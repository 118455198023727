import React, { Component} from 'react';
import {Image} from 'grommet';
import {connect} from 'react-redux';

// Displays photo
// props: id - photo id

class DBImage extends Component {

	render() {
		if(this.props.photo){
			if (this.props.thumbnail) {
				return(
					<Image {...this.props} src={process.env.PUBLIC_URL + 'uploads/thumbnails/tn_' + this.props.photo.photo_url} />
				)
			} else {
				return <Image {...this.props} src={process.env.PUBLIC_URL + 'uploads/' + this.props.photo.photo_url} />;
			}
		} else {
			return null
		}
	}
	
}

const mapStateToProps = (state, ownProps) => ({
	photo: state.photos.byId[ownProps.id]
})

export default connect(mapStateToProps)(DBImage);