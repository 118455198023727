const albumsReducer = (state = {}, action) => {
	switch (action.type) {
		case 'SET_ALBUMS':
			return action.payload;
		default:
			return state;
	}
};

// albums will be on the redux state at:
// state.albums
export default albumsReducer;