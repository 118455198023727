import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getRecipes(action) {
	try {
		
		const response = yield axios.get('/api/recipe/all');
		const recipes = {
			byId: convertArrayToObject(response.data, 'recipe_id'),
			allIds: response.data.map((recipe) => recipe.recipe_id)
		};
		
		yield put({type: 'SET_RECIPES', payload: recipes});

	} catch (error) {
		console.log('recipe get failed', error);
	}
}


function* recipeSaga() {
	yield takeLatest('FETCH_RECIPES', getRecipes);
}

export default recipeSaga;