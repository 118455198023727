import React, { Component } from 'react';
import { Box, Heading } from "grommet";
import DBImage from '../Reused/DBImage';

// Title bar on person page with name and header photo
// props: person object

class NameHeader extends Component {

	render() {
		const {photo_id, name} = this.props;
		if (photo_id) {
			return(
						
				<Box direction="row-responsive" align="center" width="100%" background="#e0dcdc" height="small">
					<Box width="60%" alignContent="center" pad='medium' >
						<Heading alignSelf="center" level="1" fontWeight="bold" color="brand">{name}</Heading>
					</Box>
					<Box width="40%" height="small">
						<DBImage fit="cover" id={photo_id}/>
					</Box>
				</Box>

			)
		} else {
			return(
						
				<Box direction="row-responsive" alignContent="center" align="center" width="100%" background="brand" justify="center" pad='medium'>
					<Heading level="3" alignSelf="center" textAlign="center" color="white" >{name}</Heading>
				</Box>

			)
		}
	}
}

export default NameHeader;