import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Image,
	ThemeContext
} from "grommet";
import { withRouter } from "react-router";

// This is just the family crest in the header

class Header extends Component { 

	render() {
		return(
			<ThemeContext.Extend
				value={
					{ 
						global: {
							focus: {
								background: {
									color: '#73a09a'
								},
								outline: {
									color: 'transparent'
								},
								shadow: {
									color: 'transparent'
								},
								boxShadow: {
									color: 'transparent'
								}
							}
						},
						button: {
							default: {
								fill: true,
								plain: true,
								outline: 'no'
							},
							hover: {
								default: {
								background: {
									color: "#666666"
								},
								color: "white"
								},
		
							},
							global: {
								focus: {
									border: {
										color: 'transparent'
									},
									outline: {
										color: 'transparent'
									},
									shadow: {
										color: 'transparent'
									},
									boxShadow: {
										color: 'transparent'
									}
								}
							}
						}
					}
				}
			>

				<Box
					flex={false}
					direction="row"
					align="start"
					margin={{ right: "small" }}
					onClick={()=>this.props.history.push('/home')}
				>
					<Image height="35px" alt='family crest' src={process.env.PUBLIC_URL + '/favicon.ico'}  />
				</Box>

			</ThemeContext.Extend>

	);}
}
	
export default withRouter(connect()(Header));