const loginMode = (state = 'login', action) => {
	switch (action.type) {
		case 'CLEAR_LOGIN_MODE':
			return '';
		case 'SET_TO_LOGIN_MODE':
			return 'login';
		case 'SET_TO_REGISTER_MODE':
			return 'register';
		case 'SET_TO_RESET_MODE':
			return 'reset';
		default:
			return state;
	}
};

// loginMode will be on the redux state at:
// state.loginMode
export default loginMode;
	