const albumDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case 'SET_ALBUM_DETAIL':
			return action.payload;
		default:
			return state;
	}
};
	
// ablum detail will be on the redux state at:
// state.album
export default albumDetailReducer;