import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import findType from './RelationshipFunctions';
import createDiagram from './DiagramFunc';
import TreeLayer from './TreeLayer';

const emptyState = {
	diagram: '',
	show: false,
	relationString: ''
}

class RelatedButton extends Component { 

	state = emptyState;

	componentDidMount () {
		this.findRelation();
	}

	findRelation = async () => {
		let fam = await this.findFamily(this.props.person_id);

		if(fam.multiple) {
			let path;
			for (let i = 0; i < fam.b.length; i++) {
				const b = fam.b[i];
				let thisPath = await this.findPath(this.props.user.family_id, b);
				if (i===0 || !path) {
					path=thisPath;
				} else if(!thisPath) {
					path=path;
				} else if(path.length> thisPath.length) {
					path=thisPath;
				}
			}

			if (path) {
				this.processData(path, fam.married);
			} else {
				this.setError();
			}
		
			
		} else {
		
			let b = fam.b;
			this.setState({...this.state, married: fam.married});
			this.setState({input: {fam_a: this.props.user.family_id, fam_b:b, generations: this.props.user.generations, person_b: this.props.person_id}});

			let path = await this.findPath(this.props.user.family_id, b);
			if (path) {
				this.processData(path, fam.married);
			} else {
				this.setError();
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.person_id !== prevProps.person_id) {
			this.findRelation();
		}
	}
 
	setError = () => {
		this.setState({result: 'no relation found'});
		this.setState({relationString: 'You are not related (or there is an error)'});
		this.setState({diagram: {}});
		this.setState({error: true});
		axios.post(`/api/family/error`, {path_found: false, user_id: this.props.user.user_id, user_family: this.props.user.family_id, person_id: this.props.person_id});
	}

	findFamily = (personId) => {
		let person = this.props.people[personId];
		//console.log('####toPerson:', person);
		if (person.families.length===1) {
				//console.log('one marriage');
				return {b: person.families[0], married: true}
		} else if (person.families.length === 0) {
				//console.log('no marriage');
				return {b: person.parent_families[0], married: false}
		} else {
				//console.log('multiple marriages');
				return {b: person.families, married: true, multiple: true};
		}
	}

	processData = async (path, married) => {
		let toPerson = this.props.person_id;
		let nGen = this.props.user.generations;
		
		let rels = [];
		for (let i = 0; i < path.length-1; i++) {
			const a = path[i]
			const b = path[i+1];
			let item = await this.getDetail(a,b);
			rels.push(item);
		}

		let diagram = createDiagram(path.map(f => this.props.families[f]), rels, nGen, this.props.people[toPerson]);
	
		this.setState({diagram: diagram});
	
		let treeData = this.getTreeData(rels);
		
		let splitPath = this.splitPath(rels);
		let output = [];

		for (let i = 0; i < splitPath.length; i++) {
			const subPath = splitPath[i];
			let toPersonId;
			let addUp = 0;
			let addDown = 0;
			let last = false;
			let fromPersonId;

			if (i===0) {
					addUp = nGen;
			} else {
				//
				let prev = splitPath[i-1];
				fromPersonId=prev[prev.length-1].person_id; 
			}

			//set toPerson if last subpath
			if (i===splitPath.length-1) {
					toPersonId=this.props.person_id;
					last = true;
					
					//if last step and not married, +1 down
					if (married===false) {
						addDown = 1;
					}
			} else {
					toPersonId=splitPath[i+1][0].person_id;
					//console.log('not end, toPerson:', toPersonId);
					//console.log('splitPath:', splitPath, i);
			}

			let item = {
					path: subPath,
			}
			let res = findType(item, this.props.people[toPersonId], this.props.people[fromPersonId], addUp, addDown, last);
			item = {
				...item,
				...res
			}
			output.push(item);          
		}
				
		this.setState({split: output});

		let strings = output.map(o => o.relationString);

		if (strings.includes('error')) {
			console.log('error found in relation string');
			this.setState({relationString: `… it's too complicated for words!`});
			axios.post(`/api/family/error`, {path_found: true, user_id: this.props.user.user_id, user_family: this.props.user.family_id, person_id: this.props.person_id});
		} else if (splitPath.length===1) {
			let finalString = output[0].relationString;
			this.setState({relationString: finalString});
		} else {
			let finalString = '';
			for (let i = 0; i < output.length; i++) {
					const e = output[i];
					if (i===output.length-1) {
							finalString = finalString + e.relationString;
					} else {
							finalString = finalString + e.relationString + `'s `;
					}
			}
			this.setState({relationString: finalString});
		}

	}

	splitPath = (steps) => {
		//create array of indeces to split before
		let splitPoints = [];
		let result = [];
		let dirs = steps.map(s => s.a_to_b);

		//loop thru steps to set splitPoints
		for (let i = 0; i < dirs.length-1; i++) {

			if((dirs[i]==='down' && dirs[i+1]!=='down') || (dirs[i]==='spouse' && dirs[i+1]==='up')) {
				splitPoints.push(i+1);
			} else if(dirs[i]==='spouse' && dirs[i+1]==='spouse' && dirs[i+2]!=='down' ) {
					splitPoints.push(i+1);
			} 
		}

		//loop thru splitPoints to split steps into array of arrays
		let start = 0;
		for (let i = 0; i < splitPoints.length; i++) {
			const splitIndex = splitPoints[i];
			result.push(steps.slice(start, splitIndex));
			start = splitIndex;
		}

		result.push(steps.slice(start));
		//console.log(steps, result);
		return(result);

	}

	getDetail = async (a,b) => {
		const params = {a: a, b: b};
		let res = await axios.get( `/api/family/relation`, { params });
		return(res.data);
	}

	findFamily = (personId) => {
		let person = this.props.people[personId];
		//console.log('####toPerson:', person);
		if (person.families.length===1) {
				//console.log('one marriage');
				return {b: person.families[0], married: true}
		} else if (person.families.length === 0) {
				//console.log('no marriage');
				return {b: person.parent_families[0], married: false}
		} else {
				//console.log('multiple marriages');
				return {b: person.families, married: true, multiple: true};
		}
	}

	//takes two fam ids, returns path if found, null if none
	findPath = async (a, b) => {
		let params  = {a: a, b: b};
		if (a===b) {
			let path = [a];
			return path;
		} else {
			let path = await this.getFromDB(params);
			if (!path) {
				return null;
			} else {
				path = path.path_string.split('.');
				path = path.map(id => parseInt(id));
				path.pop();
				//console.log('STEP: ', path);
				return path;
			}
		}
	}

	getFromDB = (params) => {
		return axios.get(`/api/family/related`, { params }).then(response => response.data)
	}

	
	getTreeData = (path) => {
		let data = path.map(p => ({id: p.person_id, gender: p.gender, families: this.props.people[p.person_id].families, parent_families:this.props.people[p.person_id].parent_families}) );
		
		data.forEach(p => {
			const self = p.id;

			p.parents = p.parent_families.map(f => ([this.props.families[f].mother, this.props.families[f].father]));
			p.siblings = p.parent_families.map(f => (this.props.families[f].children));
			p.spouses = p.families.map(f => ([this.props.families[f].mother, this.props.families[f].father]));
			p.children = p.families.map(f => (this.props.families[f].children));

			//combine arrays of arrayss
			p.children = [].concat(...p.children);
			p.siblings = [].concat(...p.siblings);
			p.spouses = [].concat(...p.spouses);
			p.parents = [].concat(...p.parents);
			
			//remove nulls from children
			p.children = p.children.filter(x => x);

			//get ids for children & siblings
			p.children = p.children.map(c => c.person_id);
			p.siblings = p.siblings.map(c => c.person_id);

			//remove self from siblings and spoused
			p.spouses = p.spouses.filter(x => x!=self);
			p.siblings = p.siblings.filter(x => x!=self);

			//format people w/type
			p.children = p.children.map(c => ({id: c, type: 'blood'}));
			p.siblings = p.siblings.map(c => ({id: c, type: 'blood'}));
			p.spouses = p.spouses.map(c => ({id: c, type: 'blood'}));
			p.parents = p.parents.map(c => ({id: c, type: 'blood'}));

		});
		return data;
	}

	render() {
		if (this.state.relationString && this.state.diagram) {
			return (
				<TreeLayer 
					position='full'
					margin={{horizontal: 'small'}}
					align='start'
					caption={this.state.relationString} 
					data={this.state.diagram} 
					gens={this.props.user.generations}
					name={this.props.person.name}
					error={this.state.error}
					label={'How am I Related?'}
				/>
			);
		} else {
			return null;
		}
	}
	
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		albums: state.albums,
		person: state.people.byId[ownProps.person_id],
		people: state.people.byId,
			//detail on people
		families: state.families.byId,
			//detail of families
	}
}

export default connect(mapStateToProps)(RelatedButton);