const articleReducer = (state = [], action) => {
	switch (action.type) {
		case 'SET_ARTICLES':
			return action.payload;
		default:
			return state;
	}
};

// articles will be on the redux state at:
// state.articles
export default articleReducer;