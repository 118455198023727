import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Event from './Event.js';
import InfoSidebar from './InfoSidebar';
import { Article } from 'grommet-icons';
import Bio from '../Reused/Bio';
import { Box, Grid, Heading,Button } from "grommet";
import SelectHeaderThumb from './SelectHeaderThumb.js';
import PersonAlbums from './PersonAlbums.js';
import AlbumCarosel from './AlbumCarosel.js';

class PersonPage extends Component {

	componentDidMount() {
		const { person } = this.props;
		this.props.dispatch({type: 'SET_TITLE', payload: {text: person.name, photo_id: person.header_photo}});
	}


	componentDidUpdate(prevProps) { 
		const { person } = this.props;
		if (person.person_id !== prevProps.person.person_id) {
			this.props.dispatch({type: 'SET_TITLE', payload: {text: person.name, photo_id: person.header_photo}});
		}
	}

	editPersonAlbum = (event) => {
		event.preventDefault();
		this.props.history.push(`/editAlbum/${this.props.person.person_album}`);
	}
	
	render() {

		return(

		<Box  fill overflow="auto">
			<Grid 
				areas={[
					{ name: "main", start: [0, 0], end: [0, 0] },
					{ name: "side", start: [1, 0], end: [1, 0] }
				]}
				columns={["60%", "40%"]}
				rows={["auto", "flex"]}
			>
					
				<Box gridArea="main" pad="small"  align="center">

					{this.props.user.admin && <Button alignSelf='start' label='back' onClick={()=>this.props.history.goBack()}/>  }

					{this.props.user.admin && <SelectHeaderThumb person_id={this.props.person.person_id} header={this.props.person.header_photo} thumbnail={this.props.person.thumbnail_photo}/>}

					<AlbumCarosel album_id={this.props.person.person_album}/>  

					{this.props.user.admin && <Button label={'Edit Person Album'} onClick={this.editPersonAlbum}/>}

					{this.props.person.events &&
					<Box width={{"min":"75%", "max":"75%"}}>
						<Heading color="accent-1" level="4" margin="none">Timeline</Heading>
						<ul style={{marginTop: "0px"}}>
							{this.props.person.events && this.props.person.events.map(event=>
								<Event key={event.event_id} event={event} eventArray={this.props.person.events}/>
							)}
						</ul>
					</Box>}

					{this.props.person.articles[0] && 
					<Box width={{"min":"75%"}} margin={{'bottom':'medium'}}>
						<Heading color="accent-1" level="4" margin="none">In the News</Heading>
						<Link className='text-link' margin={'xsmall'} to={`/article?personId=${this.props.person.person_id}`}><Box direction="row" gap='small'><Article color='#63b9d6'/>Read all about it!</Box></Link>
					</Box>}

					{(this.props.person && (this.props.user.admin || this.props.person.albums)) &&
					<PersonAlbums person_albums={this.props.person.albums} person_id={this.props.person.person_id}/>}

					<Box width={{"min":"75%", "max":"75%"}}>
						<Bio
							bio_text={this.props.person.bio_text}
							person_id={this.props.person.person_id}
						/>
					</Box>
											
				</Box>
			
				<Box gridArea="side" >
					<InfoSidebar user={this.props.user} person={this.props.person}/>
				</Box>

			</Grid>
		</Box>

		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		albums: state.albums,
		person: state.people.byId[ownProps.match.params.id]
	}
}

export default connect(mapStateToProps)(PersonPage);