import React, { Component } from 'react';
import { connect } from 'react-redux';
import BioEditor from './BioEditor';
import BioDisplay from './BioDisplay'

import {
	Box,
	Heading,
	Button,
} from "grommet";

// Bio section of the person page
// Props: person_id, bio_text

class Bio extends Component {

	emptyState = {
		edit: false,
	}

	state = { 
		edit: false,
	}

	formatBio(text) {
		console.log(text);
		if (text==='​\n' || !text) {
			return false
		} else if (text.slice(0,3)==='###') {
			let array = text.split('### ');
			array.shift();

			let arrayO = array.map(s => {
				let i = s.search('\n');
				let o = {header: s.slice(0, i), paragraphs: s.slice(i+2, s.length)};
				return o;
			});
			return arrayO;
		} else {
			return text;
		}
	}

	componentDidMount() {
		this.setState(this.emptyState);  
	}

	componentDidUpdate(prevProps) {
		if(prevProps === undefined) {
			return false
		}
		if (this.props.person_id !== prevProps.person_id) {
			this.setState(this.emptyState);	
		}
	}

	edit() {
		this.setState(prevState => ({
			edit: !prevState.edit
		}));
	}

	handleChange = ( event ) => {
		this.setState({bio_text: event.target.value});
	}

	handleSubmit = (text) => {
		this.props.dispatch({
			type: 'UPDATE_BIO',
			payload: {
				text: text,
				person_id: this.props.person_id,
			},
		});
		this.setState(this.emptyState);
	}

	handleCancel = () => {
		this.setState(this.emptyState);
	}
		
	render() {
		return(
			<Box align="center">
				{this.props.bio_text && this.formatBio(this.props.bio_text) && <Heading alignSelf="start" color="accent-1" level="4" margin={{"bottom":"-18px"}}>Bio</Heading>}
				{!this.state.edit && this.props.bio_text && 
					<BioDisplay bio={this.formatBio(this.props.bio_text)}/>
				}		
				{this.props.user.admin && (<>
					{!this.state.edit && <Button label="Edit Bio" onClick={()=>{this.edit()}}/>}
					{this.state.edit && 
						<BioEditor 
							person_id={this.props.person_id} 
							bio_text={this.props.bio_text} 
							handleSubmit={this.handleSubmit}
							handleCancel={this.handleCancel}
						/>
					}
				</>)}
			</Box>
		)
	}
}

const mapStateToProps = state => ({
	user: state.user,
});

export default connect(mapStateToProps)(Bio);