const familyReducer = (state = [], action) => {
	switch (action.type) {
		case 'SET_FAMILIES':
			return action.payload;
		default:
			return state;
	}
};

// families will be on the redux state at:
// state.families
export default familyReducer;