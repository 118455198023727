import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Box,
    Text,
    Heading,
    Button,
    Grid,
    ThemeContext
} from "grommet";

import { Next, Previous } from 'grommet-icons';
import EditPhoto from './EditPhoto';

const EditAllPhotos = (props) => {

    const [photos, setPhotos] = useState(props.album.photos);
    const [index, setIndex] = useState(0);

	useEffect(() => {
		props.dispatch({ type: 'CLEAR_TITLE' });
	}, []);


    const onClick = (dir) => {
        if (dir==='up') {
            setIndex(index+1);
        } else {
            setIndex(index-1);
        }
    }

    return(
        <Grid
        areas={[
            {name: "header", start: [0, 0], end: [1, 0]},
            { name: "main", start: [0, 1], end: [0, 1] },
            { name: "side", start: [1, 1], end: [1, 1] }
            ]}
        columns={["100%", "0%"]}
        rows={["auto", "flex"]}
        > 


        {photos[0] && 
        <Box gridArea="main" pad="medium"  margin={{left: 'xlarge', right: 'xlarge'}}>  

                <ThemeContext.Extend
            value={
                { 
                    global: {
                        focus: {
                            background: {
                                color: '#73a09a'
                            },
                            outline: {
                                color: 'transparent'
                            },
                            shadow: {
                                color: 'transparent'
                            },
                            boxShadow: {
                                color: 'transparent'
                            }
                        }
                    },
                    button: {
                        default: {
                            fill: true,
                            plain: true,
                            online: 'no'
                        },
                        hover: {
                            default: {
                            background: {
                                color: "#666666"
                            },
                            color: "white"
                            },
       
                        },
                        global: {
                            focus: {
                                border: {
                                    color: 'transparent'
                                },
                                outline: {
                                    color: 'transparent'
                                },
                                shadow: {
                                    color: 'transparent'
                                },
                                boxShadow: {
                                    color: 'transparent'
                                }
                            }
                        }
                    }
                }
            }
            > 
            <Heading alignSelf='center' level='3'>{props.album.name}</Heading>
            <Box direction='row' justify='between'>
                <Box justify='center'>{index>0 && <Button focusIndicator={false} icon={<Previous />} onClick={() => {onClick('down')}} />}</Box>
                <Box ><Heading level='4'>{index+1} of {photos.length}</Heading></Box>
                <Box justify='center'>{index<photos.length-1 && <Button focusIndicator={false} icon={<Next />} onClick={() => {onClick('up')}} />}</Box>
            </Box>
            </ThemeContext.Extend>

            <EditPhoto photoId={photos[index]}/>

        </Box>}


        </Grid>
    )

}

const mapStateToProps = (state, ownProps) => ({
    album: state.albums.byId[ownProps.match.params.id],
});


export default connect(mapStateToProps)(EditAllPhotos);