import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getAlbums(action) {
	try {
		
		const response = yield axios.get('/api/album/');
		const albums = {
			byId: convertArrayToObject(response.data, 'album_id'),
			allIds: response.data.map((album) => album.album_id)
		};

		yield put({type: 'SET_ALBUMS', payload: albums});

	} catch (error) {
		console.log('albums get failed', error);
	}
}

function* updatePhotoOrder(action) {
	let from=action.payload.oldIndex + 1;
	let to=action.payload.newIndex + 1;
	let album=action.payload.album_id;
	
	try {
		//axios call to update photo ordering
		yield call(axios.put, '/api/album/order1', {params: {old: from, new: to, album: album}});
		yield call(axios.put, '/api/album/order2', {params: {old: from, new: to, album: album}});
		yield call(axios.put, '/api/album/order3', {params: {old: from, new: to, album: album}});
		yield put( { type: 'FETCH_ALBUMS'} );
	}
	catch (error) {
		console.log('error with update photo order');
	}
}


function* albumSaga() {
	yield takeLatest('FETCH_ALBUMS', getAlbums);
	yield takeLatest('UPDATE_ALBUM_ORDER', updatePhotoOrder);
}

export default albumSaga;