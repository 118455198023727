import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
	Box,
	TextArea,
	Button,
} from "grommet";

//Create "what's new" item

const emptyState = {
	text: ''
}

const customStyles = {
	menu: (provided, state) => ({
	  ...provided,
	  width: '200px',
	})
}

class AddNews extends Component { 

	state = emptyState;

	componentDidMount() {
		this.setState(this.emptyState);
		this.forceUpdate();  
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}

	handleInputChangeFor = propertyName => (event) => {
		this.setState({
			[propertyName]: event.target.value,
		});
	}


	saveAll = () => {
		let data = {text: this.state.text}
		axios.post(`/api/news/new`, data)
		.then((results) => {
			this.props.dispatch({type:'FETCH_NEWS', payload: this.props.user.news});
			this.props.history.goBack();
		}).catch((error) => {
			console.log('error adding news', error);
		})
	}
 
	render() {
		return(
			<Box pad="small" gap='small'>
		
				<TextArea
					placeholder="text"
					value={this.state.text}
					onChange={this.handleInputChangeFor('text')}
				/>
				<Button alignSelf='center' label="save" onClick = {()=>this.saveAll()}/>
				<Button alignSelf='center' label='back' onClick={()=>this.props.history.goBack()}/>    

			</Box>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(mapStateToProps)(AddNews);