import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getPhotos(action) {
	try {
		
		const response = yield axios.get('/api/photo/');
		const photos = {
			byId: convertArrayToObject(response.data, 'photo_id'),
			allIds: response.data.map((photo) => photo.photo_id)
		};
		
		yield put({type: 'SET_PHOTOS', payload: photos});

	} catch (error) {
		console.log('photo get failed', error);
	}
}


function* photoSaga() {
	yield takeLatest('FETCH_PHOTOS', getPhotos);
}

export default photoSaga;