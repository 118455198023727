import React, { Component } from 'react';
import { Box, Heading } from "grommet";
import { Link } from 'react-router-dom';
import Family from './Family'
import { ReactComponent as AncestorsIcon} from '../../Assets/Ancestors.svg';
import { Blank } from 'grommet-icons';
import RelatedButton from '../HowRelated/RelatedButton.js';

// Left column of the person page
// props: person - person object

const Ancestors = props => (
	<Blank {...props}>
		<AncestorsIcon />
	</Blank>
);

class InfoSidebar extends Component {

	render() {
		return(<Box pad={{ "right":"medium", }}>
			<Box pad={{"top": "medium", "horizontal":"small", "bottom": "small"}}>
				<Heading level="4" color="accent-1" margin="none" style={{'text-transform': "uppercase"}}>{this.props.person.name}</Heading>
			</Box>

			<RelatedButton person_id={this.props.person.person_id}/>
			
			{this.props.person.parent_families[0] &&        
			<Box pad={{"top": "medium", "horizontal":"small", "bottom": "small"}}>
				<Heading level="5" color="accent-1" margin="none">Parents & Siblings</Heading>
				<Link className='text-link' margin={'xsmall'} to={`/tree/${this.props.person.person_id}`}><Box direction="row">See ancestors<Ancestors/></Box></Link>
				{this.props.person.parent_families.map(family=>
					<Family key={family} id={family} self={this.props.person.person_id} type={'parents'}/>
				)}
			</Box>}

			{this.props.person.families[0] &&
				<Box pad="small">
				<Heading level="5" color="accent-1" margin="none">Partners & Children</Heading>
				{this.props.person.families.map(family=>
					<Family key={family} id={family} self={this.props.person.person_id} type={'partner'}/>
				)}
			</Box>}
		</Box>)
	}
}

export default InfoSidebar;