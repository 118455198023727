import React, {Component} from 'react';
import { connect } from 'react-redux';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {Box, Button} from 'grommet';
import arrayMove from 'array-move';
import { Refresh } from 'grommet-icons';

import AlbumWithDelete from './AlbumWithDelete';

const SortableItem = SortableElement(({value, person_id}) =>
	<AlbumWithDelete album={value} person_id={person_id}/>
);

const SortableList = SortableContainer(({items, person_id}) => {
	return (
		<Box direction="column">
			{items.map((value, index) => (
				<SortableItem key={`item-${value.album_id}`} index={index} value={value} person_id={person_id}/>
			))}
		</Box>
	);
});

class SortableAlbums extends Component {
	state = {
		items: this.props.albums,
	};

	onClick = event => {
		event.preventDefault();
		console.log('refreshed', this.props);
		this.setState({
			items: this.props.albums
		})
	};

	onSortEnd = ({oldIndex, newIndex}) => {
		if (oldIndex === newIndex) {
			return
		}
		this.setState(({items}) => ({
			items: arrayMove(this.props.albums, oldIndex, newIndex),
		}));
		console.log('in sort', this.props.person_id, oldIndex, newIndex);
		
		let person_id = this.props.person_id;

		// call to saga to update the list in the database
		this.props.dispatch({ type: 'UPDATE_PERSON_ALBUM_ORDER', payload: {oldIndex, newIndex, person_id}});
};

	render() {
		return <>
			<SortableList items={this.props.albums} onSortEnd={this.onSortEnd} axis={"xy"} person_id={this.props.person_id}/>
			<Button onClick={this.onClick} icon={<Refresh/>}/>
		</>;
	}
}


export default connect()(SortableAlbums);