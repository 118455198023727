const personReducer = (state = {}, action) => {
	switch (action.type) {
		case 'SET_PERSON':
		return action.payload;
		default:
		return state;
	}
};

// current person will be on the redux state at:
// state.person
export default personReducer;
