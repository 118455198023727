import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
	Box,
	Button,
	TextInput,
	Form,
	FormField,
	TextArea,
	Text
} from "grommet";
import Caption from '../Reused/Caption';

// Used to change manual caption, manual date, and location data for photo
// Props: photo - object with all photo data


class EditCaption extends Component { 

	emptyState ={
		edit: false,
		caption: this.props.photo.manual_caption,
		location: this.props.photo.location_text,
		date: this.props.photo.manual_date
	}

	state ={
		edit: false,
		caption: this.props.photo.manual_caption,
		location: this.props.photo.location_text,
		date: this.props.photo.manual_date 
	}

	componentDidMount() {
		this.setState(this.emptyState);
	}

	componentDidUpdate(prevProps) {
		if(this.props.photo.photo_id !== prevProps.photo.photo_id) {
			this.setState(this.emptyState);
		}
	}


	handleSubmit = (event) => {
		event.preventDefault();
		let data = {manual_caption: this.state.caption, location_text: this.state.location, manual_date: this.state.date, id:this.props.photo.photo_id};

		const caption = axios.post(`/api/photo/caption`, data);
		const location = axios.post(`/api/photo/location`, data);
		const date = axios.post(`/api/photo/date`, data);
		
		axios.all([caption, location, date]).then(axios.spread((...responses) => {
			const caption = responses[0];
			const location = responses[1];
			const date = responses[2];
			console.log('done', caption, location, date); 
			this.props.getPhotoInfo();
			this.setState(prevState => ({
				...this.state,
				edit: !prevState.edit 
			}));
		})).catch(errors => {
			console.log('error editing caption', errors);
		})
		this.props.dispatch({type: 'FETCH_PHOTOS' });
		this.props.dispatch({type: 'FETCH_PEOPLE'});
}

	cancel() {
		this.setState(prevState => ({
			...this.state,
			edit: false,
			caption: this.props.photo.manual_caption,
			location: this.props.photo.location_text,
			date: this.props.photo.manual_date 
		}));
	}

	edit() {
		this.setState(prevState => ({
			...this.state,
			edit: !prevState.edit,
			caption: this.props.photo.manual_caption,
			location: this.props.photo.location_text,
			date: this.props.photo.manual_date 
		}));
	}

	handleInputChangeFor = propertyName => (event) => {
		event.preventDefault();
		this.setState({...this.state,
			[propertyName]: event.target.value,
		});
	}

	render() {
		const { caption, date, location } = this.state;
		return(
			<Box pad="large" flex="grow" align="center" justify="center">
				
				<Box width="large">

					{!this.state.edit && this.props.photo && <Text>{<Caption photo={this.props.photo}/>}</Text>}
					{!this.state.edit && <Button label="Edit Caption" onClick={()=>{this.edit()}}/>}
				
					{this.state.edit && <Form onSubmit={this.handleSubmit}>
						<FormField label="manual caption" caption="caption" value={caption} htmlFor="caption">
							<TextArea
								id="caption"
								name="caption"
								placeholder={caption}
								value={caption}
								onChange={this.handleInputChangeFor('caption')}
							/>
						</FormField>
						<FormField label="date" caption="date" value={date} htmlFor="date">
							<TextInput
								id="date"
								name="date"
								placeholder={date}
								value={date}
								onChange={this.handleInputChangeFor('date')}
							/>
						</FormField>
						<FormField label="location" caption="location" value={location} htmlFor="location">
							<TextInput
								id="location"
								name="location"
								placeholder={location}
								value={location}
								onChange={this.handleInputChangeFor('location')}
							/>
						</FormField> 
						<Box direction="row" justify="between" margin={{ top: 'medium' }}>
							<Button type="submit" name="submit" label="Save"/>
							<Button label="Cancel" onClick={()=>{this.cancel()}}/>
						</Box>
						
					</Form>}

				</Box>

			</Box>
		);
	}
}


export default connect()(EditCaption);