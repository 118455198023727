const locationReducer = (state = [], action) => {
	switch (action.type) {
		case 'SET_LOCATIONS':
			return action.payload;
		default:
			return state;
	}
};

// location will be on the redux state at:
// state.location
export default locationReducer;