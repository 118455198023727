import React, { Component } from 'react';

// Formats photo caption for display
// props: photo - object with all photo data

class Caption extends Component {

	formatCaption(photo) {
		let names;
		let line2;
		let manual = photo.manual_caption;
	
		if (photo.people && !photo.manual_caption) {
			names = photo.people.map(p => p.name);
			names = names.join(`, `);
		}
	
		if (photo.manual_date && photo.location_text) {
			line2 = photo.location_text.concat(`, `, photo.manual_date);
		} else if (photo.manual_date) {
			line2 = photo.manual_date;
		} else {
			line2 = photo.location_text;
		}
	
		let caption = [manual, names, line2];
		caption = caption.filter(e => e);
		caption = caption.map(s=><React.Fragment key={s}>{s}<br/></React.Fragment>)
		return caption;
	}

  
	render() {
		return(
			<>{this.formatCaption(this.props.photo)}</>
		)
	}

}

export default Caption;