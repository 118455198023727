import React, {Component, useContext} from 'react';
import { connect } from 'react-redux';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import { Button, Grid, ResponsiveContext} from 'grommet';
import arrayMove from 'array-move';
import { Refresh } from 'grommet-icons';
import PhotoWithDelete from './PhotoWithDelete';


const DragHandle = sortableHandle(() => <span>::</span>);

const SortableItem = SortableElement(({value, album_id}) =>
<PhotoWithDelete DragHandle={DragHandle} key={value.photo_id} photo={value} album_id={album_id}/>
);

const SortableList = SortableContainer(({items, album_id}) => {

  const size = useContext(ResponsiveContext);

  return (
    <Grid columns={size !== 'small' ? 'small' : '100%'} gap="small">
      {items.map((value, index) => (
        <SortableItem key={`item-${value.photo_id}`} index={index} value={value} album_id={album_id}/>
      ))}
    </Grid>
  );
});

class SortPhotos extends Component {
  state = {
    items: this.props.photos,
  };

  onClick = event => {
    event.preventDefault();
    //console.log('refreshed', this.props);
    this.setState({
      items: this.props.photos
    })
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    //console.log('sort', this.props);
    if (oldIndex === newIndex) {
      return
    }
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    //console.log('in sort', this.props.album_id, oldIndex, newIndex);
    
    let album_id = this.props.album_id;

    // call to saga to update the list in the database
    this.props.dispatch({ type: 'UPDATE_ALBUM_ORDER', payload: {oldIndex, newIndex, album_id}});
};

//onSortEnd={this.onSortEnd} in sortable list fix
  render() {
    return <><SortableList useDragHandle items={this.props.photos} onSortEnd={this.onSortEnd} axis={"xy"} album_id={this.props.album_id}/>
    <Button onClick={this.onClick} icon={<Refresh/>}/>
    </>;
  }
}


export default connect()(SortPhotos);