import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {Box, Button} from "grommet";
import { Drag } from 'grommet-icons';
import { withRouter } from "react-router";
import AlbumLightbox from '../AlbumManage/AlbumLightbox'

// Album shown with option to delete; used in album list on person page when user is an admin
// Props: album - album object with album_id, photos, etc
//		person_id - id of person the person page is for


class AlbumWithDelete extends Component { 

	constructor(){
		super();
		this.targetRef = React.createRef()
	}  

	componentDidMount() {
		this.forceUpdate();
	}

  	deleteFromPerson = () => {
		if ( window.confirm('Remove album from person?') ) {
			let data = {album_id: this.props.album.album_id, person_id: this.props.person_id}
			axios.post('/api/person/removeAlbum', data)
				.then((results) => {
					this.props.dispatch({type: 'FETCH_PEOPLE'});
				}).catch((error) => {
					console.log('error removing album', error); 
				})
		}
	}

	goToEdit = () => {
		event.preventDefault();
		this.props.history.push(`/editAlbum/${this.props.album.album_id}`);
	}


	render() {
		return(
			
		<Box direction="row" key={this.props.album.album_id}>
			<Drag/>
			<AlbumLightbox album_photos={this.props.album.photos} key={this.props.album.album_id} buttonText={this.props.album.display_name}/>
			<Button onClick={() => this.deleteFromPerson()} label='Remove'/>
			<Button label={'Edit Album'} onClick={this.goToEdit}/>
		</Box>
			
		);
	}
}

export default withRouter(connect()(AlbumWithDelete));