const treeReducer = (state = {}, action) => {
	switch (action.type) {
		case 'SET_TREE':
			return action.payload;
		default:
			return state;
	}
};
	
// tree data will be on the redux state at:
// state.tree
export default treeReducer;