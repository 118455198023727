import React, { Component, useEffect } from 'react';
import {
	Diagram,
	Stack,
	Box,
	Grid,
	Heading,
	Button
} from "grommet";
import RoutedButton from '../Reused/RoutedButton';
import { connect } from 'react-redux';
import Dot from './Dot';
import { Blank } from 'grommet-icons';
import { ReactComponent as ArrowDown} from '../../Assets/Arrow-Down.svg';
import { ReactComponent as ArrowUp} from '../../Assets/Arrow-Up.svg';
import SVGRectangle from '../HowRelated/SVGRectangle';

const DownArrow = props => (
	<Blank {...props}>
		<ArrowDown />
	</Blank>
);

const UpArrow = props => (
	<Blank {...props}>
	  <ArrowUp />
	</Blank>
);


class TreePage extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const name = this.props.person.name;
		this.props.dispatch({type: 'SET_TITLE', payload: {text: `${name.slice(name.lastIndexOf(' '))} Family`, photo_id: ''}});
	}


	componentDidUpdate(prevProps) { 
		if (this.props.match.params.id !== prevProps.match.params.id) {
			const name = this.props.person.name;
			this.props.dispatch({type: 'SET_TITLE', payload: {text: `${name.slice(name.lastIndexOf(' '))} Family`, photo_id: ''}});
		}
	}


	render() {
		const { person_id, m, p, pp, mp, pm, mm, ppp, ppm, pmp, pmm, mpp, mpm, mmp, mmm, maternal, paternal } = this.props.tree;
		
		return(
			<>
			<Box gap="small" align="center" full fill={true} pad='medium'>
				<Stack  fill={true} full align="center" guidingChild='last'>
					<Diagram
						connections={[
						{
							fromTarget: 'x',
							toTarget: '1',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear'
						},
						{
							fromTarget: 'p',
							toTarget: '1',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'm',
							toTarget: '1',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'p',
							toTarget: '2',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'm',
							toTarget: '3',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'pp',
							toTarget: '2',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'pm',
							toTarget: '2',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'mm',
							toTarget: '3',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'mp',
							toTarget: '3',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'pp',
							toTarget: '4',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'pm',
							toTarget: '5',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'mp',
							toTarget: '6',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'mm',
							toTarget: '7',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'ppp',
							toTarget: '4',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'ppm',
							toTarget: '4',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'pmp',
							toTarget: '5',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'pmm',
							toTarget: '5',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'mpp',
							toTarget: '6',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'mpm',
							toTarget: '6',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'mmm',
							toTarget: '7',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},
						{
							fromTarget: 'mmp',
							toTarget: '7',
							thickness: 'xsmall',
							color: 'accent-2',
							type: 'rectilinear',
						},

						]}
					/>
					
					<Grid
						columns={{count: 15, size: 'xxsmall'}}
						justifyItems={'center'}
						justify={'center'}
						align={'center'}
						fill={true}
						
					>
						<>
							<Box/>
							<Box/>
							<Box/>
							{paternal && <RoutedButton  focusIndicator={false} alignSelf='center' align='center' icon={<UpArrow />} path={`/tree/${p}/b`}/>}
							{!paternal && <RoutedButton disabled={true} style={{visibility: 'hidden'}} focusIndicator={false} alignSelf='center' align='center' icon={<UpArrow />} path={`/tree/${p}/b`}/>}
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							{maternal &&<RoutedButton focusIndicator={false} alignSelf='center' align='center' icon={<UpArrow />} path={`/tree/${m}/b`}/>}
							{!maternal && <RoutedButton disabled={true} style={{visibility: 'hidden'}} focusIndicator={false} alignSelf='center' align='center' icon={<UpArrow />} path={`/tree/${p}/b`}/>}
							<Box/>
							<Box/>
							<Box/>
						</>
						
						{( ppp || ppm || pmp || pmm || mpp || mpm || mmp || mmm )&&<>
							<SVGRectangle id="ppp" person_id={ppp} />
							<Dot id="4" l={ppp} r={ppm}/>
							<SVGRectangle id="ppm" person_id={ppm} />
							<Box/>
							<SVGRectangle id="pmp" person_id={pmp}/>
							<Dot id="5"  l={pmp} r={pmm}/>
							<SVGRectangle id="pmm"  person_id={pmm}/>
							<Box/>
							<SVGRectangle id="mpp" person_id={mpp} />
							<Dot id="6"  l={mpp} r={mpm}/>
							<SVGRectangle id="mpm" person_id={mpm} />
							<Box/>
							<SVGRectangle id="mmp" person_id={mmp} />
							<Dot id="7"  l={mmp} r={mmm}/>
							<SVGRectangle id="mmm" person_id={mmm}/>
						</>}

						{( mm || pp || pm || mp )&&<>
							<Box/>
							<SVGRectangle id="pp" person_id={pp}/>
							<Box/>
							<Dot id="2" l={pp} r={pm}/>
							<Box/>
							<SVGRectangle id="pm" person_id={pm}/>
							<Box/>
							<Box/>
							<Box/>
							<SVGRectangle id="mp" person_id={mp}/>
							<Box/>
							<Dot id="3" l={mp} r={mm}/>
							<Box/>
							<SVGRectangle id="mm" person_id={mm}/>
							<Box/>
						</>}

						{( m || p )&&<>
							<Box/>
							<Box/>
							<Box/>
							<SVGRectangle id="p" person_id={p}/>
							<Box/>
							<Box/>
							<Box/>
							<Dot id="1" l={m} r={p}/>
							<Box/>
							<Box/>
							<Box/>
							<SVGRectangle id="m" person_id={m}/>
							<Box/>
							<Box/>
							<Box/>
						</>}

						<>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<SVGRectangle id="x" person_id={person_id}/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
						</>

						{this.props.match.params.back && <>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Button alignSelf='center' align='center' icon={<DownArrow />} onClick={()=>this.props.history.goBack()}/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
							<Box/>
						</>}
					
					</Grid>

				</Stack>
			</Box>

			<Box width="100%" height='small'/>
			</>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
	   tree: state.tree.byId[ownProps.match.params.id],
	   newTree: state.newTree,
	   person: state.people.byId[ownProps.match.params.id],
	}
}

export default connect(mapStateToProps)(TreePage);