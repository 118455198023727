import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Heading
} from "grommet";
import OrphanPhotoTable from './OrphanPhotoTable';

// Displays only photos not used in albums or on person pages

class OrphanPhotos extends Component { 

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}

	render() {
		return(
	<Box justify="center" pad={{ horizontal: "medium", vertical: "small" }} margin="large"  align="center">
		<Heading level='3'>Unused Photos</Heading>
		{this.props.photos && <OrphanPhotoTable photos={this.props.photos}/>}
	</Box>
		);
	}

}


const mapStateToProps = state => ({
	photos: state.photos.allIds.map(id => state.photos.byId[id]).filter((p) => !p.header &&!p.thumbnail && !p.albums)
});

export default connect(mapStateToProps)(OrphanPhotos);