import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Box } from "grommet";
import TreeLayer from '../HowRelated/TreeLayer';
import createTree from './NewTree';

// Button to show full tree on home
// Props: data object

const emptyState = {
	diagram: '',
	show: false,
	relationString: ''
}

class NewTreeButton extends Component { 

	state = emptyState;

	componentDidMount () {
		//console.log(this.props.data, 'start here');
		let result = createTree(this.props.data, this.props.user.generations);
		//console.log(result.families);
		result.families.map(f=>this.addMDate(f));
		//console.log(result.families);
		this.setState({...this.state, diagram: result});
	}

	addMDate (f) {
		f.y = f.y - 10;
		let family = this.props.families[f.id];
		let mDate;
		if (family.events) {
			mDate = family.events.find(e => e.name==="Marriage").date;
			if (mDate) {
					mDate = mDate.slice(-4);
					mDate = ' '+mDate;
			}
		}

		f.mDate = mDate;
		return f;
	}


	render() {
		if (this.state.diagram) {
			return (
				<TreeLayer 
					justify='end'
					margin={{horizontal: 'medium' , vertical: 'large'}}
					label="View Full Tree"
					align='end'
					data={this.state.diagram} 
					gens={this.props.user.generations}
				/>
			);
		} else {
			return (
				<Box>
					<Button 
						margin={{horizontal: 'medium' , vertical: 'large'}} 
						align='end' 
						justifySelf='end'
						label='View Full Tree' />
				</Box>
				);
		}

	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		albums: state.albums,
		person: state.people.byId[ownProps.person_id],
		people: state.people.byId,
			//detail on people
		families: state.families.byId,
			//detail of families
	}
}

export default connect(mapStateToProps)(NewTreeButton);