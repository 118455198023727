import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
	Box,
	Text,
	Button,
	FormField,
	Paragraph
} from "grommet";
import findType from './RelationshipFunctions';
import createDiagram from './DiagramFunc';
import TreeLayer from './TreeLayer';

const customStyles = {
	menu: (provided, state) => ({
		...provided,
		width: '200px',
	})
}

const emptyState = {
    a: '',
    aPerson: '',
    nGen: '',
    b: '',
    bPerson: '',
    input: '',
    result: '',
    detail: '',
    relationString: ''
}

class You extends Component { 

  	state = emptyState;

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}


	processData = async (path, married) => {

		let toPerson = this.state.bPerson;
		let nGen = this.state.nGen;
		
		let rels = [];
		for (let i = 0; i < path.length-1; i++) {
			const a = path[i]
			const b = path[i+1];
			let item = await this.getDetail(a,b);
			rels.push(item);
		}

		let diagram = createDiagram(path.map(f => this.props.families[f]), rels, nGen, this.props.people[toPerson]);
		this.setState({diagram: diagram});
	
			
		let splitPath = this.splitPath(rels);
		let output = [];

        for (let i = 0; i < splitPath.length; i++) {
            const subPath = splitPath[i];
            //console.log(subPath, 'SUBPATH');
            let toPersonId;
            let addUp = 0;
            let addDown = 0;
            let last = false;
            let fromPersonId;

            if (i===0) {
                addUp = this.state.nGen;
            } else {
              //
              let prev = splitPath[i-1];
              fromPersonId=prev[prev.length-1].person_id; 
              //console.log('fromPerson:', fromPersonId, prev);
            }

            //set toPerson if last subpath
            if (i===splitPath.length-1) {
                toPersonId=this.state.bPerson;
                last = true;
                
                //if last step and not married, +1 down
                if (married===false) {
                  addDown = 1;
                }
            } else {
                toPersonId=splitPath[i+1][0].person_id;
                //console.log('not end, toPerson:', toPersonId);
                //console.log('splitPath:', splitPath, i);
            }

            let item = {
                path: subPath,
            }
            //console.log(item, 'ITEM');
            let res = findType(item, this.props.people[toPersonId], this.props.people[fromPersonId], addUp, addDown, last);
            item = {
                ...item,
                ...res
            }
          output.push(item);          
        }

        this.setState({split: output});

        let strings = output.map(o => o.relationString);

        if (strings.includes('error')) {
			console.log('error found in relation string');
			axios.post(`/api/family/error`, {path_found: true, user_id: null, user_family: this.state.a, person_id: this.state.bPerson});

			this.setState({relationString: `… it's too complicated for words!`});

        } else if (splitPath.length===1) {
			let finalString = output[0].relationString;
			this.setState({relationString: finalString});
		} else {
			let finalString = '';
			for (let i = 0; i < output.length; i++) {
				const e = output[i];
				if (i===output.length-1) {
					finalString = finalString + e.relationString;
				} else {
					finalString = finalString + e.relationString + `'s `;
				}
			}
			this.setState({relationString: finalString});
			//console.log(this.state)
        }

    }


	splitPath = (steps) => {
	//create array of indices to split before
		let splitPoints = [];
		let result = [];
		let dirs = steps.map(s => s.a_to_b);

		//loop thru steps to set splitPoints
		for (let i = 0; i < dirs.length-1; i++) {

			if((dirs[i]==='down' && dirs[i+1]!=='down') || (dirs[i]==='spouse' && dirs[i+1]==='up')) {
			splitPoints.push(i+1);
			} else if(dirs[i]==='spouse' && dirs[i+1]==='spouse' && dirs[i+2]!=='down' ) {
				splitPoints.push(i+1);
			} 
		}

		//loop thru splitPoints to split steps into array of arrays
		let start = 0;
		for (let i = 0; i < splitPoints.length; i++) {
			const splitIndex = splitPoints[i];
			result.push(steps.slice(start, splitIndex));
			start = splitIndex;
		}

		result.push(steps.slice(start));
		//console.log(steps, result);
		return(result);
	}

	getDetail = async (a,b) => {
		const params = {a: a, b: b};
		let res = await axios.get( `/api/family/relation`, { params });
		return(res.data);
	}

	findFamily = (personId) => {
		let person = this.props.people[personId];
		//console.log('####toPerson:', person);
		if (person.families.length===1) {
			//console.log('one marriage');
			return {b: person.families[0], married: true}
		} else if (person.families.length === 0) {
			//console.log('no marriage');
			return {b: person.parent_families[0], married: false}
		} else {
			//console.log('multiple marriages');
			return {b: person.families, married: true, multiple: true};
		}
	}

//takes two fam ids, returns path if found, null if none
	findPath = async (a, b) => {
		let params  = {a: a, b: b};
		if (a===b) {
			let path = [a];
			return path;
		} else {
			let path = await this.getFromDB(params);
			if (!path) {
				return null;
			} else {
				this.setState({result: path});
				path = path.path_string.split('.');
				path = path.map(id => parseInt(id));
				path.pop();
				//console.log('STEP: ', path);
				return path;
			}
		}
	}

	getFromDB = (params) => {
		return axios.get(`/api/family/related`, { params }).then(response => response.data)
	}

	getTreeData = (path) => {
		let data = path.map(p => ({id: p.person_id, gender: p.gender, families: this.props.people[p.person_id].families, parent_families:this.props.people[p.person_id].parent_families}) );
		
		data.forEach(p => {
		const self = p.id;

		p.parents = p.parent_families.map(f => ([this.props.families[f].mother, this.props.families[f].father]));
		p.siblings = p.parent_families.map(f => (this.props.families[f].children));
		p.spouses = p.families.map(f => ([this.props.families[f].mother, this.props.families[f].father]));
		p.children = p.families.map(f => (this.props.families[f].children));

		//combine arrays of arrayss
		p.children = [].concat(...p.children);
		p.siblings = [].concat(...p.siblings);
		p.spouses = [].concat(...p.spouses);
		p.parents = [].concat(...p.parents);
		
		//remove nulls from children
		p.children = p.children.filter(x => x);

		//get ids for children & siblings
		p.children = p.children.map(c => c.person_id);
		p.siblings = p.siblings.map(c => c.person_id);

		//remove self from siblings and spoused
		p.spouses = p.spouses.filter(x => x!=self);
		p.siblings = p.siblings.filter(x => x!=self);

		//format people w/type
		p.children = p.children.map(c => ({id: c, type: 'blood'}));
		p.siblings = p.siblings.map(c => ({id: c, type: 'blood'}));
		p.spouses = p.spouses.map(c => ({id: c, type: 'blood'}));
		p.parents = p.parents.map(c => ({id: c, type: 'blood'}));

		});
		//console.log(data, 'in treeData');
		return data;
	}

	findRelation = async (data) => {
		//console.log(data, 'data start')
		let fam = await this.findFamily(data.bPerson);

		//console.log(fam);
		if(fam.multiple) {
		let path;
		for (let i = 0; i < fam.b.length; i++) {
			const b = fam.b[i];
			let thisPath = await this.findPath(data.a, b);
			if (i===0 || !path) {
				path=thisPath;
			} else if(!thisPath) {
				path=path;
			} else if(path.length> thisPath.length) {
				path=thisPath;
			}
		}
		//console.log('step', path);

		if (path) {
			this.processData(path, fam.married);
		} else {
			this.setError();
		}
		
		
		} else {
		
			let b = fam.b;
			this.setState({...this.state, married: fam.married});
			this.setState({input: {fam_a: data.a, fam_b:b, generations: data.nGen, person_b: data.bPerson}});

			let path = await this.findPath(data.a, b);

			if (path) {
				this.processData(path, fam.married);
			} else {
				this.setError();
			}
		}
	}

	setError = () => {
		console.log('error , no path');
		this.setState({result: 'no relation found'});
		this.setState({split: null});
		this.setState({diagram: {}});
		this.setState({error: true});
		axios.post(`/api/family/error`, {path_found: false, user_id: null, user_family: this.state.a, person_id: this.state.bPerson});
	}

	handleSelect = (selectedOption, meta) => {
		this.setState(
			{...this.state, [meta.name]: selectedOption.value}
		);
	}

	handleChange = (event) => {
		this.setState(
			{...this.state, [event.target.name]: event.target.value,}
		);
	}

	render() {
		const {a, b, nGen} = this.state;

		return(
		
			<Box  alignContent='center' flex='grow' direction='row'>

				<Box alignSelf='center' alignContent='center' margin='medium'>
					<Paragraph>WARNING: To test “from” a specific person, choose the family that is their parents.  If they have no family, choose the family of them and their spouse (and results will be off by one generation)
					</Paragraph>
					<Paragraph>
					Current logic does not work if the “from” person is of a higher generation than the “to” person or if the path initially goes down.  The correct path will be found, but the relationship description will likely be wrong. 
					</Paragraph>
				</Box>
				<Box>
				<Box width='200px' alignSelf='center' alignContent='center' margin='medium'>
				<FormField label="From Family" name="family">
				<Select
				styles={customStyles}
				name={'a'}
				onChange={this.handleSelect}
				options={this.props.famIds.map(f=>({label:f, value:f}))}
				/>
				</FormField>
				{a && <FormField label="Generations" name="generations"><Select
						styles={customStyles}
						name={'nGen'}
						onChange={this.handleSelect}
						options={[{label:'1', value:1}, {label:'2', value:2}, {label:'3', value:3}, {label:'4', value:4}, {label:'5', value:5}, {label:'6', value:6}, {label:'7', value:7}]}
					/></FormField>}
				<FormField label='To Person'>
				<Select
				styles={customStyles}
				name={'bPerson'}
				onChange={this.handleSelect}
				options={this.props.allPeople.map(p=>({label:p.person_id+` `+p.name, value:p.person_id}))}
				/> 
				</FormField> 
				{<Button label="find relation" onClick = {()=>this.findRelation(this.state)}/>}
				</Box>
				{this.state.diagram && <TreeLayer gens={this.state.nGen} label="View Full Tree" align='center' error={this.state.error} caption={this.state.relationString} data={this.state.diagram} name={this.props.allPeople.find(p => p.person_id===this.state.bPerson).name}/>}
			<Box alignSelf='center' pad={{vertical: 'medium'}}>    
				{this.state.relationString && <Box alignSelf='center'>{this.props.people[this.state.bPerson].name +' is your '+this.state.relationString}</Box>}
				{this.state.result && !this.state.relationString && <Box alignSelf='center'>{'You are not related (or there is an error)'}</Box>}
				{this.state.diagram && <Box alignSelf='center'>{JSON.stringify(this.state.diagram.levels, null, 6)}</Box>}
				{this.state.input && <Box alignSelf='center'><Text>Input:</Text>{JSON.stringify(this.state.input, null, 6)}</Box>}
				{this.state.result && <Box alignSelf='center'><Text>General:</Text>{JSON.stringify(this.state.result, null, 6)}</Box>}
				{this.state.detail && <Text alignSelf='center'>Steps:</Text>}
				{this.state.detail && this.state.split.map((link, i)=><Box key={i} alignSelf='center'>{JSON.stringify(link, null, 10)}</Box>)}
				{this.state.split && this.state.split.map((subpath, i)=>
				<Box key={i} alignSelf='center'><Text alignSelf='center'>Subpath:</Text>{subpath.relationString}
				{subpath.path.map((link, i)=>
					<Box alignSelf='center'>{JSON.stringify(link, null, 2)}</Box>)}
				</Box>)}
				</Box>
				</Box>
			</Box>
		);
	}
}

const mapStateToProps = state => ({
	allPeople: state.people.allIds.map(id => state.people.byId[id]),
		//array used for dropdown to select people
	people: state.people.byId,
		//detail on people
	families: state.families.byId,
		//detail of families
	famIds: state.families.allIds.sort((a, b) => a - b)
		//family ids in order for dropdown
});

export default connect(mapStateToProps)(You);