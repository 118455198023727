import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import { Box, Button, Form, FormField, Paragraph } from 'grommet';
import { withRouter } from "react-router";

// Admin only page to select parameters for article page query

const ArticleAdmin = (props) => {
	const [person, setPerson] = useState(null);
	const [event, setEvent] = useState(null);
	const [subject, setSubject] = useState(null);
	const [newspaper, setNewspaper] = useState(null);

	useEffect(() => {
		props.dispatch({ type: 'CLEAR_TITLE' });
	}, []);

	const onSubmit = () => {
		let search = [];
		if (event) {
			search.push(`eventId=${event.value}`)
		}
		if (person) {
			search.push(`personId=${person.value}`)
		}
		if (subject) {
			search.push(`subjectId=${subject.value}`)
		}
		if (newspaper) {
			search.push(`newspaperId=${newspaper.value}`)
		}
		search = search.join('&')
		if(search) {
			search = '?' + search
		}
		props.history.push(`/article${search}`);
	}

	const handleReset = () => {
		setPerson(null);
		setEvent(null);
		setSubject(null);
		setNewspaper(null);
	}


	return(
		<Box direction="row-responsive" pad="medium">
		<Box  align="center"   width={'500px'} >
			<Paragraph>Other manual test options: 
				<ul style={{ lineHeight: "30px" }} >
					<li>https://cajacob.family/#/article?today=true </li>
						<li className='indent'>all articles with today’s Month & Day</li>
					<li>https://cajacob.family/#/article?city=Sibley </li>
						<li className='indent'>all articles from a city - spaces in city OK</li>
					<li>https://cajacob.family/#/article?eventId=2   </li>
						<li className='indent'>all articles for an event</li>
					<li>https://cajacob.family/#/article </li>
						<li className='indent'>all articles by date</li>
					<li>https://cajacob.family/#/article?articleId=51 </li>
						<li className='indent'>one specific article</li>
					<li>https://cajacob.family/#/article?id=3 </li>
						<li className='indent'>all articles sorted by and positioned on id</li>
					<li>https://cajacob.family/#/article?people=3,6,8 </li>
						<li className='indent'>articles by multiple person_ids</li>
				</ul>
			</Paragraph>
		</Box>
	<Box  align="center"   width={'500px'}>
		<Form
			onReset={handleReset}
			onSubmit={({ }) => {onSubmit()}}
		>
			<FormField label='Person' name='person'>
				<Select
					value={person}
					onChange={(selectedOption) => setPerson(selectedOption)}
					name={'person'}
					options={props.people.map(p=>({label:p.person_id+` `+p.name, value:p.person_id}))}
				/> 
			</FormField>
			<FormField label='Subject' name='subject'>
				<Select
					value={subject}
					onChange={(selectedOption) => setSubject(selectedOption)}
					name={'subject'}
					options={props.subjects.map(p=>({label:p.subject, value:p.subject_id}))}
				/> 
			</FormField>
			<FormField label='Newspaper' name='newspaper'>
				<Select
					value={newspaper}
					onChange={(selectedOption) => setNewspaper(selectedOption)}
					name={'newspaper'}
					options={props.newspapers.map(p=>({label:p.masthead, value:p.newspaper_id}))}
				/> 
			</FormField>
			<Box direction="row" gap="medium">
				<Button type="submit" primary label="Submit" />
				<Button type="reset" label="Reset" />
			</Box>	
		</Form>
	</Box>
	</Box>
	)
}

const mapStateToProps = state => ({
	people: state.people.allIds.map(id=>state.people.byId[id]),
	subjects: state.subjects.allIds.map(id => state.subjects.byId[id]),
	newspapers: state.newspapers.allIds.map(id => state.newspapers.byId[id])
});

export default withRouter(connect(mapStateToProps)(ArticleAdmin));