import { combineReducers } from 'redux';
import errors from './errorsReducer';
import loginMode from './loginModeReducer';
import user from './userReducer';
import people from './peopleReducer';
import person from './personReducer';
import photos from './photoReducer';
import albums from './albumsReducer';
import albumDetail from './albumDetailReducer';
import families from './familyReducer';
import tree from './treeReducer';
import news from './newsReducer';
import newTree from './newTreeReducer';
import articles from './articleReducer';
import newspapers from './newspaperReducer';
import subjects from './subjectReducer';
import locations from './locationReducer';
import recipes from './recipeReducer';
import title from './titleReducer';

// rootReducer is the primary reducer for our entire project
// It bundles up all of the other reducers so our project can use them.
// This is imported in index.js as rootSaga

// Lets make a bigger object for our store, with the objects from our reducers.
// This is what we get when we use 'state' inside of 'mapStateToProps'
const rootReducer = combineReducers({
	errors, // contains registrationMessage and loginMessage
	loginMode, // will have a value of 'login' or 'registration' to control which screen is shown
	user, // will have an id and username if someone is logged in
	people, //will have an array of people
	person, //will detail data on the current person
	photos,
	albums,
	albumDetail,
	families,
	tree,
	news,
	newTree,
	articles,
	newspapers,
	subjects,
	locations,
	recipes,
	title
});

export default rootReducer;
