import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getArticles(action) {
	try {
		
		const response = yield axios.get('/api/articles/');
		const articles = {
			byId: convertArrayToObject(response.data, 'article_id'),
			allIds: response.data.map((article) => article.article_id)
		};
		
		yield put({type: 'SET_ARTICLES', payload: articles});

	} catch (error) {
		console.log('article get failed', error);
	}
}


function* articleSaga() {
	yield takeLatest('FETCH_ARTICLES', getArticles);
}

export default articleSaga;