export default function findType(item, toPerson, fromPerson, addUp, addDown, last) {
	let subPath = item.path;
	let nDown = addDown;
	let nUp = addUp;
	let nSpouse = 0;
	let relationString = '';
	let type = '';

	//count ups, downs, spouses
	subPath.forEach(e => {
		switch (e.a_to_b) {
			case 'up': nUp++;
				break;
			case 'down': nDown++;
				break;
			case 'spouse': nSpouse++;
				break;
		}
	});


	if (nDown===0 && nUp>0) {
		type = 'direct';
		relationString = directAncestor(toPerson, nUp, nSpouse, subPath);
	} else if (nUp===0 && nSpouse>0) {
		type = 'otherSpouse';
		relationString = otherSpouse(toPerson, nDown, subPath, last);
	}else if(nDown===1 && nUp===1) {
		type = 'sib';
		relationString = Sibling(toPerson, fromPerson, nSpouse, subPath, last);
	} else if(nDown===1 && nUp>1) {
		type = 'auncle';
		relationString = AuntUncle(toPerson, nUp, nDown, nSpouse, subPath, last);
	} else if(nDown>1 && nUp>1) {
		type = 'cousin';
		relationString = cousin(toPerson, nUp, nDown, nSpouse, subPath, last);
	} else if(nDown>1 && nUp===1) {
		type = 'nibling';
		relationString = nibling(toPerson, fromPerson, nUp, nDown, nSpouse, subPath, last);
	} else {
		relationString = 'error';
	}

	return {type: type, relationString: relationString};
}

//creates relation string for direct ancestor
function directAncestor (toPerson, nUp, nSpouse, subPath) {
	let relationString = '';
	let temp = '';
	let gender = '';

	if (toPerson.sex) {
		gender = 'mother';
	} else {
		gender = 'father';
	} 
	
	if (nSpouse===1) {
		let spouse = subPath.find(s => s.a_to_b==='spouse');
		if (spouse.spouse_order==='subsequent') {
			relationString = 'step-'.concat(relationString)
		} else {
			temp = 'previous spouse of ';
			//with previous spouse we need to switch the gender of the toPerson
			if (toPerson.sex) {
				gender = 'father';
			} else {
				gender = 'mother';
			} 
		}
	}

	relationString = relationString.concat(gender);

	if (nUp>1) {
		relationString = 'grand'.concat(relationString);
		if (nUp>2) {
			for (let i = 0; i < (nUp-2); i++) {
				relationString = 'great-'.concat(relationString);
			}
		}
	}

	relationString = temp.concat(relationString);

	return relationString;
}

//1 up, 2+ down, 0-2 spouse
function nibling (toPerson, fromPerson, nUp, nDown, nSpouse, subPath, last) {
	let string ='';

	if (toPerson.sex) {
		string = string.concat('niece');
	} else {
		string = string.concat('nephew');
	} 

	if (nDown>2) {
		for (let i = 0; i < (nDown-2); i++) {
			string = 'great-'.concat(string);
		}
	}

	if (toPerson.person_id !== subPath[subPath.length-1].person_id && !last) {
		//console.log('one', toPerson.person_id, subPath);
		string = string.concat('-in-law')
	} else if(last) {
		if(subPath[subPath.length-1].person_id !== toPerson.person_id && toPerson.families.length) {
			//console.log('two');
			string = string.concat('-in-law')
		} 
	}

	if(nSpouse===1) {
		string = '1/2 '.concat(string);
	} else if (nSpouse > 1){
		string = 'step-'.concat(string);
	}

	return string;
}

//2+ up, 2+ down, 0-2 spouses
function cousin (toPerson, nUp, nDown, nSpouse, subPath, last) {
	let string = ordinalSuffix(nDown-1) + ' cousin';

	if ((nUp-nDown) >0) {
		string = string + ' ' + findTimes(nUp-nDown) + ' removed';
	}

	if (nSpouse===1) {
		string = '1/2 ' + string;
	} else if (nSpouse===2) {
		string = 'step ' + string;
	}

	if (last) {
		if(subPath[subPath.length-1].person_id !== toPerson.person_id && toPerson.families.length) {
			string = string.concat(` (by marriage)`);
		}
	} else if(subPath[subPath.length-1].person_id !== toPerson.person_id) {
		string = string.concat(` (by marriage)`);
	}

	return string;
}

//one down, 2+ up, 0-2 spouses
function AuntUncle (toPerson, nUp, nDown, nSpouse, subPath, last) {
	let string = '';
	if (toPerson.sex) {
		string = string.concat('aunt');
	} else {
		string = string.concat('uncle');
	} 

	if (nUp>2) {
		for (let i = 0; i < (nUp-2); i++) {
			string = 'great-'.concat(string);
		}
	}

	if (last) {
		if(subPath[subPath.length-1].person_id !== toPerson.person_id && toPerson.families.length) {
			string = string.concat(` (by marriage)`);
		}
	} else if(subPath[subPath.length-1].person_id !== toPerson.person_id) {
		string = string.concat(` (by marriage)`);
	}


	if(nSpouse===1) {
		string = '1/2 '.concat(string);
	} else if (nSpouse > 1){
		string = 'step-'.concat(string);
	}

	return string;
}

//one down, one up, 0-2 spouses
function Sibling (toPerson, fromPerson, nSpouse, subPath, last) {
	let string = '';
	let downStep = subPath.find(step => step.a_to_b==='down');

	if (toPerson.sex) {
		string = string.concat('sister');
	} else {
		string = string.concat('brother');
	}

	if (toPerson.person_id !== subPath[subPath.length-1].person_id && !last) {
		//console.log('one');
		string = string.concat('-in-law')
	} else if(last) {
		if(subPath[subPath.length-1].person_id !== toPerson.person_id && toPerson.families.length) {
			//console.log('two');
			string = string.concat('-in-law')
		} 
	}

	if(nSpouse===1) {
		string = '1/2 '.concat(string);
	} else if (nSpouse > 1){
		string = 'step-'.concat(string);
	}

	return string;
}

//one spouse, no ups, possible downs
function otherSpouse (toPerson, nDown, subPath, last) {
	let string = '';
	let temp = '';
	let spouse = subPath.find(s => s.a_to_b==='spouse');

	if (spouse.spouse_order === 'previous') {
		string = string.concat(`prior spouse`);
	} else {
		string = string.concat(`subsequent spouse`);
	}

	if (nDown===0) {
		
	} else {

		if(toPerson.sex) {
			temp = 'daughter';
		} else {
			temp = 'son';
		}

		if (last && subPath[subPath.length-1].a_to_b==='down') {
			if(subPath[subPath.length-1].person_id !== toPerson.person_id && toPerson.families.length) {
				temp = temp.concat('-in-law');
			}
		} else if(subPath[subPath.length-1].person_id !== toPerson.person_id && subPath[subPath.length-1].a_to_b==='down') {
			temp = temp.concat('-in-law');
		}

		if (nDown>1) {
			temp = 'grand'.concat(temp);
		}

		if (nDown>2) {
			for (let i = 0; i < (nDown-2); i++) {
				temp = 'great-'.concat(temp);
			}
		} 

		string = temp + ' with a ' + string;
	}

	return string;
}

//formats n 'times removed'
function findTimes (i) {
	switch(i) {
		case 1:
			return 'once';
		case 2:
			return 'twice';
		case 3:
			return 'three times';;
		case 4:
			return 'four times';
		case 5:
			return 'five times';
		case 6:
			return 'six times';
		case 7:
			return 'seven times';
		case 8:
			return 'eight times';
		case 9:
			return 'nine times';
		case 10:
			return 'ten times';
		case 11:
			return 'eleven times';
		case 12:
			return 'twelve times';
		case 13:
			return 'thirteen times';
		case 14:
			return 'fourteen times';
		case 15:
			return 'fifteen times';
		default:
			return null;
	} 
}

//formats 1st, 2nd, 3rd, etc
function ordinalSuffix (n) {
	let j = n % 10;
	let k = n % 100;
	if (j === 1 && k !== 11) {
		return n + "st";
	}
	if (j === 2 && k !== 12) {
		return n + "nd";
	}
	if (j === 3 && k !== 13) {
		return n + "rd";
	}
	return n + "th";
}