import React, { Component } from 'react';
import {
    Box
  } from "grommet";
import { connect } from 'react-redux';

// props: l & r - person ids for the people to the left and right of this dot 
//       if either don't exist the dot is not needed and an empty box is returned

class Dot extends Component {

    render() {
        if (this.props.l || this.props.r) {
            return(
                <Box id={this.props.id} width={"6px"} height={"6px"} background="accent-2" align='center'/>
            )
        } else {
            return <Box />;
        }
    }
}

export default connect()(Dot);