import React, { Component } from 'react';
import {Box} from 'grommet';
import DBImage from '../Reused/DBImage';

//uptodate
//only prop is id
class SidebarPhoto extends Component {

  render() {
    return(
      <Box height="xsmall" width="xsmall" direction="column" margin={{right: "small"}}>
        <DBImage id={this.props.photo_id} fit="cover" />  
      </Box>
    )
  }
}

export default SidebarPhoto;