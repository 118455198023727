import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Box,
	Heading,
	Button,
	Form,
	TextInput,
	FormField,
} from "grommet";

class LoginPage extends Component {
	state = {
		username: '',
		password: '',
	};
	

	login = (event) => {
		event.preventDefault();

		if (this.state.username && this.state.password) {
			this.props.dispatch({
				type: 'LOGIN',
				payload: {
					username: this.state.username.toLowerCase().trim(),

					
					password: this.state.password,
				},
			});
			this.props.history.push(`/home`);
		} else {
			this.props.dispatch({ type: 'LOGIN_INPUT_ERROR' });
		}
	}

	handleInputChangeFor = propertyName => (event) => {
		this.setState({
			[propertyName]: event.target.value,
		});
	}

	render() {
		return (
			<Box fill align="center" justify="center">
				
				{this.props.errors.loginMessage && (
					<Heading
						className="alert"
						role="alert"
						level="4"
					>
						{this.props.errors.loginMessage}
					</Heading>
				)}

				<Form onSubmit={this.login}>

					<Heading>Login</Heading>

					<FormField label="email">
						<TextInput
							type="text"
							name="username"
							value={this.state.username}
							onChange={this.handleInputChangeFor('username')}
						/>
					</FormField>

					<div>
						<FormField label="password">
							<TextInput
								type="password"
								name="password"
								value={this.state.password}
								onChange={this.handleInputChangeFor('password')}
							/>
						</FormField>
					</div>

					<div>
						<Button
						margin={'xsmall'}
							type="submit"
							name="submit"
							label="Log In"
						/>
					</div>

					<div>
						<Link className='text-link' to={`/forgot`}>Forgot Password</Link>
					</div>
					
				</Form>
			
			</Box>
		);
	}
}

const mapStateToProps = state => ({
	errors: state.errors,
});

export default connect(mapStateToProps)(LoginPage);
