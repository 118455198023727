const newspaperReducer = (state = [], action) => {
	switch (action.type) {
		case 'SET_NEWSPAPERS':
			return action.payload;
		default:
			return state;
	}
};
	
// newspaper list will be on the redux state at:
// state.newspapers
export default newspaperReducer;