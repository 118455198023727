import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
    Box,
    Carousel
  } from "grommet";
import AlbumLightbox from '../AlbumManage/AlbumLightbox';
import ImageWithCaption from './ImageWithCaption';

// Displays album in a carosel; used for person album on person page
// Props: album_id

class AlbumCarosel extends Component {

    render() {
        if (this.props.album) {
            if (this.props.album.photos.length && this.props.album_id) {
                return(<>
                    <Box margin='small' align="center" width="medium" overflow="hidden" height="medium">
                        <Carousel key={this.props.album_id} fill controls="arrows" pad='small' initialChild={0}>
                            { this.props.album.photos.map(photo=>
                                <ImageWithCaption key={photo} photo_id={photo}/>
                            )}
                        </Carousel>  
                    </Box>
                    <AlbumLightbox album_photos={this.props.album.photos} buttonText={'View Full Screen'}/>
                </>)
            } else {
                return null;
            } 
            
        } else {
            return null;
        }

    }
}

const mapStateToProps = (state, ownProps) => ({
    album: state.albums.byId[ownProps.album_id],
    album_id: ownProps.album_id
})

export default connect(mapStateToProps)(AlbumCarosel);