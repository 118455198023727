// Define the initial state
const initialState = {
    text: '',
    photo_id: ''
};

const titleReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CLEAR_TITLE':
			console.log('clearing');
			return initialState;
		case 'SET_TITLE':
			return action.payload;
		default:
			return state;
	}
};
	
// title data will be on the redux state at:
// state.title
export default titleReducer;