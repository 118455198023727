import { all } from 'redux-saga/effects';
import loginSaga from './loginSaga';
import registrationSaga from './registrationSaga';
import userSaga from './userSaga';
import peopleSaga from './peopleSaga';
import bioSaga from './bioSaga';
import photoSaga from './photoSaga';
import albumSaga from './albumSaga';
import familySaga from './familySaga';
import treeSaga from './treeSaga';
import newsSaga from './newsSaga';
import articleSaga from './articleSaga';
import newspaperSaga from './newspaperSaga';
import subjectSaga from './subjectSaga';
import resetSaga from './resetSaga';
import locationSaga from './locationSaga';
import recipeSaga from './recipeSaga';
// import updateSaga from './updateSaga';

// rootSaga is the primary saga.
// It bundles up all of the other sagas so our project can use them.
// This is imported in index.js as rootSaga

// some sagas trigger other sagas, as an example
// the registration triggers a login
// and login triggers setting the user
export default function* rootSaga() {
	yield all([
		loginSaga(),
		registrationSaga(),
		userSaga(),
		peopleSaga(),
		bioSaga(),
		photoSaga(),
		albumSaga(),
		familySaga(),
		treeSaga(),
		newsSaga(),
		articleSaga(),
		newspaperSaga(),
		subjectSaga(),
		resetSaga(),
		locationSaga(),
		recipeSaga()
	]);
}
