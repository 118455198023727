const rHeight = 60;
const rWidth = 150;
const up = 100;
const across = 120;
const youHeight = 60;
const youWidth = 100;
let x = 0;
let y = 0;
let connections = [];
let pCoords = [];
let fCoords = [];
let dashArray = '5';


export default function createDiagram(fams, people, nGen, toPerson) {

	let coords = findCoords(fams, people, nGen, toPerson);    
	coords.families = coords.families.map(f=>formatFam(f));
	return (coords);

}

function formatFam(fam) {
	//console.log(fam);
	let x = fam.x;
	let y = fam.y-(up/10);
	let id = fam.id;
	let mDate;

	if (fam.family.events) {
		mDate = fam.family.events.find(e => e.name==="Marriage").date;
		if (mDate) {
			mDate = mDate.slice(-4);
			mDate = ' '+mDate;
		}
	}
	
	return {x: x, y: y, id: id, mDate: mDate}
}

function findCoords(fams, people, nGen, toPerson) {
	//console.log(fams, 'people:', people, nGen, toPerson);

	x=0;
	y=0;
	connections = [];
	pCoords = [];
	fCoords = [];

	//add 'you' box
	pCoords.push({id: 'y', x: x, y: y});

	//if needed add generations box
	if (nGen>1) {
		step('up');
		pCoords.push({id: 'g', x: x, y: y});
	} 

	//loop thru people, build family a to family b
	for (let i = 0; i < people.length; i++) {
		const p = people[i];
		const f = fams[i];

		//if after first step, set direction of previous step
		let prevDir;
		let prevPerson;
		if (i>0) {
			prevDir = people[i-1].a_to_b;
			prevPerson = people[i-1];
		}
		//up after spouse
		if (p.a_to_b==='up' && prevDir==='spouse') {
			step('right');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			step('right');
			pCoords.push({id: p.person_id, x: x, y: y});
		//up after down
		}else if(p.a_to_b==='up' && prevDir==='down') {
			//console.log('this is the step:', p, f);
			step('rightExtra');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			step('rightExtra');
			pCoords.push({id: p.person_id, x: x, y: y});
		}else if(p.a_to_b==='up') {
			step('up');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			addSpouse(p,f, 'left');
			step('right');
			pCoords.push({id: p.person_id, x: x, y: y});
		//down after up
		} else if(p.a_to_b==='down' && prevDir==='up') {
			step('upR');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			addBothSpouses(f);
			step('downR');
			pCoords.push({id: p.person_id, x: x, y: y});
		//down after down
		} else if(p.a_to_b==='down' && prevDir==='down') {
			step('right');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			addSpouse(prevPerson ,f , 'right');
			step('down');
			pCoords.push({id: p.person_id, x: x, y: y});
		//down after spouse
		} else if(p.a_to_b==='down' && prevDir==='spouse') {
			step('right');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			addSpouse(prevPerson ,f , 'right');
			step('down');
			pCoords.push({id: p.person_id, x: x, y: y});
		//spouse after dowm
		} else if(p.a_to_b==='spouse' && prevDir==='down') {
			// console.log('spouse', p, f);
			step('right');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			step('right');
			pCoords.push({id: p.person_id, x: x, y: y});
		//spouse at start
		} else if(p.a_to_b==='spouse' && !prevDir) {
			// console.log('spouse start', p, f);
			step('up');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			addSpouse(p,f,'left');
			step('right');
			pCoords.push({id: p.person_id, x: x, y: y});
		//spouse after spouse
		} else if(p.a_to_b==='spouse' && prevDir==='spouse') {
			//console.log('spouse', p, f);
			step('right');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			step('right');
			pCoords.push({id: p.person_id, x: x, y: y});
		} else if(p.a_to_b==='spouse') {
			//console.log('spouse', p, f);
			step('up');
			fCoords.push({id: f.family_id, x: x, y: y, family: f});
			addSpouse(p,f, 'left');
			step('right');
			pCoords.push({id: p.person_id, x: x, y: y});
		} else {
			console.log(i, p.a_to_b, prevDir, 'no step being added here');
		}
		//down after down
		
	}

	//add toPerson on end
	let lastFam = fams[fams.length-1];
	if (people.length) {
		let lastPerson = people[people.length-1];
		if(toPerson.person_id===lastPerson.person_id) {
			pCoords[pCoords.length-1].end = true;
		} else if(toPerson.families.includes(lastFam.family_id) && lastPerson.a_to_b==='spouse') {
			step('right');
			fCoords.push({id: lastFam.family_id, x: x, y: y, family: lastFam});
			step('right');
			pCoords.push({id: toPerson.person_id, x: x, y: y, end: true});
		} else if(toPerson.families.includes(lastFam.family_id) && lastPerson.a_to_b==='up') {
			step('up');
			fCoords.push({id: lastFam.family_id, x: x, y: y, family: lastFam});
			addSpouse(toPerson, lastFam, 'left');
			step('right');
			pCoords.push({id: toPerson.person_id, x: x, y: y, end: true});
		} else if(toPerson.families.includes(lastFam.family_id) && lastPerson.a_to_b==='down') {
			step('right');
			fCoords.push({id: lastFam.family_id, x: x, y: y, family: lastFam});
			step('right');
			pCoords.push({id: toPerson.person_id, x: x, y: y, end: true});
		} else if (toPerson.families.length===0 && lastPerson.a_to_b==='up') {
			step('upR');
			fCoords.push({id: lastFam.family_id, x: x, y: y, family: lastFam});
			addBothSpouses(lastFam);
			step('downR');
			pCoords.push({id: toPerson.person_id, x: x, y: y, end: true});
		} else if (toPerson.families.length===0) {
			step('right');
			fCoords.push({id: lastFam.family_id, x: x, y: y, family: lastFam});
			addSpouse(lastPerson, lastFam, 'right');
			step('down');
			pCoords.push({id: toPerson.person_id, x: x, y: y, end: true});  
		} else {
			console.log('toPerson not being handled', lastPerson, toPerson);
		}
	} else {
		step('up');
		fCoords.push({id: lastFam.family_id, x: x, y: y, family: lastFam});
		addSpouse(toPerson, lastFam, 'left');
		step('right');
		pCoords.push({id: toPerson.person_id, x: x, y: y, end: true});
	}


	//format people as rectangles
	pCoords = pCoords.map(p=>formatRect(p));

	//shift out of negative coordinates
	let xs = pCoords.map(r=>r.x);
	let ys = pCoords.map(r=>r.y);
	//console.log('x and y', xs, ys);
	let maxX = Math.max(...xs)+rWidth+50;
	let minX = Math.min(...xs)-50;
	let maxY = Math.max(...ys)+rHeight+50;
	let minY = Math.min(...ys)-50;
	//console.log(minX, maxX, minY, maxY);

	let height = maxY-minY;
	let width =  maxX-minX;

	if(minX<0) {
		pCoords = pCoords.map((p)=>{
		   return {...p, x: (p.x-minX), centerX: (p.centerX-minX)}
		})
		connections = connections.map((c)=>{
			return {...c, x1: (c.x1-minX), x2: (c.x2-minX)}
		})
		fCoords = fCoords.map((f)=>{
			return {...f, x: (f.x-minX)}
		})
		minX = 0;
	}

	if(minY<0) {
		pCoords = pCoords.map((p)=>{
		   return {...p, y: (p.y-minY), centerY: (p.centerY-minY)}
		})
		connections = connections.map((c)=>{
			return {...c, y1: (c.y1-minY), y2: (c.y2-minY)}
		})
		fCoords = fCoords.map((f)=>{
			return {...f, y: (f.y-minY)}
		})
		minY = 0;
	}

	let result = {connections: connections, rectangles: pCoords, width: width, height: height, families: fCoords}

	return result;
   
}

function step(dir) {
	if(dir==='up') {
		let conn = {x1: x, y1: y, line: '0'};
		y=y-up;
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
	} else if(dir==='down') {
		let conn = {x1: x, y1: y, line: '0'};
		y=y+up;
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
	} else if(dir==='right') {
		let conn = {x1: x, y1: y, line: dashArray};
		x=x+across;
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
	} else if(dir==='rightExtra') {
		let conn = {x1: x, y1: y, line: dashArray};
		x=x+across*1.7;
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
	} else if(dir==='left') {
		let conn = {x1: x, y1: y, line: dashArray};
		x=x-across;
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
	} else if(dir==='upR') {
		let conn = {x1: x, y1: y, line: '0'};
		y=y-(up/2);
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
		conn = {x1: x, y1: y, line: '0'};
		x=x+across;
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
		conn = {x1: x, y1: y, line: '0'};
		y=y-(up/2);
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
	} else if(dir==='downR') {
		let conn = {x1: x, y1: y, line: '0'};
		y=y+(up/2);
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
		conn = {x1: x, y1: y, line: '0'};
		x=x+across;
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
		conn = {x1: x, y1: y, line: '0'};
		y=y+(up/2);
		conn.x2 = x;
		conn.y2 = y;
		connections.push(conn);
	}
	return;


}

//take coordinates of center of rectangle and give corner coordinates
function formatRect(object) {
	object.centerX = object.x;
	object.centerY = object.y;
	if (object.id==='y') {
		object.x = object.x-(youWidth/2);
		object.y = object.y-(youHeight/2);
		object.width = youWidth;
		object.height = youHeight;
	} else {
		object.x = object.x-(rWidth/2);
		object.y = object.y-(rHeight/2);
		object.width = rWidth;
		object.height = rHeight;   
	}
	return object;
}

function addSpouse(p, f, dir) {
	let xTemp;
	if (dir==='left') {
		xTemp = x-across;
	} else {
		xTemp = x+across;
	}
	let id =p.person_id;

	connections.push({x1: x, y1: y, x2: xTemp, y2: y, line: dashArray});

	if (f.mother===id) {
		pCoords.push({id: f.father, x: xTemp, y: y});
		//console.log(f.father, 'id');
	} else if(f.father===id) {
		pCoords.push({id: f.mother, x: xTemp, y: y});
		//console.log(f.mother, 'id');
	} else {
		console.log('something weird here', p, f, dir);
	}
	return
}

function addBothSpouses(f) {
	connections.push({x1: x, y1: y, x2: x+across, y2: y, line: dashArray});
	connections.push({x1: x, y1: y, x2: x-across, y2: y, line: dashArray});
	pCoords.push({id: f.father, x: x+across, y: y});
	pCoords.push({id: f.mother, x: x-across, y: y});
}