import React, { Component } from 'react';
import { connect } from 'react-redux';
import PersonLink from './PersonLink';
import Event from './Event';
import { Box, Text } from "grommet";
import Parents from './Parents';

// Displays a family on person page
// props: id - family id
// 		  self - id of person who's person page we are on

class Family extends Component {

    render() {
        if (!this.props.family) {
            return null;
        } else if (this.props.type==='parents') {
            return(
                <Box pad={{top:"none", bottom:"small"}} >
                    <Text color="accent-1" margin={{top: 'small'}}>Parents</Text>
                    <Parents family={this.props.family}/>
                    {(this.props.family.children.length>1) && <>
						<Text color="accent-1" margin={{top: 'small'}}>Siblings</Text>
						<Box > 
							<ul padding="none" style={{ paddingLeft:0, listStyleType: "none", margin: "unset" }}>
								{this.props.family.children.map(child=>
									<li><PersonLink key={child.person_id} person={child} id={child.person_id} self={child.person_id !== this.props.self ? false: true}/>  </li>
								)}
							</ul>
						</Box>
                    </>}
                </Box>
            )
		} else if (this.props.type==='partner') {
            return(
                <Box pad={{top:"none", bottom:"small"}} >
                    {this.props.family.father && (this.props.family.father !== this.props.self) && <>
                    	<Text margin={{top: 'small'}} color="accent-1">Partner <PersonLink photo={true} id={this.props.family.father}/></Text>
					</>}
    
                    {this.props.family.mother && (this.props.family.mother !== this.props.self) && <>
                    	<Text margin={{top: 'small'}} color="accent-1">Partner <PersonLink photo={true} id={this.props.family.mother}/></Text>
					</>}
    
                    <ul style={{margin: "unset", listStyleType: "none", fontStyle: "italic", paddingLeft: "0", color: "#4b4b4b"}}>
						{this.props.family.events && this.props.family.events.map(event=>
							<Event key={event.event_id} event={event}/>
						)}
                    </ul>
                    {this.props.family.children && <>
						<Text color="accent-1" margin={{top: 'small'}}>Children</Text>
						<Box pad={{top:"none", bottom:"none"}}> 
							<ul padding="none" style={{ paddingLeft:0, listStyleType: "none", margin: "unset" }}>
								{this.props.family.children.map(child=>
									(child.person_id !== this.props.self) &&
									<li key={child.person_id}><PersonLink key={child.person_id} id={child.person_id}/>  </li>
								)}
							</ul>
						</Box>
                    </>}
                </Box>
        	)
		} 
    } 
}

const mapStateToProps = (state, ownProps) => {
    return {
      	family: state.families.byId[ownProps.id],
    }
}
  
export default connect(mapStateToProps)(Family);