import React, { Component } from 'react';
import {
    Heading,
    Box,
    Button
  } from "grommet";
import { connect } from 'react-redux';
import RoutedButton from '../Reused/RoutedButton';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Article } from 'grommet-icons';
import PersonLink from '../PersonPage/PersonLink';
import Event from "./Event";

class Today extends Component {
    
    state = {
        articles: [],
        events: []
    };
    

    componentDidMount() {
        this.props.dispatch({type: 'SET_TITLE', payload: {text: `Today in History`, photo_id: ''}});
        let date = new Date();

        axios.get(`/api/articles/today/${date}`)
        .then((results) => {
            this.setState({...this.state, articles: results.data});
        }).catch((error) => {
            //console.log('error getting today in history', error); 
        })

        axios.get(`/api/articles/history/${date}`)
        .then((results) => {
            this.setState({...this.state, events: results.data});
            //console.log(results.data, 'events', date);
        }).catch((error) => {
            console.log('error updating news seen to server', error); 
        })
    }

    render() {
        return(
            <Box flex='grow'>

            <Box  width='500px' alignSelf='center'>

                {this.state.events[0] && <>
                <Heading level="4" color="accent-1" alignSelf='start'>
                    Events
                </Heading>
                <ul style={{marginTop: "0px"}}>
                        {this.state.events.map(event=>
                        <Event key={event.event_id} event={event} people={this.props.people} showArticleLink={true}/>
                    )}
                </ul>
                </>}

                {this.state.articles[0] && <>
                <Heading level="4" color="accent-1" alignSelf='start'>
                    In the News
                </Heading>
                <Link className='text-link' margin={'xsmall'} to={`/article?today=true`}><Box direction="row" gap='small'><Article color='#63b9d6'/>Read all about it!</Box></Link>
                </>} 

                {!this.state.events[0] && !this.state.articles[0] && 
                <Heading level="5">Nothing happened today in history.</Heading>
                }          

            </Box>

            </Box>
        )
    }
}

const mapStateToProps = state => ({
    people: state.people.byId
});


export default connect(mapStateToProps)(Today);