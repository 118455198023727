import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Box, Text} from 'grommet';
import {connect} from 'react-redux';

//Props 
class ArticleLink extends Component {

    onSubmit = (props) => {
        let search = [];
        const params = new Set(['personId', 'subjectId', 'eventId', 'newspaperId', 'city', 'startDate', 'endDate', 'articleId'])
        for (const key in props) {
            if (Object.hasOwnProperty.call(props, key) && params.has(key)) {
                console.log(key, props[key]);
                search.push(`${key}=${props[key]}`)
            }
        }
        search = search.join('&')
        if(search) {
          search = '?' + search
        }
        console.log(search);
        return search
      }


    render() {
        const string = this.onSubmit(this.props);

        return(<Link className='text-link' to={`/article${string}`}>{this.props.text}</Link>)
        
    }


}

export default connect()(ArticleLink);