import React, {useRef, useState, useLayoutEffect, useEffect} from 'react';
import {INITIAL_VALUE, ReactSVGPanZoom, TOOL_PAN, TOOL_NONE, fitSelection, zoomOnViewerCenter, fitToViewer} from 'react-svg-pan-zoom';
import SVGPerson from './SVGPerson';
import Toolbar from './Toolbar.jsx';

export default function TreeZoom(props) {
	const targetRef = useRef();
	const [dimensions, setDimensions] = useState({});

	const Viewer = useRef(null);
	const [tool, setTool] = useState(TOOL_PAN);
	const [value, setValue] = useState(INITIAL_VALUE);

	useLayoutEffect(() => {
		if (targetRef.current) {
			setDimensions({
				width: parseInt(targetRef.current.offsetWidth),
				height: parseInt(targetRef.current.offsetHeight)
			});
			if(Viewer.current) {
				Viewer.current.fitToViewer("center", "center");
			}
		} 
	}, []);


	useEffect(() => {
		if (Viewer.current && props.position==='full') {
			Viewer.current.fitToViewer("center", "center");
		} else if(Viewer.current) {
			Viewer.current.fitSelection((props.width/2 - dimensions.width*3/4), (props.height/4), dimensions.width, props.height)
		}
	}, [dimensions]);

	function onClose() {
		props.onClose();
	}


	return (
		<div style={{width: "100%", height: "100%"}} ref = {targetRef}>

			{dimensions.width && <ReactSVGPanZoom
				ref={Viewer}
				width={dimensions.width} height={dimensions.height}
				tool={tool} onChangeTool={setTool}
				defaultTool={'pan'}
				detectAutoPan={false}
				miniatureProps={{position: 'none'}}
				value={value} onChangeValue={setValue}
				//onZoom={e => console.log(value, INITIAL_VALUE)}
				// onPan={e => console.log('pan')}
				// onClick={event => console.log('click', event.x, event.y, event.originalEvent)}
				customToolbar={Toolbar}
				toolbarProps={{ onClose: onClose, tool: tool, value: value, onChangeValue: setValue, position: 'bottom'}}
				background='white'
			>
				<svg width={props.width} height={props.height} >
					{props.connections.map(c=>
						<line x1={c.x1} x2={c.x2} y1={c.y1} y2={c.y2} stroke='#444444' strokeWidth='2' strokeDasharray={c.line}/>)}
					{props.families && props.families.map(f=>
						<text textAnchor="middle" dominantBaseline='middle' x={f.x} y={f.y} fontFamily="'Montserrat', Tahoma, Geneva, Verdana, sans-serif" fontSize="12" fill="#444444">m.{f.mDate}</text>)}
					{props.rectangles.map(r=>
						<SVGPerson gens={props.gens} condensed={true} r={r} onClose={onClose} font="14.4"/>)}
					{props.polygons && props.polygons.map(p=>
						<polygon points={p.points}></polygon>)}
						Sorry, your browser does not support inline SVG.  
				</svg>
			</ReactSVGPanZoom>}
		</div>
	)
}