import React, { Component } from 'react';
import { connect } from 'react-redux';
import LogOutButton from '../LogOutButton/LogOutButton';
import RoutedButton from '../Reused/RoutedButton';
import {
	Box,
	Text
} from "grommet";
import { Search } from 'grommet-icons';

// Nav menu

class Nav extends Component { 

	render() {
		const bar = {
			borderLeft:  "3px solid black",
			marginTop: "3%",
			marginBottom: "3%",
			borderColor: "#919191"
		} 

		return(
			<Box direction="row">

				<RoutedButton path="/list" hoverIndicator>
					<Box pad={{ horizontal: "medium" }}>
						<Text>{this.props.user.user_id ? <Search/> : 'Login'}</Text>
					</Box>
				</RoutedButton>

				{this.props.user.admin && (
					<>
						<Box style={bar}/>
						<RoutedButton path='/admin' hoverIndicator>
							<Box float='left' pad={{ horizontal: "medium" }}>
								<Text alignSelf="start">Admin</Text>
							</Box>
						</RoutedButton>
					</>
				)}  

				{this.props.user.user_id && <> 
					<Box style={bar}/>

						<RoutedButton path="/today" hoverIndicator>
							<Box pad={{ horizontal: "medium" }}>
								<Text>Today in History</Text>
							</Box>
						</RoutedButton>
						<Box style={bar}/>

						<Box style={bar}/>
						<RoutedButton path="/article" hoverIndicator>
							<Box pad={{ horizontal: "medium" }}>
								<Text>Newspapers</Text>
							</Box>
						</RoutedButton>
						<Box style={bar}/>

						<RoutedButton path="/new" hoverIndicator>
							<Box pad={{ horizontal: "medium" }}>
								<Text>What's New</Text>
							</Box>
						</RoutedButton>
					<Box style={bar}/>

					<RoutedButton path="/info" hoverIndicator>
						<Box pad={{ horizontal: "medium" }}>
							<Text>About</Text>
						</Box>
					</RoutedButton>

					<Box style={bar}/>
						<RoutedButton path='/reset_in' hoverIndicator>
							<Box float='left' pad={{ horizontal: "medium" }}>
								<Text alignSelf="start">Change Password</Text>
							</Box>
						</RoutedButton>
					<Box style={bar}/> 

					<LogOutButton/> 
					
				</>}

			</Box>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
});

export default connect(mapStateToProps)(Nav);
