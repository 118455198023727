import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
	Box,
	Text,
	Button,
	DataTable
} from "grommet";
import { Link } from 'react-router-dom';
import RoutedButton from '../Reused/RoutedButton';
import DBImage from '../Reused/DBImage';
import { Checkmark } from 'grommet-icons';

// Table of photos on orphan photo page
// Props: photos - array of photo objects

const OrphanPhotoTable = (props) => { 

	const deletePhoto = (photo_id) => {
		if ( window.confirm('Delete this photo?') ) {
			axios.delete(`/api/photo/${photo_id}`)
			.then((results) => {
				console.log('deleted');
				props.dispatch({type: 'FETCH_PHOTOS'});
				props.dispatch({type: 'FETCH_PEOPLE'});
			}).catch((error) => {
				console.log('error deleting photo', error);
			})
		}
	}

	const [sort, setSort] = React.useState({
		property: "name",
		direction: "desc"
	});

			
	const DATA = props.photos;

	const columns = [
		{
			property: 'photo_id',
			header: <Text>ID</Text>,
			sortable:true,
			primary: true
		},
		{
			property: 'image',
			render: datum => 
				<Box height="xsmall" width="xsmall" border>
					<DBImage id={datum.photo_id}  fit="contain" thumbnail={true} />
				</Box>,  
			sortable:false
		},
		{
			property: 'photo_url',
			header: <Text>url</Text>,
			search: true,
			sortable:true
		},
		{
			property: 'people',
			header: <Text>people</Text>,
			render: datum => datum.people && <ul>{datum.people.map(person => <li key={person.person_id}><Link className='text-link' to={`/person/${person.person_id}`}>{person.name}</Link></li>)}</ul>,
			sortable: false,
			search: false
		},
		{
			property: 'header',
			header: <Text>H</Text>,
			sortable: true,
			render: datum => datum.header && <Checkmark/>
		},
		{
			property: 'thumbnail',
			header: <Text>T</Text>,
			sortable: true,
			render: datum => datum.thumbnail && <Checkmark/>
		},
		{
			property: 'updated_at',
			header: 'updated',
			render: datum =>
				datum.updated_at && new Date(datum.updated_at).toLocaleString('en-US', {dateStyle:'short', timeStyle: 'short'}),
			align: 'end',
			sortable:true
		},
		{
			property: 'location',
			header: <Text>location</Text>,
			search: true,
			sortable: true
		},
		{
			property: 'photo_id',
			render: datum => <RoutedButton path={`/editPhoto/${parseInt(datum.photo_id)}`} label={'edit'}/>,
			sortable: false
		},
		{
			property: 'delete',
			render: datum => <Button onClick={()=>deletePhoto(datum.photo_id)} label={'delete'}/>,
			sortable: false
		},
	];
	
	return(
		<Box align="center" fill='horizontal'>
			{props.photos && 
				<DataTable
					columns={columns}
					data={DATA}
					sortable
					replace={false}
					sort={{direction:'desc', property:'updated_at'}}
				/>
			}
		</Box>
	);
}

export default connect()(OrphanPhotoTable);