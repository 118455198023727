import React from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Text,
	Button,
	DataTable
} from "grommet";
import { withRouter } from "react-router";

// Table of orphan albums
// props: ids - array of album ids

const OrphanAlbumTable = (props) => { 

	const [sort, setSort] = React.useState({
		property: "name",
		direction: "desc"
	});


	const onClick = (event) => {
		props.history.push(`/editAlbum/${event}`);
	};
			
	const DATA = props.albums;

	const columns = [
		{
			property: 'album_id',
			header: <Text>ID</Text>,
			sortable:true,
			primary: true
		},
		{
			property: 'name',
			header: <Text>Name</Text>,
			sortable:true,
			search: true
		},
		{
			property: 'updated_at',
			header: 'updated',
			render: datum =>
				datum.updated_at && new Date(datum.updated_at).toLocaleString('en-US', {dateStyle:'short', timeStyle: 'short'}),
			align: 'end',
			sortable:true
		},
		{
			property: 'edit',
			render: datum => <Button onClick={(event)=>onClick(datum.album_id, event)} label={'edit'}/>,
			sortable: false
		},
	]
	
	return(
		<Box align="center" fill='horizontal'>
			{props.albums && 
				<DataTable
						columns={columns}
						data={DATA}
						sortable
						replace={false}
						sort={{direction:'desc', property:'updated_at'}}
				/>
			}
		</Box>
	);
}

const mapStateToProps = (state, ownProps) => ({
	albums: ownProps.ids.map(id => state.albums.byId[id])
});

export default withRouter(connect(mapStateToProps)(OrphanAlbumTable));