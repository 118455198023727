import React, { Component } from 'react';
import {
    Box,
    Text
  } from "grommet";
import { connect } from 'react-redux';

class GenerationLabel extends Component {

    constructor(props) {
        super(props);
        let string;
        if (this.props.generations) {
            string = this.props.generations.toString().concat(' generation');
        }
        if (this.props.generations>1) {
            string = string.concat('s')
        }
        this.state = {
            string: string
          };
      }

    render() {  
        if (this.props.generations) {
            return(
                <Box id={this.props.id} width={{"max": "400%"}} pad="xxsmall" background="white" round={'xsmall'} align='center' justifyContent='center'>
                    <Text size='12px' textAlign='center' truncate={true}>{this.state.string}</Text>
                </Box>
            )
        } else {
            return <Box />;
        }
    }
}

export default connect()(GenerationLabel);