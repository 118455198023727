import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getLocations(action) {
	try {
		
		const response = yield axios.get('/api/location/all');
		const locations = {
			byId: convertArrayToObject(response.data, 'location_id'),
			allIds: response.data.map((location) => location.location_id)
		};
		yield put({type: 'SET_LOCATIONS', payload: locations});

	} catch (error) {
		console.log('location get failed', error);
	}
}


function* locationSaga() {
	yield takeLatest('FETCH_LOCATIONS', getLocations);
}

export default locationSaga;