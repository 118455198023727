import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getTree(action) {
	try {
		const response = yield axios.get('/api/family/tree');
		const data = response.data.map((p)=>p.person);
		data.forEach(r => {
		if (r.pppp || r.pppm || r.ppmp ||r.ppmm || r.pmpp || r.pmpm ||r.pmmp ||r.pmmm ) {
			r.paternal = true;
		} else {
			r.paternal = false;
		}
		if (r.mmmm || r.mmmp || r.mmpm ||r.mmpp || r.mpmm || r.mpmp ||r.mppm ||r.mppp ) {
			r.maternal = true;
		} else {
			r.maternal = false;
		} 
	});
		const tree = {
			byId: convertArrayToObject(data, 'person_id'),
			allIds: data.map((p) => p.person_id)
		};
		
		yield put({type: 'SET_TREE', payload: tree});

	} catch (error) {
		console.log('tree get failed', error);
	}
}


function* treeSaga() {
 	yield takeLatest('FETCH_TREE', getTree);
}

export default treeSaga;