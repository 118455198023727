import React, { Component, Fragment } from 'react';
import {
    Box
  } from "grommet";
import {connect} from 'react-redux';
import SVGPerson from './SVGPerson';
import { withRouter } from "react-router";

//props: id
class SVGRectangle extends Component {


    onClose() {
        console.log('no');
    }
    

    render() {
        if (!this.props.person_id) {
            return <Box/>;
        }
        else if (this.props.person_id==='y') {
            const r = {
                id: 'y',
                centerX: 55,
                centerY: 35,
                x: 5,
                y: 5,
                width: 100,
                height: 60 
            }
            return(
                <svg width={r.width+10} height={r.height+10} id={this.props.id}>
                    <SVGPerson r={r} onClose={this.onClose} font="14.4"/>
                    Sorry, your browser does not support inline SVG.  
                </svg>
            )
            
        } else {
            const r = {
                id: this.props.person_id,
                centerX: 73,
                centerY: 35,
                x: 5,
                y: 5,
                width: 136,
                height: 60 
            }
            return(
                <svg width={r.width+10} height={r.height+10} id={this.props.id}>
                    <SVGPerson condensed={true} r={r} onClose={this.onClose} font="12"/>
                    Sorry, your browser does not support inline SVG.  
                </svg>
            )
        }
    }


}

export default withRouter(connect()(SVGRectangle));