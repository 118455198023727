import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
	Box,
	Header,
	TextInput,
	Button,
	Form,
	FormField
} from "grommet";

//Page for admin to create a new album

class AddAlbum extends Component { 
    state = {
        name: '',
        displayName: ''
    }

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}


    handleSubmit = (event) => {
        event.preventDefault();
        axios.post('/api/album/', this.state)
            .then((results) => {
				//console.log(results.data[0].album_id);
				const album_id = results.data[0].album_id;
				this.props.dispatch({type: 'FETCH_ALBUMS' });
				this.props.history.push(`/photoUpload/${album_id}`);
            }).catch((error) => {
                console.log('error posting album to server', error); 
            })
    }

    handleInputChangeFor = propertyName => (event) => {
        this.setState({
          [propertyName]: event.target.value,
        });
    }

	render() {
  
		return(
			<Box fill align="center" justify="center" >
				<Header>New Album</Header>
				<Form onSubmit={this.handleSubmit}>
				<FormField label="Name" name="name" htmlFor="text-input">
				<TextInput
					id="text-input"
					name="name"
					placeholder="name"
					value={this.state.name}
					onChange={this.handleInputChangeFor('name')}
				/>
				</FormField>
				<FormField label="Display Name" name="displayName" htmlFor="text-input">
				<TextInput
					id="text-input"
					name="displayName"
					placeholder="display name"
					value={this.state.displayName}
					onChange={this.handleInputChangeFor('displayName')}
				/>
				</FormField>
				<Button
					onClick={()=>this.props.history.goBack()}
					name="cancel"
					label="Cancel"
					/>
				<Button
					type="submit"
					name="submit"
					label="Add"
					/>
				</Form>
			</Box>
		);
	}
}


export default connect()(AddAlbum);