import React from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Text,
	Button
} from "grommet";

const LogOutButton = props => (
	<Button
		onClick={() => props.dispatch({ type: 'LOGOUT' })}
	>
		<Box pad={{ horizontal: "medium", vertical: "small" }}>
			<Text>Log Out</Text>
		</Box>
	</Button>
);

export default connect()(LogOutButton);