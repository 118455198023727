import React, { Component } from 'react';
import {connect} from 'react-redux';
import { renderDateParen } from '../Reused/Utils';


//props: id, x, y
class SVGName extends Component {

	formatLine = (nickname, surname) => {
		let result = '';
		if(nickname) {
			result = `"`+ nickname + `"`;
		}
		if (nickname && surname) {
			result += ' '
		}
		if (surname) {
			result += surname;
		}
		return result;
	}

    offset = (index) => {
        switch (index) {
            case 0:
                return -1;
            case 1:
                return 0;
            case 2:
                return 1;
            default:
                return 0;
        }
    }

    renderText = (textContent, x, y, offset, font, multi) => {
        //console.log(textContent);
        if (multi) {
            return (
                <text textAnchor="middle" dominantBaseline='middle' x={x} y={y} fontFamily="'Montserrat', Tahoma, Geneva, Verdana, sans-serif" fontSize={font} fill="#444444">
                    {textContent.split('\n').map((text, index) => (
                        <tspan key={index} x={x} y={y + this.offset(index) * offset * 1.5}>{text}</tspan>
                    ))}
                </text>
            );
            
        } else {
            return (
                <text textAnchor="middle" dominantBaseline='middle' x={x} y={y} fontFamily="'Montserrat', Tahoma, Geneva, Verdana, sans-serif" fontSize={font} fill="#444444">
                    {textContent}
                </text>
            );
            
        }
    }


    render() {
        const { id, x, y, font, person, gens } = this.props;
        const offset = 10;
  
        let textContent;
        let multi = false;
        if (!id || id === 'u') {
            textContent = 'Unknown';
        } else if (id === 'y') {
            textContent = 'you';
        } else if (id === 'g') {
            const output = gens > 2 ? 's' : '';
            textContent = `${gens - 1} generation${output}`;
        } else {
            const line = this.formatLine(person.nickname, person.surname);
            
            const birthDeathText = renderDateParen(person)

            textContent = `${person.given}\n${line}\n${birthDeathText}`;
            multi = true;
        }

        return this.renderText(textContent, x, y, offset, font, multi);

    }

}

const mapStateToProps = (state, ownProps) => ({
	person: state.people.byId[ownProps.id],
})

export default connect(mapStateToProps)(SVGName);