import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import PersonLink from '../PersonPage/PersonLink';
import {Box, Heading, DataTable, Text, Grid} from 'grommet';

// Page with searchable list of people; linked from search in the nav bar

const ListPage = (props) => { 


	useEffect(() => {
		props.dispatch({type: 'SET_TITLE', payload: {text: 'Find Ancestor', photo_id: ''}});
	}, []);
	
	const [sort, setSort] = React.useState({
		property: "name",
		direction: "desc"
	});

	const columns = 
	[{
		property: 'name',
		header: <Text>Name</Text>,
		sortable: true,
		search: true,
		render: datum => <PersonLink photo={true} id={datum.person_id}/>
	}]

	return(
		<Box margin="large" flex={false} width={{'max': '900px'}} alignSelf='center' align='center'>
			{props.people && <DataTable
			columns={columns}
			data={props.people}
			sortable
			margin='xsmall'
			pad='xxsmall'
			primaryKey='person_id'
			step={props.people.length}/>}
		</Box>
	)
}

const mapStateToProps = state => ({
	people: state.people.allIds.map(id => state.people.byId[id])
});

export default connect(mapStateToProps)(ListPage);