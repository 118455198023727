import React, { Component } from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import { Box, Text, Form, FormField, Button, Heading, TextInput } from 'grommet';

const loading = {
	margin: '1em',
	fontSize: '24px',
};

class TokenReset extends Component {
	constructor() {
		super();
		this.state = {
			user_id: '',
			password: '',
			password2: '',
			updated: false,
			isLoading: true,
			error: false,
			message: ''
		};
	}

	async componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
		const token = this.props.match.params.id;
		try {
			console.log(token);
			const response = await axios.get('api/user/reset', {
				params: {
					token: token,
				},
			});
			// console.log(response);
			if (response.data.message === 'password reset link a-ok') {
				console.log(response.data);
				this.setState({
					user_id: response.data.user_id,
					updated: false,
					isLoading: false,
					error: false,
				});
			}
		} catch (error) {
			console.log(error.response.data);
			this.setState({
				updated: false,
				isLoading: false,
				error: true,
			});
		}
	}

	handleChange = name => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	updatePassword = async (e) => {
		e.preventDefault();
		const { user_id, password } = this.state;
		const token = this.props.match.params.id;
		if (this.state.password && this.state.password2 && this.state.password===this.state.password2) {
	
			axios.put(`/api/user/tokenreset/${user_id}`, {password: password, token: token})
				.then((results) => {
					if (results.data.message === 'password updated') {
						this.setState({
							updated: true,
							error: false,
						});
					} else {
						this.setState({
							updated: false,
							error: true,
						});
					}
				}).catch((error) => {
						console.log('error restting password', error); 
				})		
			
		} else if (this.state.password && this.state.password2 && this.state.password !== this.state.password2) {
			this.setState({message: 'Passwords do not match'})
		} else {
			this.setState({message: 'Please enter a new password'})
		}
	};

	render() {
		const { error, isLoading, updated } = this.state;

		if (error) {
			return (
				<Box fill align="center" justify="center">
						<Heading level={4}>Problem resetting password. Please send another reset link.</Heading>
						<Button alignSelf='center' label='Forgot Password?' onClick={()=>this.props.history.push('/forgot')}/>    
				</Box>
			);
		} else if (isLoading) {
			return (
				<div>
					<div style={loading}>Loading User Data...</div>
				</div>
			);
		} else if(updated) {
			return(
				<Box fill align="center" justify="center"v>
					<p>Your password has been successfully reset. Please log in with your new password.</p>
					<Button alignSelf='center' label='Login' onClick={()=>this.props.history.push('/list')}/>
				</Box>
			);
		} else {
			return (
				<div>
					<Box fill align="center" justify="center">
						<Form onSubmit={this.updatePassword} >

							<Heading>Please Reset Password</Heading>

							<FormField label="New Password">
									<TextInput
										type="password"
										name="password"
										value={this.state.password}
										onChange={this.handleChange('password')}
									/> 
							</FormField>

							<div>
								<FormField label="Confirm Password">
									<TextInput
										type="password"
										name="password2"
										value={this.state.password2}
										onChange={this.handleChange('password2')}
									/>
								</FormField>

								{this.state.message && (
									<Box pad={{ horizontal: 'small' }}>
										<Text color="status-error">{this.state.message}</Text>
									</Box>
								)}
							</div>

							<div>
								<Button
									type="submit"
									name="submit"
									label="Save"
								/>
								
								<Button
									onClick={()=>this.props.history.goBack()}
									name="cancel"
									label="Cancel"
								/>

							</div>

						</Form>
					</Box>

					{updated && (
						<Box fill align="center" justify="center"v>
							<p>Your password has been successfully reset. Please log in with your new password.</p>
							<Button alignSelf='center' label='Login' onClick={()=>this.props.history.push('/list')}/>
						</Box>
					)}
				</div>
			);
		}
	}
}

const mapStateToProps = state => ({
	user: state.user
});
 
export default connect(mapStateToProps)(TokenReset);