import React from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Text,
	DataTable,
	Button
} from "grommet";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import RoutedButton from '../Reused/RoutedButton';
import DBImage from '../Reused/DBImage';
import { Checkmark } from 'grommet-icons';

// Shows all photos in a table with filtering
// props: photos - array of photo objects with data

const ImageTable = (props) => { 

	const [sort, setSort] = React.useState({
		property: "name",
		direction: "desc"
	});
			
	const DATA = props.photos;

	const columns = [
		{
			property: 'photo_id',
			header: <Text>ID</Text>,
			sortable:true,
			primary: true
		},
		{
			property: 'image',
			render: datum => <Box height="xsmall" width="xsmall" border>
					<DBImage id={datum.photo_id}  fit="contain" thumbnail={true} />
				</Box>,  
			sortable:false
		},
		{
			property: 'photo_url',
			header: <Text>url</Text>,
			search: true,
			sortable:true
		},
		{
			property: 'people',
			header: <Text>people</Text>,
			render: datum => datum.people && <ul>{datum.people.map(person => <li key={person.person_id}><Link className='text-link' to={`/person/${person.person_id}`}>{person.name}</Link></li>)}</ul>,
			sortable: false,
			search: false
		},
		{
			property: 'header',
			header: <Text>H</Text>,
			sortable: true,
			render: datum => datum.header && <Checkmark/>
		},
		{
			property: 'thumbnail',
			header: <Text>T</Text>,
			sortable: true,
			render: datum => datum.thumbnail && <Checkmark/>
		},
		{
			property: 'albums',
			header: <Text>albums</Text>,
			render: datum => datum.albums && <ul>{datum.albums.map(album => 
				<li 
					style={{ cursor:"pointer", color:'#63b9d6' }} 
					key={album.album_id} 
					onClick={()=>{props.history.push(`/editAlbum/${album.album_id}`);}}>
				{props.albums[album.album_id].name}</li>)}
				</ul>,
			sortable: false
		},
		{
			property: 'updated_at',
			header: 'updated',
			render: datum =>
				datum.updated_at && new Date(datum.updated_at).toLocaleString('en-US', {dateStyle:'short', timeStyle: 'short'}),
			align: 'end',
			sortable:true
		},
		{
			property: 'location_text',
			header: <Text>location</Text>,
			search: true,
			sortable: true
		},
		{
			property: 'delete',
			render: datum => <Button onClick={()=>props.deletePhoto(datum.photo_id)} label={'delete'}/>,
			sortable: false
		},
		{
			property: 'edit',
			render: datum => <RoutedButton path={`/editPhoto/${parseInt(datum.photo_id)}`} label={'edit'}/>,
			sortable: false
		},
	]
	
	return(
		<Box align="center" fill='horizontal' >
			{props.photos && 
			<Box flex={false}>
				<DataTable
						columns={columns}
						data={DATA}
						sortable
						primaryKey='photo_id'
						fontSize='xxsmall'
						sort={{direction:'desc', property:'updated_at'}}
				/>
			</Box>
			}
		</Box>
	);
}

const mapStateToProps = state => ({
	albums: state.albums.byId
});

export default withRouter(connect(mapStateToProps)(ImageTable));