import React, { Component } from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import { Box, Text, Form, FormField, Button, Heading, TextInput } from 'grommet';

// Component to reset password when logged in

class PasswordReset extends Component {

	state = {
		password: '',
		password2: '',
		message: ''
	}

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}

	handleInputChangeFor = propertyName => (event) => {
		this.setState({
			[propertyName]: event.target.value,
		});
		console.log(this.state);
	}

	handleSubmit = (event) => {
		event.preventDefault();
		console.log(this.state);
		if (this.state.password && this.state.password2 && this.state.password===this.state.password2) {
				console.log('save', this.state.password, this.props.user.user_id);

				axios.put(`/api/user/reset/${this.props.user.user_id}`, {password: this.state.password, user: this.props.user})
				.then((results) => {
						window.alert('Password changed successfully. Please log in again.');
						this.props.dispatch({ type: 'LOGOUT' });
						this.props.dispatch({ type: 'PASSWORD_RESET_DONE' });
						this.props.history.push('/');
					}).catch((error) => {
							console.log('error resetting password', error); 
					})

		} else if (this.state.password && this.state.password2 && this.state.password !== this.state.password2) {
				this.setState({message: 'Passwords do not match'})
		} else {
				this.setState({message: 'Please enter a new password'})
		}
	}

	render() {
		return(
			<Box fill align="center" justify="center">
				<Form onSubmit={this.handleSubmit} >

					<Heading>Please Reset Password</Heading>

					<FormField label="New Password">
						<TextInput
							type="password"
							name="password"
							value={this.state.password}
							onChange={this.handleInputChangeFor('password')}
						/> 
					</FormField>

					<div>
						<FormField label="Confirm Password">
							<TextInput
								type="password"
								name="password2"
								value={this.state.password2}
								onChange={this.handleInputChangeFor('password2')}
							/>
						</FormField>

						{this.state.message && (
							<Box pad={{ horizontal: 'small' }}>
								<Text color="status-error">{this.state.message}</Text>
							</Box>
						)}
					</div>

					<div>
						<Button
							type="submit"
							name="submit"
							label="Save"
						/>
						<Button
							onClick={()=>this.props.history.goBack()}
							name="cancel"
							label="Cancel"
						/>
					</div>

				</Form>
			</Box>
		)
	}
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(mapStateToProps)(PasswordReset);