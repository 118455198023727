import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getSubjects(action) {
	try {
		
		const response = yield axios.get('/api/articles/subjects');
		const subjects = {
			byId: convertArrayToObject(response.data, 'subject_id'),
			allIds: response.data.map((subject) => subject.subject_id)
		};
		
		yield put({type: 'SET_SUBJECTS', payload: subjects});

	} catch (error) {
		console.log('subject get failed', error);
	}
}


function* subjectSaga() {
	yield takeLatest('FETCH_SUBJECTS', getSubjects);
}

export default subjectSaga;