import React, {Component} from 'react';
import { Box, Heading } from "grommet";
import DBImage from '../Reused/DBImage';
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";

function Title(props) {
    const location = useLocation();
    const title = props.title; // This is the title from the redux state

    if (title.photo_id) {
        return (
            <Box direction="row-responsive" align="center" width="100%" background="#e0dcdc"  justify="center"  height="xsmall">
                <Box width="60%" alignContent="center" height="xsmall" justify="center">
                    <Heading alignSelf="center" level="3" fontWeight="bold" style={{ maxWidth: '100%' }} color="brand">{title.text}</Heading>
                </Box>
                <Box width="40%" height="xsmall">
                    <DBImage fit="cover" id={title.photo_id} />
                </Box>
            </Box>
        );
    } else if (title.text){
        return (
            <Box direction="row-responsive" alignContent="center" align="center" width="100%" background="brand" justify="center"  height="xsmall">
                <Heading level="3" style={{ maxWidth: '100%' }}  alignSelf="center" textAlign="center" color="white" >{title.text}</Heading>
            </Box>
        );
    } else {
        return null;
    }
}

const mapStateToProps = state => ({
    title: state.title
});

export default connect(mapStateToProps)(Title);
