import React, { Component } from 'react';
import {
	Paragraph,
	Heading,
	Box,
	Grid,
	Text,
	Image
} from "grommet";
import { connect } from 'react-redux';

// The page that displays via the About link in the nav

class InfoPage extends Component {

	componentDidMount() {
		this.props.dispatch({type: 'SET_TITLE', payload: {text: `About`, photo_id: ''}});
	}

	render() {
		return(
			<Grid
			areas={[
				{ name: "main", start: [0, 0], end: [0, 0] },
				{ name: "side", start: [1, 0], end: [1, 0] }
			]}
			columns={["60%", "40%"]}
			rows={["auto", "flex"]}
			>

				<Box gridArea="main" pad="medium"  align="center" margin={{left: 'xlarge'}}>
					<Paragraph fill >
					This site will eventually contain all of the old photos I have and a selection of photos of my generation.  It will also contain everything I’ve unearthed about our ancestors lives, starting with the parents of Ted Cajacob and Clare English.  We will continue to improve the site as well.  Check the What’s New section to find out about new or updated content and new functionality.
					</Paragraph>

					<Paragraph fill>
					I’ve used my own best judgement when I run into conflicting accounts to choose the most likely, and have made certain assumptions in areas where research results are ambiguous.  I will try to make note of the questionable items.  If you see anything you feel is wrong, or feel something is missing, let me know.
					</Paragraph>

					<Paragraph fill>
					Version 1.3.62
					</Paragraph>
				</Box>

				<Box gridArea="side" align='center' justifyContent="center" pad='medium' >
					<Text alignSelf='center'>cajacob.family</Text>
					<Box width={{'max':'300px'}} pad='small'>
						<Image fill='false' src={process.env.PUBLIC_URL + '/alex.jpg'}/>
						<Text alignSelf='center'>built by Alex</Text>
					</Box>
					<Box width={{'max':'300px'}} pad='small'>
						<Image fill='false' height={{'max':'300px'}} src={process.env.PUBLIC_URL + '/jackie.jpg'}/>
						<Text alignSelf='center'>design by Jackie</Text>
					</Box>
					<Box width={{'max':'300px'}} pad='small'>
						<Image fill='false' height={{'max':'300px'}} src={process.env.PUBLIC_URL + '/carol.jpg'}/>
						<Text alignSelf='center'>content by Carol</Text>
					</Box>
				</Box>

			</Grid>
		)
	}
}


export default connect()(InfoPage);