import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

function* sendReset(action) {
	try {

		console.log('he');
		// clear any existing error on the login page
		yield put({ type: 'CLEAR_RESET_ERROR' });

		const config = {
		headers: { 'Content-Type': 'application/json' },
		withCredentials: true,
		};

		// send the action.payload as the body
		// the config includes credentials which
		// allow the server session to recognize the user
		yield axios.post('/api/user/forgotpassword', action.payload, config);
		yield put({ type: 'RESET_SUCCEEDED' });

	} catch (error) {
		console.log('Error with user login:', error);
		if (error.response.status === 403) {
			console.log('code');
			// The 401 is the error status sent from passport
			// if user isn't in the database or
			// if the username and password don't match in the database
			yield put({ type: 'RESET_FAILED' });
		} else {
			console.log('no code');
			// Got an error that wasn't a 401
			// Could be anything, but most common cause is the server is not started
			yield put({ type: 'RESET_FAILED_NO_CODE' });
		}
	}
}

function* resetSaga() {
	yield takeLatest('SEND_RESET', sendReset);
}

export default resetSaga;
