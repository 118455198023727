import React, { useEffect } from 'react';
import {Button}  from 'grommet';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css';
import { connect } from 'react-redux';

// Uploads photos

const axios = require("axios");

const MyUploader = ({match, history, dispatch}) => {

	useEffect(() => {
		dispatch({ type: 'CLEAR_TITLE' });
	}, []);

	const {albumId} = match.params;

	const cancel = () => {
		history.push(`/editAlbum/${albumId}`);
	}

	// specify upload params and url for your files
	const getUploadParams = ({ file, meta }) => {
		const body = new FormData()
		body.append('photos', file)
		return { url: 'api/photo', body}
	}
	
	// called every time a file's `status` changes
	const handleChangeStatus = ({ meta, file, xhr }, status) => { 
		if (status === 'done'){
			let response = JSON.parse(xhr.response);
			console.log(response.data.photo_id);
			meta.photo_id = response.data.photo_id;
		}  
	}

	const endUpload = (albumId) => {
		dispatch({type: 'FETCH_PHOTOS' });
		dispatch({type: 'FETCH_ALBUMS'});
		dispatch({type: 'FETCH_PEOPLE'});
		if (albumId) {
			history.push(`/albums`);
		} else {
			history.push(`/photos`);
		}
	}
	
	// receives array of files that are done uploading when submit button is clicked
	const handleSubmit = (files, allFiles) => {
		allFiles.forEach(f => f.remove());
		if (albumId) {
			let data = files.map(f => ({photo_id: f.meta.photo_id, name: f.meta.name, album: parseInt(albumId)}));
			data.sort((a, b)=> {
				if (a.name < b.name) return -1
				return a.name > b.name ? 1 : 0
			})
			data = data.map((f, i) => ({photo_id: parseInt(f.photo_id), album_id: parseInt(albumId), sort_order: i+1}));
			let countdown = data.length;
			data.forEach(f => {
				axios.post('/api/album/addPhotoOrdered', f)
				.then((results) => {
					countdown--;
					if (countdown===0) {
						console.log('done', countdown);
						endUpload(albumId);
					}
				}).catch((error) => {
					console.log('error adding photo to album', error); 
				})
			});

		} else {
			endUpload();
		}
	}

	return (
		<>
			{albumId && <Button label="Cancel" onClick={()=>{cancel()}}/>}
			<Dropzone
				getUploadParams={getUploadParams}
				onChangeStatus={handleChangeStatus}
				onSubmit={handleSubmit}
				accept="image/*"
			/>
		</>
	)
}

export default connect()(MyUploader);