import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Box, Text} from 'grommet';
import SidebarPhoto from './SidebarPhoto';
import {connect} from 'react-redux';
import { renderDateParen } from '../Reused/Utils';

// props: id - person id
// 		inText, self, parent, photoSpace, tree, link, photo - optional boolean values for formatting
// 		parentText - optional string used to as header on family sidebar ('father' or 'mother')

class PersonLink extends Component {
    render() {
		const {
			fragment,
            inText,
            self,
            parent,
            photoSpace,
			photo,
            tree,
            parentText,
            person,
			link
		} = this.props;

		if (fragment) {
			return(<>{person.name}</>)
		} else if(inText===true) {
		  	return(<Link className='text-link' to={`/person/${person.person_id}`}>{person.name}</Link>)
		} else if(self) {
		  	return(<Text color="accent-2">{person.name} {renderDateParen(person)}</Text>)
		} else if(parent && person.thumbnail_photo) {
			return(<Box direction="column">
				<SidebarPhoto photo_id={person.thumbnail_photo} />
				<Text>{parentText}</Text>
				<Link className='text-link' to={`/person/${person.person_id}`}>{person.name} <br/> {renderDateParen(person)}</Link>
			</Box>)
		} else if (parent && photoSpace) {
			return(<Box direction="column">
				<Box height="xsmall" width="xsmall" direction="column" margin={{right: "small"}}/>
				<Text>{parentText}</Text>
				<Link className='text-link' to={`/person/${person.person_id}`}>{person.name} <br/> {renderDateParen(person)}</Link>
			</Box>)
		} else if (parent) {
			<Box direction="column">
				<Text>{parentText}</Text>
				<Link className='text-link' to={`/person/${person.person_id}`}>{person.name} <br/> {renderDateParen(person)}</Link>
			</Box>
		} else if(tree) {
		  	return(<Box><Link className='tree-link' color='#444444' props={this.props} to={`/person/${person.person_id}`}>{person.name} {renderDateParen(person)}</Link></Box>)
		}
		if (link===false) {
		  	return(<Box><Text>{person.name} {renderDateParen(person)}</Text></Box>)
		} else if (photo && person.thumbnail_photo) {
		  return(<Box direction="row">
				<SidebarPhoto photo_id={person.thumbnail_photo} />
			  	<Link className='text-link' to={`/person/${person.person_id}`}>{person.name} <br/> {renderDateParen(person)}</Link>
			</Box>)
		} else {
			return(<Box><Link className='text-link' props={this.props} to={`/person/${person.person_id}`}>{person.name} {renderDateParen(person)}</Link></Box>)
		}
	}  
}

const mapStateToProps = (state, ownProps) => ({
	person: state.people.byId[ownProps.id]
})

export default connect(mapStateToProps)(PersonLink);