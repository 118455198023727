import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
	Box,
	Text,
	Heading,
	Button,
	Grid
} from "grommet";
import DBImage from '../Reused/DBImage';
import EditCaption from './EditCaption';
import PersonLink from '../PersonPage/PersonLink';

// Allows admin user to see and edit photo data
// Props: photoId - optional, id may be in url or passed down as a prop

const emptyState = {
		photo: {},
		addPerson: false,
		selectedPerson: ''
}

const customStyles = {
		menu: (provided, state) => ({
			...provided,
			width: '200px',
		})}


class EditPhoto extends Component { 

	state = emptyState;

	componentWillMount () {
		this.getPhotoInfo();
	}
	componentDidUpdate(prevProps) {
		if(this.props.photoId !== prevProps.photoId) {
			this.setState(this.emptyState);
			this.getPhotoInfo();
		}
	} 

	getPhotoInfo = () => {
		let photo_id;
		if (this.props.match) {
			photo_id = this.props.match.params.photoId;
		} else {
			photo_id = this.props.photoId;
		}
		console.log(photo_id);
		axios.get(`/api/photo/${photo_id}`)
			.then((results) => {
				this.setState({photo: results.data[0]});
			}).catch((error) => {
				console.log('error getting production from server', error);
			})
	}

	componentDidMount() {
		this.setState(this.emptyState);
		this.forceUpdate();  
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}


	savePerson = () => {
		let photo_id;
			if (this.props.match) {
				photo_id = this.props.match.params.photoId;
			} else {
				photo_id = this.props.photoId;
			}

		let data = {person_id: this.state.selectedPerson, photo_id: parseInt(photo_id)}
		axios.post(`/api/photo/addPerson`, data)
		.then((results) => {
			this.setState({...this.state, addPerson: false, selectedOption: ''});
			this.props.dispatch({type: 'FETCH_PHOTOS' });
			this.props.dispatch({type: 'FETCH_PEOPLE'});
			this.getPhotoInfo();
		}).catch((error) => {
			console.log('error adding person', error);
		})
	}

	handleChange = (selectedOption) => {
		this.setState({selectedPerson: selectedOption.value});
	}

	handleInputChangeFor = propertyName => (event) => {
		this.setState({
			[propertyName]: event.target.value,
		});
	}

	deletePerson = (person_id) => {
		let photo_id;
		if (this.props.match) {
			photo_id = this.props.match.params.photoId;
		} else {
			photo_id = this.props.photoId;
		}
		let data = {person_id: person_id, photo_id: parseInt(photo_id)}
		axios.post(`/api/photo/removePerson`, data)
		.then((results) => {
			this.props.dispatch({type: 'FETCH_PHOTOS' });
			this.props.dispatch({type: 'FETCH_PEOPLE'});
			this.getPhotoInfo();
		}).catch((error) => {
			console.log('error adding person', error);
		})  
	}

	render() {

		const { selectedOption } = this.state.selectedPerson;

		return(
			<Grid 
				areas={[
					{ name: 'left', start: [0, 0], end: [0, 1] },
					{ name: 'right', start: [1, 0], end: [1, 1] },
				]}
				columns={["33%", "66%"]}
				rows={["auto", "flex"]}
			>
			
				<Box gridArea='left' pad="large" flex="grow">
					<DBImage id={this.state.photo.photo_id}  fit="contain" thumbnail={true} />
					<Text>{this.state.photo.photo_url}</Text>
				</Box>

				<Box gridArea='right' pad="large">
					<Heading level='4'>People:</Heading>
					{this.state.photo.people && this.state.photo.people.map(person =>
						<Box direction="row" key={person.person_id}>
							<Text>{person.person_id} - {person.name}</Text>
							<Button onClick={() => this.deletePerson(person.person_id)} label='Remove'/>
						</Box>
					)}
					{!this.state.addPerson && <Button label="Add" onClick={() => this.setState(prevState => ({addPerson: !prevState.addPerson}))}/>}
					{this.state.addPerson && <>
						<Select
								styles={customStyles}
								value={selectedOption}
								onChange={this.handleChange}
								options={this.props.people.map(person=>({label: person.person_id+' - '+person.name, value: person.person_id}))}
								isSearchable={true}
						/>
						<Button label="save" onClick = {()=>this.savePerson()}/>     
						<Button label="cancel" onClick = {() =>  this.setState(prevState => ({addPerson: !prevState.addPerson}))}/> 
					</>}

					<EditCaption photo={this.state.photo}  getPhotoInfo={this.getPhotoInfo}/>

					{this.state.photo.headers && <>
						<Heading level='4'>Header Photo For:</Heading>
						<ul>
							{this.state.photo.headers.map(h=>
								<li><PersonLink id={h.person_id}/></li>
							)}
						</ul>
					</>}

					{this.state.photo.thumbnails && <>
						<Heading level='4'>Thumbnail Photo For:</Heading>
						<ul>
							{this.state.photo.thumbnails.map(t=>
								<li><PersonLink id={t.person_id}/></li>
							)}
						</ul>
					</>}

					{this.state.photo.albums && <>
						<Heading level='4'>In Albums:</Heading>
						<ul>
							{this.state.photo.albums.map(album=>
								<li 
								style={{ cursor:"pointer", color:'#63b9d6' }} 
								key={album.album_id} 
								onClick={()=>{this.props.history.push(`/editAlbum/${album.album_id}`);}}>
									{album.name}
								</li>
							)}
						</ul>
					</>}

					{this.state.photo.person_albums && <>
						<Heading level='4'>In Person Albums:</Heading>
						<ul>
							{this.state.photo.person_albums.map(t=>
								<li><PersonLink id={t.person_id}/></li>
							)}
						</ul>
					</>}

					{!this.props.photoId && <Button  label='back' onClick={()=>this.props.history.goBack()}/>}    
				</Box>

			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	people: state.people.allIds.map(id => state.people.byId[id]),
});

export default connect(mapStateToProps)(EditPhoto);