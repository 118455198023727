import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {sortableHandle} from 'react-sortable-hoc';
import { Box, Button } from "grommet";
import { Close } from 'grommet-icons';
import DBImage from '../Reused/DBImage';

// photo with ability to delete from album
//props: photo - photo object
//		album_id

const DragHandle = sortableHandle((props) => <DBImage id={props.photo.photo_id}  fit="cover" thumbnail={true} />);

class PhotoWithDelete extends Component { 

	constructor(){
		super();
		this.targetRef = React.createRef()
	}  

	deleteFromAlbum = () => {
		if ( window.confirm('Remove from album?') ) {
			let data = {photo_id: this.props.photo.photo_id, album_id: this.props.album_id}
			axios.post('/api/album/removePhoto', data)
				.then((results) => {
					console.log(results);
					this.props.dispatch({type: 'FETCH_ALBUMS'});
				}).catch((error) => {
						console.log('error removing photo to server', error); 
				})
		}
	}


	render() {
		return(
			<Box height="200px" width="200px" pad="small" ref={this.targetRef}>
				<Button plain={false} icon={<Close />} alignSelf="center" onClick={() => this.deleteFromAlbum()} />
				<DragHandle photo={this.props.photo}/>
			</Box>  
		);
	}
}

export default connect()(PhotoWithDelete);