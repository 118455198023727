const photoReducer = (state = {}, action) => {
	switch (action.type) {
		case 'SET_PHOTOS':
			return action.payload;
		default:
			return state;
	}
};

// photo data will be on the redux state at:
// state.photos
export default photoReducer;