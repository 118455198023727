import React, { Component } from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import { Box, Button, Table, TableHeader, TableRow, TableCell, TableBody, Heading } from 'grommet';
import { Checkmark } from 'grommet-icons';
import AddUser from './AddUser';
import EditUser from './EditUser';

class UserPage extends Component {

	state = {
		users: [],
		adding: false,
		edit: false,
		selected: {}
	}

	toggleAdding = () => {
		this.setState(prevState => ({
			...this.state,
			adding: !prevState.adding,
		}));
	}

	toggleEdit = () => {
		this.setState(prevState => ({
			...this.state,
			edit: !prevState.edit,
		}));
	}

	getList = () => {
		console.log('on it');
		axios({
			method: 'GET',
			url: '/api/user/list'
		})
		.then( (response) => {
			console.log('list was', response.data ); 
			const users = response.data;
			this.setState({...this.state, users: users});
		})
		.catch( (error) => {
			console.log('Error', error);
		})
	}

	componentDidMount() {
		this.getList();
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}

	editUser (user) {
		console.log(user);
		this.setState({
			...this.state,
			selected: user,
			edit: true
		});
	}


	render() {
		return(

			<Box align="center" justify="center">
				<Heading level='3'>Users</Heading>
				{!this.state.adding && !this.state.edit && <Box align="center" pad="medium">
					<Table>

							<TableHeader>
								<TableRow>
									<TableCell>Edit</TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Family</TableCell>
									<TableCell>Generations</TableCell>
									<TableCell>Active</TableCell>
									<TableCell>Admin</TableCell>
									<TableCell>Last Login</TableCell>
								</TableRow>
							</TableHeader>

							<TableBody align='center'>
								{this.state.users.map(user => (
								<TableRow key={user.user_id}>
									<TableCell><Button onClick={() => {this.editUser(user)}}>Edit</Button></TableCell>
									<TableCell>{user.name}</TableCell>
									<TableCell>{user.email}</TableCell>
									<TableCell>{user.family_id}</TableCell>
									<TableCell>{user.generations}</TableCell>
									<TableCell>{user.active && <Checkmark/>}</TableCell>
									<TableCell>{user.admin && <Checkmark/>}</TableCell>
									<TableCell>{user.last_login && new Date(user.last_login).toLocaleString('en-US', {dateStyle:'short'})}</TableCell>
								</TableRow>))}
							</TableBody>

					</Table>

					<Button variant="outlined"  label="Add User" onClick={()=>this.toggleAdding()}/>

				</Box>}

				{this.state.adding && <AddUser toggleAdding={this.toggleAdding} getList={this.getList}/>}
				{this.state.edit && <EditUser getList={this.getList} toggleEdit={this.toggleEdit} user={this.state.selected}/>}
				
			</Box>
		)
	}
}

export default connect()(UserPage);