import React, { Component } from 'react';
import PersonLink from './PersonLink';
import { Box, Text } from "grommet";
import { connect } from 'react-redux';

class Parents extends Component {

    render() {

        if (this.props.father && this.props.mother) {
            if (this.props.father.thumbnail_photo || this.props.mother.thumbnail_photo) {
                return(
                    <Box direction='row-responsive' alignContent='between' fill='horizontal' width={{'max':'500px'}}>
                        {this.props.family.father && <Box basis='1/2' paddingRight='small' margin={{right: "medium"}}>
                            <PersonLink parent={true} parentText={'Father'} photo={true} photoSpace={true} id={this.props.family.father}/>
                        </Box>}
    
                        {this.props.family.mother && <Box basis='1/2'>
                            <PersonLink parent={true} parentText={'Mother'} photo={true} photoSpace={true} id={this.props.family.mother}/>
                        </Box>}
                    </Box>
                ) 
            } else {
                return(
                    <Box direction='row-responsive' alignContent='between' fill='horizontal' width={{'max':'500px'}}>
                        {this.props.family.father && <Box basis='1/2' paddingRight='small' margin={{right: "medium"}}>
                            <PersonLink parent={true} parentText={'Father'} photo={true}  id={this.props.family.father}/>
                        </Box>}
    
                        {this.props.family.mother && <Box basis='1/2'>
                            <PersonLink parent={true} parentText={'Mother'} photo={true} id={this.props.family.mother}/>
                        </Box>}
                    </Box>
                    
                )
            }
            } else if(this.props.father || this.props.mother) {
            return (
                <Box>
                    {this.props.father && this.props.father.thumbnail_photo && 
                        <PersonLink parent={true} parentText={'Father'} photo={true}  id={this.props.family.father}/>
                    }

                    {this.props.family.mother && this.props.mother.thumbnail_photo &&
                        <PersonLink parent={true} parentText={'Mother'} photo={true} id={this.props.family.mother}/>
                    }

                    {this.props.family.mother && !this.props.mother.thumbnail_photo &&
                        <PersonLink parent={true} parentText={'Mother'} photo={false} id={this.props.family.mother}/>
                    }

                    {this.props.family.father && !this.props.father.thumbnail_photo &&
                        <PersonLink parent={true} parentText={'Father'} photo={false} id={this.props.family.father}/>
                    }                    
                </Box>
        )} 
    } 
}

const mapStateToProps = (state, ownProps) => {
    return {
        mother: state.people.byId[ownProps.family.mother],
        father: state.people.byId[ownProps.family.father]
    }
}
  
export default connect(mapStateToProps)(Parents);