import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getFamilies(action) {
	try {
		const response = yield axios.get('/api/family/');
		const data = response.data.map((f)=>f.family)
		const families = {
			byId: convertArrayToObject(data, 'family_id'),
			allIds: data.map((family) => family.family_id)
		};
		yield put({type: 'SET_FAMILIES', payload: families});

	} catch (error) {
		console.log('families get failed', error);
	}
}


function* familySaga() {
	yield takeLatest('FETCH_FAMILIES', getFamilies);
}

export default familySaga;