import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
	Box,
	Button,
	Layer,
	Card,
	CardHeader,
	CardBody,
	CardFooter
} from "grommet";
import { Edit } from 'grommet-icons';
import SelectPhotoforHT from './SelectPhotoforHT';
import DBImage from '../Reused/DBImage';

//props: header - photo id
//  	person_id
//		thumbnail - photo id

class SelectHeaderThumb extends Component { 

	emptyState = {
		edit: false,
		type: ''
	}
		
	state = { 
		edit: false,
		type: ''
	}
		
	componentDidMount() {
		this.setState(this.emptyState);  
	}
		
	edit(type) {
			this.setState(prevState => ({
				edit: !prevState.edit,
				type: type
		}));
	}

	handleInput = (event) => {
		this.setState({
				...this.state,
				category: event.value 
		});
	}

	handleClick = (id) => {
		let type = this.state.type;
		let setCat = {id:id, type: type, person: this.props.person_id}
		if (type==='thumbnail') {
			axios.post('/api/album/thumbnail', setCat)
				.then((results) => {
						this.props.dispatch({type: 'FETCH_PHOTOS' });
						this.props.dispatch({type: 'FETCH_PEOPLE'});
						this.setState({...this.state,
								edit: false});
				}).catch((error) => {
						console.log('error posting thumbnail to server', error); 
				})
		} else {
			axios.post('/api/album/header', setCat)
				.then((results) => {
						this.props.dispatch({type: 'FETCH_PHOTOS' });
						this.props.dispatch({type: 'FETCH_PEOPLE'});
						this.setState({...this.state,
								edit: false});
				}).catch((error) => {
						console.log('error posting header to server', error); 
				})
	}
}


	render() {
		return(
			<Box direction='row-responsive' pad='small'>

				<Card  height="small" width="small" background="light-1" margin='small' justify='center' align='center'>
					<CardHeader pad="small">Header</CardHeader>
					<CardFooter pad={{horizontal: "small"}}> 
						<Button icon={<Edit color="plain" />} hoverIndicator onClick={()=>this.edit('header')} />  
					</CardFooter>
				</Card>

				<Card  height='small' background="light-1" margin='small' justify='center' align='center'>
					<CardHeader pad="small">Thumbnail</CardHeader>
					<CardBody  height="xxsmall" width="xxsmall" >
						{this.props.thumbnail && <DBImage id={this.props.thumbnail.photo_id} fit="contain" />}
					</CardBody>
					<CardFooter pad={{horizontal: "small"}}> 
						<Button icon={<Edit color="plain" />} hoverIndicator onClick={()=>this.edit('thumbnail')} />   
					</CardFooter>
				</Card>

				{this.state.edit && 
					<Layer full={true} animation={false} margin='small' onEsc={()=>this.edit('')}>
						<SelectPhotoforHT toggleChange={()=>this.edit()} handleClick={this.handleClick}/>
					</Layer>
				}

			</Box>
		);
	}
}

export default connect()(SelectHeaderThumb);