import React, { Component } from 'react';
import {
	Diagram,
	Stack,
	Box,
	Grid,
	Heading
  } from "grommet";
import { connect } from 'react-redux';
import RoutedButton from '../Reused/RoutedButton';
import Dot from './Dot';
import GenerationLabel from './GenerationLabel';
import axios from 'axios';
import { Blank } from 'grommet-icons';
import { ReactComponent as ArrowUp} from '../../Assets/Arrow-Up.svg';
import NewTreeButton from './NewTreeButton';
import SVGRectangle from '../HowRelated/SVGRectangle';


const UpArrow = props => (
	<Blank {...props}>
		<ArrowUp />
	</Blank>
);

class Home extends Component {

	state = {
		
	}
	
	componentDidMount () {
		this.getHomeTree();
	}

  	getHomeTree = () => {
		const family_id = this.props.user.family_id;
		//console.log(family_id);

		axios.get(`/api/family/newTree/${family_id}`)
		.then((results) => {
			//console.log(results.data);
			this.setState({newTree: results.data}); 
			this.props.dispatch({type: 'SET_NEW_TREE', payload: results.data });
			this.props.dispatch({type: 'SET_TITLE', payload: {text: `Cajacob Family`, photo_id: ''}});
		}).catch((error) => {
			console.log('error creating newTree', error);
		})
		
		axios.get(`/api/family/homeTree/${family_id}`)
		.then((results) => {
			let r = results.data.tree;
			if (r.pppp || r.pppm || r.ppmp ||r.ppmm || r.pmpp || r.pmpm ||r.pmmp ||r.pmmm ) {
				r.paternal = true;
			} else {
				r.paternal = false;
			}
			if (r.mmmm || r.mmmp || r.mmpm ||r.mmpp || r.mpmm || r.mpmp ||r.mppm ||r.mppp ) {
				r.maternal = true;
			} else {
				r.maternal = false;
			} 
			//console.log(r, 'input');

			this.setState({tree: r});
			//console.log(this.state);
		}).catch((error) => {
			console.log('error getting home tree', error);
		})
  	}

	constructor(props) {
		super(props);
		//console.log('here', props);
	}
		


  render() {
	if (this.state.tree) {
		return(
			<>
	  
			  
			  <Box gap="small" align="center" full fill={true} pad='medium'>
					
				<Stack  fill={true} full align="center"  >
	  
				  <Diagram
					  connections={[
					  {
						  fromTarget: 'x',
						  toTarget: '1',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear'
					  },
					  {
						  fromTarget: 'p',
						  toTarget: '1',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'm',
						  toTarget: '1',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'p',
						  toTarget: '2',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'm',
						  toTarget: '3',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'pp',
						  toTarget: '2',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'pm',
						  toTarget: '2',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'mm',
						  toTarget: '3',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'mp',
						  toTarget: '3',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'pp',
						  toTarget: '4',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'pm',
						  toTarget: '5',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'mp',
						  toTarget: '6',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'mm',
						  toTarget: '7',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'ppp',
						  toTarget: '4',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'ppm',
						  toTarget: '4',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'pmp',
						  toTarget: '5',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'pmm',
						  toTarget: '5',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'mpp',
						  toTarget: '6',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'mpm',
						  toTarget: '6',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'mmm',
						  toTarget: '7',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'mmp',
						  toTarget: '7',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: 'x',
						  toTarget: 'y',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  },
					  {
						  fromTarget: '1',
						  toTarget: 'y',
						  thickness: 'xsmall',
						  color: 'accent-2',
						  type: 'rectilinear',
					  }
	  
	  
					  ]}
				  />
						
				  <Grid
					  columns={{count: 15, size: 'xxsmall'}}   
					  justifyItems={'center'}
					  justify={'center'}
					  align={'center'}
					  fill={true}
				  >
	  
					{(this.state.tree.paternal || this.state.tree.maternal) && <>
					  <Box/>
						<Box/>
						<Box/>
						{this.state.tree.paternal && <RoutedButton  focusIndicator={false} alignSelf='center' align='center' icon={<UpArrow />} path={`/tree/${this.state.tree.p}/b`}/>}
						{!this.state.tree.paternal && <Box/>}
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						{this.state.tree.maternal &&<RoutedButton focusIndicator={false} alignSelf='center' align='center' icon={<UpArrow />} path={`/tree/${this.state.tree.m}/b`}/>}
						{!this.state.tree.maternal && <Box/>}
						<Box/>
						<Box/>
						<Box/>
					</>
					}
					
					{( this.state.tree.ppp || this.state.tree.ppm || this.state.tree.pmp || this.state.tree.pmm || this.state.tree.mpp || this.state.tree.mpm || this.state.tree.mmp || this.state.tree.mmm )&&
					<>
						<SVGRectangle id="ppp" person_id={this.state.tree.ppp} />
						<Dot id="4" l={this.state.tree.ppp} r={this.state.tree.ppm}/>
						<SVGRectangle id="ppm" person_id={this.state.tree.ppm} />
						<Box/>
						<SVGRectangle id="pmp" person_id={this.state.tree.pmp}/>
						<Dot id="5"  l={this.state.tree.pmp} r={this.state.tree.pmm}/>
						<SVGRectangle id="pmm"  person_id={this.state.tree.pmm}/>
						<Box/>
						<SVGRectangle id="mpp" person_id={this.state.tree.mpp} />
						<Dot id="6"  l={this.state.tree.mpp} r={this.state.tree.mpm}/>
						<SVGRectangle id="mpm" person_id={this.state.tree.mpm} />
						<Box/>
						<SVGRectangle id="mmp" person_id={this.state.tree.mmp} />
						<Dot id="7"  l={this.state.tree.mmp} r={this.state.tree.mmm}/>
						<SVGRectangle id="mmm" person_id={this.state.tree.mmm}/>
					</>}
	  
					{( this.state.tree.mm || this.state.tree.pp || this.state.tree.pm || this.state.tree.mp )&&<>
						<Box/>
						<SVGRectangle id="pp" person_id={this.state.tree.pp}/>
						<Box/>
						<Dot id="2" l={this.state.tree.pp} r={this.state.tree.pm}/>
						<Box/>
						<SVGRectangle id="pm" person_id={this.state.tree.pm}/>
						<Box/>
						<Box/>
						<Box/>
						<SVGRectangle id="mp" person_id={this.state.tree.mp}/>
						<Box/>
						<Dot id="3" l={this.state.tree.mp} r={this.state.tree.mm}/>
						<Box/>
						<SVGRectangle id="mm" person_id={this.state.tree.mm}/>
						<Box/>
					</>}
	  
					{( this.state.tree.m || this.state.tree.p )&&<>
						<Box/>
						<Box/>
						<Box/>
						<SVGRectangle id="p" person_id={this.state.tree.p}/>
						<Box/>
						<Box/>
						<Box/>
						<Dot id="1" l={this.state.tree.m} r={this.state.tree.p}/>
						<Box/>
						<Box/>
						<Box/>
						<SVGRectangle id="m" person_id={this.state.tree.m}/>
						<Box/>
						<Box/>
						<Box/>
					</>}
	  
					<>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<GenerationLabel id="x" generations={this.props.generations-1}/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
					</>
	  
					<>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<SVGRectangle person_id="y" id='y' />
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
						<Box/>
					</>
	  
				  
				  </Grid>
	  
				</Stack>
			
	  
			  </Box>
	  
	  
			  <Box width="100%" height='small'>
				  {this.state.newTree && <NewTreeButton data={this.state.newTree}/>}
			  </Box>
			</>
		  )
		
	} else {
		return null;
	}

  }
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		generations: state.user.generations,
		newTree: state.newTree
	}
}

export default connect(mapStateToProps)(Home);