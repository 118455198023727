import axios from 'axios';
import { put, takeLatest, call } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* fetchPeople() {
	try {
		const response = yield axios.get('/api/person/all');
		const data = response.data.map((p)=>p.person)
		const people = {
			byId: convertArrayToObject(data, 'person_id'),
			allIds: data.map((person) => person.person_id)
		};
		yield put({ type: 'SET_PEOPLE', payload: people });
		
	} catch (error) {
		console.log('People get request failed', error);
	}
}

function* updateAlbumOrder(action) {
	let from=action.payload.oldIndex + 1;
	let to=action.payload.newIndex + 1;
	let person=action.payload.person_id;
	//console.log('in updateAlbumOrder,', from, to, person);
	
	try {
		//axios call to update album ordering
		console.log('in try of update');
		yield call(axios.put, '/api/person/AlbumOrder1', {params: {old: from, new: to, person: person}});
		yield call(axios.put, '/api/person/AlbumOrder2', {params: {old: from, new: to, person: person}});
		yield call(axios.put, '/api/person/AlbumOrder3', {params: {old: from, new: to, person: person}});
		yield put( { type: 'FETCH_PEOPLE'} );
	} catch (error) {
		console.log('error with update photo order');
	}
}



function* peopleSaga() {
	yield takeLatest('FETCH_PEOPLE', fetchPeople);
	yield takeLatest('UPDATE_PERSON_ALBUM_ORDER', updateAlbumOrder)
}

export default peopleSaga;