import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
	Box
} from "grommet";
import MyUploader from './MyUploader';
import ImageTable from './ImageTable';

// Displays photos and allows admin to upload

class Images extends Component { 

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}

	deletePhoto = (photo_id) => {
		if ( window.confirm('Delete this photo?') ) {
			axios.delete(`/api/photo/${photo_id}`)
			.then((results) => {
				console.log('deleted');
				this.props.dispatch({type: 'FETCH_PHOTOS'});
			}).catch((error) => {
				console.log('error deleting photo', error);
			})
		}
	}

	render() {
		return(
			<Box justify="center" pad={{ horizontal: "medium", vertical: "small" }} margin="large" flex={true} align="center">
				<MyUploader match={{params: 0}} inset={true}/>
				{this.props.photos && <ImageTable photos={this.props.photos} deletePhoto={this.deletePhoto}/>}
			</Box>
		);
	}
}

const mapStateToProps = state => ({
	photos: state.photos.allIds.map(id => state.photos.byId[id])
});

export default connect(mapStateToProps)(Images);