import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Article } from 'grommet-icons';
import { Box, Grid, Heading,Button } from "grommet";
import PersonLink from '../PersonPage/PersonLink';
import NameHeader from '../PersonPage/NameHeader';


class LocationPage extends Component {

	componentDidMount() {
		const { location } = this.props;
		this.props.dispatch({type: 'SET_TITLE', payload: {text: location.name, photo_id: location.header_photo}});
	}


	componentDidUpdate(prevProps) { 
		const { location } = this.props;
		if (location.location_id !== prevProps.location.location_id) {
			this.props.dispatch({type: 'SET_TITLE', payload: {text: location.name, photo_id: location.header_photo}});
		}
	}

	render() {
		const { location } = this.props;
		return(
			<Box fill overflow="auto">
			<Grid
				areas={[
					{ name: "main", start: [0, 0], end: [0, 0] },
                    { name: "side", start: [1, 0], end: [1, 0] }
				]}
				columns={["60%", "40%"]}
				rows={["auto", "flex"]}
			>

					
				<Box gridArea="main" pad="large"  align="center">
					<div dangerouslySetInnerHTML={{ __html: location.main_text }} />		 
				</Box>
			
				<Box gridArea="side" pad="large"  align="center">
				<div dangerouslySetInnerHTML={{ __html: location.side_text }} />
				</Box>

			</Grid>
			</Box>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		location: state.locations.byId[ownProps.match.params.id]
	}
}

export default connect(mapStateToProps)(LocationPage);