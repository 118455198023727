import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Grid, Heading,Button } from "grommet";
import NameHeader from '../PersonPage/NameHeader';


class RecipePage extends Component {

	componentDidMount() {
		const { recipe } = this.props;
		this.props.dispatch({type: 'SET_TITLE', payload: {text: recipe.recipe_name, photo_id: recipe.header_photo}});
	}


	componentDidUpdate(prevProps) { 
		const { recipe } = this.props;
		if (recipe.recipe_id !== prevProps.recipe.recipe_id) {
			this.props.dispatch({type: 'SET_TITLE', payload: {text: recipe.recipe_namelocation, photo_id: recipe.header_photo}});
		}
	}

	render() {
		const { recipe } = this.props;
		return(
			<Box fill overflow="auto">
			<Grid
				areas={[
					{ name: "main", start: [0, 0], end: [0, 0] },
					{ name: "side", start: [1, 0], end: [1, 0] }
				]}
				columns={["60%", "40%"]}
				rows={["auto", "flex"]}
			>
					
				<Box gridArea="main" pad="large"  align="center">
					<div dangerouslySetInnerHTML={{ __html: recipe.intro_text }} />	
					<div dangerouslySetInnerHTML={{ __html: recipe.recipe_text }} />	
					<div dangerouslySetInnerHTML={{ __html: recipe.outro_text }} />		 
				</Box>
			
				<Box gridArea="side" pad="large"  align="center">
				<div dangerouslySetInnerHTML={{ __html: recipe.side_text }} />
				</Box>

			</Grid>
			</Box>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		recipe: state.recipes.byId[ownProps.match.params.id]
	}
}

export default connect(mapStateToProps)(RecipePage);