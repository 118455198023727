import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getNews(action) {

	try {
	
		const response = yield axios.get(`/api/news/${action.payload}`);
		const news = {
			byId: convertArrayToObject(response.data, 'news_id'),
			allIds: response.data.map((news) => news.news_id),
			date: action.payload
		};
		
		yield put({type: 'SET_NEWS', payload: news});

	} catch (error) {
		console.log('photo get failed', error);
	}
}


function* photoSaga() {
	yield takeLatest('FETCH_NEWS', getNews);
}

export default photoSaga;