import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Heading,
} from "grommet";
import AlbumList from './AlbumList';
import RoutedButton from '../Reused/RoutedButton';

// Admin album managment page

class AlbumManage extends Component { 

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}

	render() {
	
		return(
			<Box width='80%' justify="center" pad={{ horizontal: "large", vertical: "small" }} margin="large"  align="center">
				<Heading level='3'>Albums</Heading>
				<RoutedButton path='/addAlbum' label='Add New'/>
				{this.props.albums && <AlbumList albums={this.props.albums}/>}
			</Box>
		);
	}
}

const mapStateToProps = state => ({
  	albums: state.albums.allIds.map(id => state.albums.byId[id]).filter(a => !a.person_id)
});

export default connect(mapStateToProps)(AlbumManage);