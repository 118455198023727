import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Article } from 'grommet-icons';
import PersonLink from '../PersonPage/PersonLink';
import { findAge } from '../Reused/Utils';

const Event = (props) => {
    const event = props.event;
    const showArticleLink = props.showArticleLink; 
    // boolean

    if (event.family_event && props.admin) {
        //console.log('family', event);
        return(<li>{event.name}</li>)
    }

    //add details
    let end = '';
    let start = '';
    if (event.details) {
        end += ` ${event.details}`
    }
    // add year
    if (event.year) {
        start += `${event.year} - `
    }

    // add location
    if (event.phrase==="was buried") {

        if (event.site) {
            end += ` ${event.site_preposition} ${event.site}`;
        }

        if (event.place) {
            end += ` ${event.place_preposition} ${event.place}`;
        }
    // all other event types	
    } else {

        if (event.site) {
            end += ` ${event.site_preposition} ${event.site}`;
        }
        if (event.place) {
            end += ` ${event.place_preposition} ${event.place}`;
        }

    }

    const person = props.people[event.person_id];
    if (person && event.show_age) {
        const birth = person.events.find(e => e.name==="Birth");
        if (birth) {
            if (birth.year && event.year) {
                let age = findAge(birth, event)
                end += age;
            }
        }
    }


    if (event.person_id && event.articles[0] && showArticleLink) {
        return(<li>{start}<PersonLink inText={true} id={event.person_id}/> {event.phrase}{end} <Link className='text-link' margin={'xsmall'} to={`/article?eventId=${event.event_id}`}><Article color='#63b9d6'/></Link></li>)
    } else if (event.person_id) {
        return(<li>{start}<PersonLink inText={true} id={event.person_id}/> {event.phrase}{end}</li>)
    } else if(event.mother_id && event.father_id && event.articles[0] && showArticleLink) {
        return(<li>{start}<PersonLink inText={true} id={event.father_id}/> and <PersonLink inText={true} id={event.mother_id}/> were {event.phrase}{end}  <Link className='text-link' margin={'xsmall'} to={`/article?eventId=${event.event_id}`}><Article color='#63b9d6'/></Link></li>)
    } else if(event.mother_id && event.father_id) {
        return(<li>{start}<PersonLink inText={true} id={event.father_id}/> and <PersonLink inText={true} id={event.mother_id}/> were {event.phrase}{end}</li>)
    } else if (event.mother_id && event.articles[0] && showArticleLink) {
        return(<li>{start}<PersonLink inText={true} id={event.mother_id}/> was {event.phrase}{end}  <Link className='text-link' margin={'xsmall'} to={`/article?eventId=${event.event_id}`}><Article color='#63b9d6'/></Link></li>)
    } else if (event.mother_id) {
        return(<li>{start}<PersonLink inText={true} id={event.mother_id}/> was {event.phrase}{end}</li>)
    } else if (event.father_id && event.articles[0] && showArticleLink) {
        return(<li>{start}<PersonLink inText={true} id={event.father_id}/> was {event.phrase}{end}  <Link className='text-link' margin={'xsmall'} to={`/article?eventId=${event.event_id}`}><Article color='#63b9d6'/></Link></li>)
    } else if (event.father_id) {
        return(<li>{start}<PersonLink inText={true} id={event.father_id}/> was {event.phrase}{end}</li>)
    } else {
        return(null)
    }
}

const mapStateToProps = state => ({
    people: state.people.byId
});


export default connect(mapStateToProps)(Event);