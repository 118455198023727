import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Image,
  Button,
  Text,
  DataTable
} from "grommet";
import { Link } from 'react-router-dom';
import { Checkmark } from 'grommet-icons';

import DBImage from '../Reused/DBImage';

//props: toggleChange
//       change

//tablefix
const SelectPhotos = (props) => { 


  const [sort, setSort] = React.useState({
    property: "name",
    direction: "desc"
  });
      
      
    const DATA = props.photos;

    const columns = [
      {
        property: 'photo_id',
        header: <Text>ID</Text>,
        sortable:true,
        primary: true
    },
    {
      property: 'image',
      render: datum => <Box height="xsmall" width="xsmall" border>
                          <DBImage id={datum.photo_id}  fit="contain" thumbnail={true} />
                        </Box>,  
      sortable:false
    },
    {
      property: 'photo_url',
      header: <Text>url</Text>,
      search: true,
      sortable:true
  },
  {
    property: 'people',
    header: <Text>people</Text>,
    render: datum => datum.people && <ul>{datum.people.map(person => <li key={person.person_id}><Link className='text-link' to={`/person/${person.person_id}`}>{person.name}</Link></li>)}</ul>,
    sortable: false,
    search: false
  },
  {
    property: 'header',
    header: <Text>H</Text>,
    sortable: true,
    render: datum => datum.header && <Checkmark/>
  },
  {
    property: 'thumbnail',
    header: <Text>T</Text>,
    sortable: true,
    render: datum => datum.thumbnail && <Checkmark/>
  },
  // {
  //   property: 'albums',
  //   header: <Text>albums</Text>,
  //   render: datum => datum.albums && <ul>{datum.albums.map(album => 
  //     <li 
  //       style={{ cursor:"pointer", color:'#63b9d6' }} 
  //       key={album.album_id} 
  //       onClick={()=>{props.history.push(`/editAlbum/${album.album_id}`);}}>
  //     {album.name}</li>)}
  //     </ul>,
  //   sortable: false
  // },
  {
    property: 'updated_at',
    header: 'updated',
    render: datum =>
      datum.updated_at && new Date(datum.updated_at).toLocaleString('en-US', {dateStyle:'short', timeStyle: 'short'}),
    align: 'end',
    sortable:true
  },
  {
    property: 'location',
    header: <Text>location</Text>,
    search: true,
    sortable: true
  },
        {
          property: 'add',
          render: datum => <Button onClick={()=>props.handleClick(datum.photo_id)}  label={'add'}/>,
          sortable: false
        },

    ]


  
  
  return(
  
    <Box align="center" fill='horizontal' >
      <Button label="Cancel" onClick={()=>{props.toggleChange('')}}/>
    {props.photos && 
    <DataTable
        columns={columns}
        data={DATA}
        sortable
        replace={false}
        onMore={() => console.log("LOAD MORE")}
        primaryKey='photo_id'
        sort={{direction:'desc', property:'updated_at'}}
    />
    }
  </Box>
);}

const mapStateToProps = state => ({
  photos: state.photos.allIds.map(id => state.photos.byId[id])
});

export default connect(mapStateToProps)(SelectPhotos);