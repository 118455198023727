import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Box, Layer, Stack, Diagram, Text } from "grommet";
import TreeZoom from './TreeZoom';

//props: data has connections and layers
//      caption
            
function TreeLayer(props) { 

    const [show, setShow] = React.useState();
    const connections = props.data.connections;
    const height = props.data.height;
    const width = props.data.width;
    const rectangles = props.data.rectangles;
    const families = props.data.families;
   
 
    return (
      <Box>
        <Button margin={props.margin} alignSelf={props.align} justifySelf={props.justify} label={props.label} onClick={() => setShow(true)} />
        {show && (
          <Layer
            full
            margin="xsmall"
            onEsc={() => setShow(false)}
            onClickOutside={() => setShow(false)}
          >
            <Box pad='small' fill='vertical'>
              {connections && <Box fill='vertical'>
                <TreeZoom gens={props.gens} position={props.position} width={width} height={height} families={families} connections={connections} rectangles={rectangles} onClose={() => setShow(false)}/>
              </Box>}
              {props.caption && props.name && connections && <Text alignSelf='center'>{props.name+' is your '+props.caption}</Text>}
              {props.error && <Text alignSelf='center'>{props.caption}</Text>}
            <Button alignSelf='center' margin='small' label="close" onClick={() => setShow(false)} />
            </Box>
          </Layer>
        )}
      </Box>
    );
}

export default connect()(TreeLayer);