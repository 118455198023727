import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
	Box,
	TextArea,
	Heading,
	Button,
} from "grommet";

// Allows admin to edit exisiting what's new item
// Props: 

const customStyles = {
	menu: (provided, state) => ({
		...provided,
		width: '200px',
	})
}

class EditNews extends Component { 

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}

	state = {
		text: this.props.news.news_text
	}

	handleInputChangeFor = propertyName => (event) => {
		this.setState({
			[propertyName]: event.target.value,
		});
	}

	saveAll = () => {
		let data = {text: this.state.text, news_id: this.props.news.news_id}
		axios.post(`/api/news/edit`, data)
		.then((results) => {
			this.props.dispatch({type:'FETCH_NEWS', payload: `2020-01-21T13:54:31.947Z`});
			this.props.history.goBack();
		}).catch((error) => {
			console.log('error adding news', error);
		})
	}
 
	render() {
		return(
			<Box pad="small" gap='small'>
				<TextArea
					placeholder="text"
					value={this.state.text}
					onChange={this.handleInputChangeFor('text')}
				/>
				<Button alignSelf='center' label="save" onClick = {()=>this.saveAll()}/>
				<Button alignSelf='center' label='back' onClick={()=>this.props.history.goBack()}/>  
			</Box>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	user: state.user,
	news: state.news.byId[ownProps.match.params.id],
});

export default connect(mapStateToProps)(EditNews);