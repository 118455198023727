import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Text, Form, FormField, Button, Box } from 'grommet';
import axios from 'axios';

// Allows admin to edit users name, email and password
// Props: user - object with user data
//		getList & toggleEdit

class EditUser extends Component {

	emptyState = {       
		name: this.props.user.name,
		email: this.props.user.email,
		generations: this.props.user.generations,
		family_id: this.props.user.family_id,
		active: this.props.user.active
	}

	state = {       
		name: this.props.user.name,
		email: this.props.user.email,
		generations: this.props.user.generations,
		family_id: this.props.user.family_id,
		active: this.props.user.active
	}

	componentDidMount() {
		this.setState(this.emptyState); 
		console.log(this.state, this.props.user);
	}

	handleChange = (event) => {
		this.setState(
			 {...this.state, [event.target.name]: event.target.value,}
		);
	}

	handleSubmit = (event) => {
		event.preventDefault();
		let data = {name: this.state.name, email: this.state.email, password: this.state.password, generations: this.state.generations, family_id: this.state.family_id, active: this.state.active}
		console.log(data);
		axios.put(`/api/user/edit/${this.props.user.user_id}`, data)
		.then((results) => {
			this.props.getList()
			this.props.toggleEdit();
		}).catch((error) => {
			console.log('error editing user', error);
		})
	}

	cancel = () => {
		this.props.toggleEdit();
	}

	toggleActive = (active) => {
		if(window.confirm('Change active status of this user?')) {
			axios.put(`/api/user/active/${this.props.user.user_id}`, {active: active})
			.then((results) => {
				this.props.getList()
				this.props.toggleEdit();
			}).catch((error) => {
				console.log('error activating/deactivating user', error);
			})
		}
	}


	render() {
		return(
			<Box align="center" justify="center">
				<Form onSubmit={this.handleSubmit} >
					<Text variant="h4">Edit User</Text>
					<FormField onChange={this.handleChange} value={this.state.name} name="name" placeholder="Name" required label='name'/>
					<FormField onChange={this.handleChange} value={this.state.email} name="email" placeholder="Email" type="email" required label='email'/>
					<FormField onChange={this.handleChange} value={this.state.generations} type="number" min="0" name="generations" label='generations' placeholder="generations" required/>
					<FormField onChange={this.handleChange} value={this.state.family_id} type="number" min="0" name="family_id" label='family id' placeholder="family_id" required/>
					<Box align="center" justify="center" pad={'small'}>
						{this.state.active && <Button variant="outlined"  label="Deactivate" onClick={()=>{this.toggleActive(false)}}/>}
						{!this.state.active && <Button variant="outlined"  label="Reactivate" onClick={()=>{this.toggleActive(true)}}/>}
					</Box>
					<Button variant="outlined"  label="Cancel" onClick={()=>{this.cancel()}}/>
					<Button type="submit" variant="outlined"  label="Save"  value="Submit"/>
				</Form>
			</Box>
		)
	}
}

export default connect()(EditUser);