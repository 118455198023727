import React, { Component } from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import { Text, Form, FormField, Button } from 'grommet';

// Admin can add a new user
//Props: functions toggleAdding & getList

const emptyNewUser = {
	name: '',
	username: '',
	password: '',
	admin: false,
	generations: '',
	familyId: ''
}

class AddUser extends Component {

	state = {
		newUser: emptyNewUser,
	}

	handleChange = (event) => {
		this.setState({
			newUser: {...this.state.newUser, [event.target.name]: event.target.value,}
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		console.log(this.state.newUser);
		axios.post('/api/user/register', this.state.newUser)
		.then( (response) => {
				this.clearNewProject();
				this.props.toggleAdding();
				this.props.getList();
		}).catch( (error) => {
			console.log('Error', error);
		})
	}

	clearNewProject = () => {
		this.setState({newUser: emptyNewUser});
	}

	cancel = () => {
		this.clearNewProject();
		this.props.toggleAdding();
	}

	render() {
		return(

			<Form onSubmit={this.handleSubmit}>
				<Text variant="h4">Add a User</Text>
				<FormField onChange={this.handleChange} value={this.state.name} name="name" placeholder="Name" required label='name'/>
				<FormField onChange={this.handleChange} value={this.state.username} name="username" placeholder="Email" type="email" required label='email'/>
				<FormField onChange={this.handleChange} value={this.state.generations} name="generations" label='generations' type="number" min="0" placeholder="Generations" required/>
				<FormField onChange={this.handleChange} value={this.state.familyId} name="familyId" type="number" min="0" label='family id' placeholder="Family Id" required/>
				<FormField onChange={this.handleChange} value={this.state.password} name="password" placeholder="Password" label='password' required/>
				<Button type="submit" variant="outlined"  label="Add"  value="Submit"/>
				<Button variant="outlined"  label="Cancel" onClick={()=>{this.cancel()}}/>
			</Form>

		)
	}
}

export default connect()(AddUser);