import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

function* updateBio(action) {
	try {
		
		yield axios.post('/api/bio/', action.payload);

		yield put({type: 'FETCH_PEOPLE', payload: action.payload.person_id});

	} catch (error) {
		console.log('bio update failed', error);
	}
}


function* bioSaga() {
	yield takeLatest('UPDATE_BIO', updateBio);
}

export default bioSaga;