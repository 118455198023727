import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Text,} from "grommet";
import Caption from '../Reused/Caption';
import DBImage from '../Reused/DBImage';

// Shows image with caption under it; used in carousel on person page
//props: photo_id

class ImageWithCaption extends Component {
	render() {
		return( 
			<>
				<DBImage id={this.props.photo_id}  fit="contain" thumbnail={true} />
				<Text textAlign='center' size='small'><Caption photo={this.props.photo} key={this.props.photo_id}/></Text>
			</>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	photo: state.photos.byId[ownProps.photo_id]
})

export default connect(mapStateToProps)(ImageWithCaption);