import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {
	Box,
	Text,
	DataTable,
	Button
} from "grommet";
import { Link } from 'react-router-dom';

//Searchable album dropdown
//Props: albums - array of albums


const AlbumList = (props) => { 

	const [sort, setSort] = React.useState({
		property: "name",
		direction: "desc"
	});


   	const onClick = (event) => {
        props.history.push(`/editAlbum/${event}`);
    };

    const editPhotos = (event) => {
      	props.history.push(`/editAllPhotos/${event}`);
    };
    
    const DATA = props.albums;

    const columns = [
        {
            property: 'album_id',
            header: <Text>ID</Text>,
            sortable:true,
            primary: true
        },
        {
			property: 'name',
			header: <Text>Name</Text>,
			sortable:true,
			search: true
        },
		{
			property: 'display_name',
			header: <Text>Display Name</Text>,
			sortable:true,
			search: true
        },
        {
			property: 'updated_at',
			header: 'updated',
			render: datum =>
					datum.updated_at && new Date(datum.updated_at).toLocaleString('en-US', {dateStyle:'short', timeStyle: 'short'}),
			align: 'end',
			sortable:true
        },
        {
			property: 'people',
			header: <Text>people</Text>,
			render: datum => 
				datum.people && <ul>{datum.people.map(person => <li><Link className='text-link' to={`/person/${person.person_id}`}>{person.name}</Link></li>)}</ul>,
			sortable: true
        },
        {
			property: 'edit',
			render: datum => 
				<Button onClick={(event)=>onClick(datum.album_id, event)} label={'edit'}/>,
			sortable: false
        },
        {
			property: 'edit photos',
			render: datum => 
				<Button onClick={(event)=>editPhotos(datum.album_id, event)} label={'edit photos'}/>,
			sortable: false
        },
    ]


	return(
	
		<Box align="center" fill='horizontal'>
		{props.albums && 
			<DataTable
				columns={columns}
				data={DATA}
				sortable
				replace={false}
				step={props.albums.list}
				sort={{direction:'desc', property:'updated_at'}}
			/>
		}
		</Box>
	);
}


export default withRouter(connect()(AlbumList));