import React, { useState, useEffect } from 'react';
import {
	Heading,
	Box,
	Button,
	Grid,
	Markdown,
	Text,
	ThemeContext,
} from "grommet";
import { Next, Previous } from 'grommet-icons';
import { connect } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import PersonLink from '../PersonPage/PersonLink';
import Event from './Event';
import { Link } from 'react-router-dom';
const _ = require('lodash');

// gets articles based on url query and displays them to page through

const ArticlePage = (props) => {

	const [articles, setArticles] = useState([]);
	const [index, setIndex] = useState(0);
	const [years, setYears] = useState([]);
	const [header, setHeader] = useState('In the News');

	const search = queryString.parse(useLocation().search);
	//console.log(search);
	const location = useLocation();

	useEffect(() => {
		console.log('setting title', header);
		props.dispatch({type: 'SET_TITLE', payload: {text: header, photo_id: ''}});
	}, [header]);

	const onClick = (dir) => {
		if (dir==='up') {
			setIndex(index+1);
		} else {
			setIndex(index-1);
		}
	}

	const goTo = (year) => {
		setIndex(year.index);
	} 

	const formatYears = (articles) => {
		let years = [];
		let year;
		for (let i = 0; i < articles.length; i++) {
			const article = articles[i];
			let thisYear = article.date.slice(0,4);
			if (thisYear != year) {
				year = thisYear;
				years.push({year: year, index: i})
			}
		}
		if (years.length>1) {
			setYears(years);
		}
	}

	useEffect(() => {
		setIndex(0);
		if (search.today) {
			let date = new Date();
			setHeader('In the News' +` - ${date.toLocaleString('en-US', {month:'long', day: 'numeric'})}`);
			axios.get(`/api/articles/today/${date}`, { params: search })
			.then((results) => {
				formatYears(results.data);
				setArticles(results.data);
			}).catch((error) => {
				console.log('error getting articles', error);
			})
		} else if(search.people) {
			axios.get(`/api/articles/byPeople`, { params: search })
			.then((results) => {
				formatYears(results.data);
				setArticles(results.data);
			}).catch((error) => {
				console.log('error getting articles', error);
			})

		}else if (search.id) {
			axios.get(`/api/articles/byId`, { params: search })
			.then((results) => {
				setArticles(results.data);
				let idIndex = results.data.findIndex(x => x.article_id == search.id);
				setIndex(idIndex);
			}).catch((error) => {
				console.log('error getting articles', error);
			})

		} else {      
			axios.get(`/api/articles/detail`, { params: search })
			.then((results) => {
				//set header
				if(_.isEmpty(search)) {
					setHeader('In the News');
				} else if(search.personId) {
					setHeader(
						<div>
						  In the News - <PersonLink id={search.personId} fragment={true}/>
						</div>
					  );
				} else if (search.eventId) {
					const events = results.data[0].events;
					const event = events.find(({event_id})=> event_id==search.eventId);
					//console.log(event);
					if (event.family_event) {
						setHeader(`In the News - ${event.name}`)
					} else {
						setHeader(
							<div>
							 	In the News - <PersonLink id={event.person_id} fragment={true}/> - {event.name}
						   	</div>
						);
					}
				} else if (search.subjectId) {
					setHeader(`In the News - ${props.subjects[search.subjectId].subject}`);
				} else if (search.newspaperId) {
					const item = results.data[0];
					//console.log(results.data[0].masthead);
					setHeader(`In the News - ${item.masthead}, ${item.city}, ${item.state}`);
				} else if(search.city) {
					const item = results.data[0];
					setHeader(`In the News - ${item.city}, ${item.state}`);
				}

				formatYears(results.data);
				setArticles(results.data);
			}).catch((error) => {
				console.log('error getting articles', error);
			})
		}
		
	}, [location])

	const formatDate = (item) => {
		let dateDisplay;

		if (item.date) {
			let dateString = articles[index].date;
			dateString = dateString.slice(0, 10);
			dateString = dateString.split('-');
			let date = new Date(dateString[0], dateString[1]-1, dateString[2])
			dateDisplay = `${date.toLocaleString('en-US', {weekday:'long'})}, ${date.toLocaleString('en-US', {dateStyle:'long'})}`;
		}
		if (item.date && item.city && item.state) {
			return `${dateDisplay} — ${item.city}, ${item.state}`;
		} else if (item.date && (item.city || item.state)) {
			return  `${dateDisplay} — ${item.city}${item.state}`;
		} else if (item.date) {
			return  `${dateDisplay}`;
		} else if(item.city && item.state) {
			return  `${item.city}, ${item.state}`;
		} else {
			return `${item.city}${item.state}`;
		}

	}

	return(
		<Grid
			areas={[
				{ name: "main", start: [0, 0], end: [0, 0] },
				{ name: "side", start: [1, 0], end: [1, 0] }
				]}
			columns={["60%", "40%"]}
			rows={["auto", "flex"]}
		> 
		<ThemeContext.Extend
			value={
				{ 
					global: {
						focus: {
							background: {
								color: '#73a09a'
							},
							outline: {
								color: 'transparent'
							},
							shadow: {
								color: 'transparent'
							},
							boxShadow: {
								color: 'transparent'
							}
						}
					},
					button: {
						default: {
							fill: true,
							plain: true,
							outline: 'no'
						},
						hover: {
							default: {
							background: {
								color: "#666666"
							},
							color: "white"
							},
	   
						},
						global: {
							focus: {
								border: {
									color: 'transparent'
								},
								outline: {
									color: 'transparent'
								},
								shadow: {
									color: 'transparent'
								},
								boxShadow: {
									color: 'transparent'
								}
							}
						}
					}
				}
			}
		>

			{articles[0] && <Box gridArea="main" pad="medium"  margin={{left: 'xlarge'}}>

				<Box direction='row' justify='between'>
					<Box justify='center'>{index>0 && <Button focusIndicator={false} icon={<Previous />} onClick={() => {onClick('down')}} />}</Box>
					<Box ><Heading level='4'>{index+1} of {articles.length}</Heading></Box>
					<Box justify='center'>{index<articles.length-1 && <Button focusIndicator={false} icon={<Next />} onClick={() => {onClick('up')}} />}</Box>
				</Box>


				<Heading color="accent-1" level="4" alignSelf='center'  margin="none">{articles[index].masthead}</Heading>

				{(articles[index].date || articles[index].city || articles[index].state) && <Text alignSelf='center' size='small'>{formatDate(articles[index])}</Text>}

				{articles[index].headline && <Heading style={{ maxWidth: '100%' }} margin={{top: 'medium', bottom: 'none'}} level='4' alignSelf='center'>{articles[index].headline}</Heading>}

				<Box margin={{vertical: 'small'}}>
					<Markdown className='para' components={{"p": {"component": "p"}}}>{articles[index].text}</Markdown>
				</Box>

				{articles[index].people[0] &&
					<>
						<Heading color="accent-1" level="4" margin="none">People mentioned:</Heading>
						<ul style={{marginTop: "0px"}}>
							{articles[index].people.map(person=>
								<PersonLink key={person} id={person}/>
							)}
						</ul>
					</>
				}

				{articles[index].subjects[0] && 
					<>
						<Heading color="accent-1" level="4" margin="none">Subjects:</Heading>
						<ul style={{marginTop: "0px"}}>
							{articles[index].subjects.map(subject=>
								<li key={subject}>
									<Link className='text-link' margin={'xsmall'} to={`/article?subjectId=${props.subjects[subject].subject_id}`}>
										{props.subjects[subject].subject}</Link></li>
							)}
						</ul>
					</>
				}

				{props.user.admin && articles[index].events && 
					<>
						<Heading color="accent-1" level="4" margin="none">Events:</Heading>
						<ul style={{marginTop: "0px"}}>
							{articles[index].events.map(event=>
								<Event admin={true} key={event.event_id} event={event} people={props.people} showArticleLink={false}/>
							)}
						</ul>
					</>
				}

				{props.user.admin && 
					<>
						<Heading color="accent-1" level="4" margin="none">Article ID: {articles[index].article_id}</Heading>
					</>
				}

			</Box>}

			{years[0] && <Box gridArea="side" pad="medium" align='center'>
				<Heading color="accent-1" level="4" margin="small">Index:</Heading>
				<ul style={{marginTop: "0px"}}>
					{years.map(year=>
						<li><Button  hoverIndicator="light-1" onClick={(event) => {goTo(year)}}><Text color="#63b9d6">{year.year}</Text></Button></li>
					)}
				</ul>
			</Box>}

		</ThemeContext.Extend>

	</Grid>)

}

const mapStateToProps = state => ({
	subjects: state.subjects.byId,
	user: state.user,
	news: state.news.allIds.map(id => state.news.byId[id]),
	date: state.news.date,
	articles: state.articles.allIds.map(id=>state.articles.byId[id]),
	people: state.people.byId
});


export default connect(mapStateToProps)(ArticlePage);