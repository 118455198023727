import React, { Component } from 'react';
import { Box, Button, Heading } from "grommet";
import {connect} from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import SortableAlbums from './SortableAlbums';
import AlbumLightbox from '../AlbumManage/AlbumLightbox';

// Display albums on person page
//Props: person_albums - array of album ids
//		person_id

class PersonAlbums extends Component {

	state = {
		selectedOption: null,
		all: false
	}

	handleChange = (selectedOption) => {
		this.setState({ ...this.state, selectedOption: selectedOption });
	}

	handleClick = (person_id, album) => {
		let data = {person_id: person_id, album_id: album}
		axios.post('/api/person/addAlbum', data)
		.then((results) => {
			this.props.dispatch({type: 'FETCH_PEOPLE', payload: person_id});
			this.setState({...this.state,
				selectedOption: null});
		}).catch((error) => {
			console.log('error posting album to server', error); 
		})
	}

	show = () => {
		this.setState({...this.state, all: true})
	}

	render() {
		return(
			<Box width={{"min":"75%", "max":"75%"}} margin={{"bottom": "small"}}>
				{this.props.personAlbums[0] && <Heading color="accent-1" level="4" margin="none">Albums</Heading>}

				{/* seven or fewer albmus */}
				{((8>this.props.personAlbums.length>0) && !this.props.user.admin) && this.props.personAlbums.map(album =>
					<AlbumLightbox album_photos={album.photos} key={album.album_id} buttonText={album.display_name}/>
				)}

				{((7<this.props.personAlbums.length) && !this.props.user.admin) && <>
					{this.props.personAlbums.slice(0,6).map(album =><AlbumLightbox album_photos={album.photos} key={album.album_id} buttonText={album.name}/>)}
					{!this.state.all && <Button alignSelf='center' primary color='#c4911e' label="Show all albums" onClick={()=>{this.show()}} />}
					{this.state.all && this.props.personAlbums.slice(6,this.props.personAlbums.length).map(album =><AlbumLightbox album_photos={album.photos} key={album.album_id} buttonText={album.name}/>)}	
				</>}

				{this.props.user.admin && this.props.personAlbums[0] &&
					<SortableAlbums person_id={this.props.person_id} albums={this.props.personAlbums}/>
				}

				{this.props.user.admin && <> 
					<Select
						onChange={this.handleChange}
						options={this.props.albums.map(album=>({label: album.name, value: album.album_id}))}
						isSearchable={true}
						placeholder='select album to add'
					/>
					{this.state.selectedOption && <Button onClick={()=>this.handleClick(this.props.person_id, this.state.selectedOption.value)} type="submit" label="Add Album"/>}
				</>}
			</Box>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		personAlbums: ownProps.person_albums.map(album => state.albums.byId[album]),
		albums: state.albums.allIds.filter(i => !ownProps.person_albums.includes(i)).map(id => state.albums.byId[id]).filter(i => !i.person_id)
	}
  }
  
export default connect(mapStateToProps)(PersonAlbums);

