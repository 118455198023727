import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { Box, Heading } from "grommet";
import OrphanAlbumTable from './OrphanAlbumTable';

// Displays only albums not used in any person pages

class OrphanAlbums extends Component { 

	state = {
		albums: [],
	}

	componentDidMount() {
		this.getProjects();
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}

	getProjects = () => {
		axios.get(`/api/album/orphan`)
		.then( (response) => {
			//console.log('Response was', response.data);
			this.setState({
				albums: response.data
			});
		}).catch( (error) => {
			alert('Error',error);
		})
	}

	render() {
		return(
			<Box justify="center" pad={{ horizontal: "medium", vertical: "small" }} margin="large"  align="center">
				<Heading level='3'>Unused Albums</Heading>
				{this.state.albums && <OrphanAlbumTable ids={this.state.albums.map(a => a.album_id)}/>}
			</Box>
		);
	}
}


const mapStateToProps = state => ({
	albums: state.albums.allIds.map(id => state.albums.byId[id])
});

export default connect(mapStateToProps)(OrphanAlbums);