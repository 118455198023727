import React, { Component } from 'react';
import {
	Heading,
	Box,
	Button
  } from "grommet";
import { connect } from 'react-redux';
import NewsItem from './NewsItem';
import RoutedButton from '../Reused/RoutedButton';
import axios from 'axios';

class WhatsNew extends Component {
	
	state = {
		news: this.props.news,
		all: false
	};

	showAll() {
		this.props.dispatch({type:'FETCH_NEWS', payload: `2020-01-21T13:54:31.947Z`});
		this.setState({news: this.props.news,})
	}
	

	componentDidMount() {
		
		this.props.dispatch({type: 'SET_TITLE', payload: {text: `What's New`, photo_id: ''}});
		
		let data = {user_id: this.props.user.user_id}
		axios.post('/api/news/seen', data)
		.then((results) => {
		  	console.log('seen updated');
		}).catch((error) => {
			console.log('error updating news seen to server', error); 
		})
	}

	render() {
		return(

			<Box flex='grow' alignSelf='center' width={{'max':'800px'}}>
				<Heading level="5" color="accent-1">
				{this.props.date==='2020-01-21T13:54:31.947Z' ? 'All updates' : (this.props.news[0] ? 'New since you visited last' : 'Nothing since your last visit')}
				</Heading>

				{this.props.news[0] && this.props.news.map(i => 
					<NewsItem key={i.news_id} id={i.news_id} history={this.props.history}/>
				)}

				{!(this.props.date==='2020-01-21T13:54:31.947Z') && <Button primary color='#c4911e' label="Show All Updates" alignSelf='center' onClick={()=>this.showAll()}/>}

				{this.props.user.admin && <RoutedButton alignSelf='center' path='/addNews' label='Add New'/>}

			</Box>
		)
	}
}

const mapStateToProps = state => ({
	user: state.user,
	news: state.news.allIds.map(id => state.news.byId[id]),
	date: state.news.date
});

export default connect(mapStateToProps)(WhatsNew);