import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Article } from 'grommet-icons';
import { findAge } from '../Reused/Utils';
import propTypes from 'prop-types';

// Formats an event for display on person page
// Props: event - event object
//		events - all person events

class Event extends Component {

	render() {
		const event = this.props.event;
		const events = this.props.eventArray;

		let item = `${event.factword}`

		if (event.details) {
			item += ` ${event.details}`;
		}

		if (event.site) {
			item += ` ${event.site_preposition} ${event.site}`;
		}

		if (event.place) {
			item += ` ${event.place_preposition} ${event.place}`;
		}

		if (event.date) {
			item += ` ${event.date}`;
		}

		if (event.show_age && events) {
			const birth = events.find(e => e.name==="Birth");

			if (birth.year && event.year) {
				let age = findAge(birth, event)
				item += age;
			} 
		} else if (event.show_age) {
			console.log('show age but no events', event);
		}

		if (event.articles[0]) {
			return <li>{item} <Link className='text-link' margin={'xsmall'} to={`/article?eventId=${this.props.event.event_id}`}><Article color='#63b9d6'/></Link></li>
		} else {
			<li>{item}</li>
		}

		return(
			<li>{item}</li>
		)


	}
}

Event.propTypes = {
	event: propTypes.object.isRequired,
	eventArray: propTypes.array,
	birthYear: propTypes.number,
	deathYear: propTypes.number
}

export default Event;