import React, { Component } from 'react';
import {Box, Button, Markdown, Text} from 'grommet';
import {connect} from 'react-redux';
import PersonLink from '../PersonPage/PersonLink';

// displays one what's new item
// props: id - news item id

class NewsItem extends Component {

	render() {
		return( 
			<Box direction='column' border={{ style: 'dotted', side: 'bottom', size: 'medium' }} margin={{'bottom': 'medium'}} pad={{'bottom': 'medium'}}>
				<Text margin='none' style={{fontStyle: "italic"}}>{new Date(this.props.news.created_at).toLocaleString('en-US', {month: 'long', day: 'numeric', year: 'numeric'})}</Text>
					{this.props.news.news_text &&
					<Markdown fill components={{"p": {"component": "p",}}} options={{overrides: {PersonLink: {component: PersonLink,},},}}>
						{this.props.news.news_text}
					</Markdown>}
				{this.props.user.admin && <Button alignSelf='center' label={'Edit'} onClick={()=>this.props.history.push(`/editNews/${this.props.news.news_id}`)}/>}
			</Box>
		)
	}

}

const mapStateToProps = (state, ownProps) => ({
	news: state.news.byId[ownProps.id],
	user: state.user
})

export default connect(mapStateToProps)(NewsItem);