import React, { Component } from 'react';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Button } from "grommet";
import Caption from '../Reused/Caption'

// Displays album in pop-out lightbox
// Props: album_photos - array of photo ids
//		buttonText - label for button to open lightbox
 
class AlbumLightbox extends Component {
	constructor(props) {
		super(props);
	
		this.state = {
		photoIndex: 0,
		isOpen: false,
		};
	}
 
  	render() {
		const { photoIndex, isOpen } = this.state;
		const images = this.props.photos;
 
		return (
			<div>
				<Button margin="2px" type="button" onClick={() => this.setState({ isOpen: true })} label={this.props.buttonText}/>

				{isOpen && (
					<Lightbox
						mainSrc={process.env.PUBLIC_URL + 'uploads/' + images[photoIndex].photo_url}
						nextSrc={process.env.PUBLIC_URL + 'uploads/' + images[(photoIndex + 1) % images.length].photo_url}
						prevSrc={process.env.PUBLIC_URL + 'uploads/' + images[(photoIndex + images.length - 1) % images.length].photo_url}
						onCloseRequest={() => this.setState({ isOpen: false })}
						imageCaption={<Caption photo={images[photoIndex]} key={images[photoIndex].photo_id}/>}
						onMovePrevRequest={() =>
							this.setState({
								photoIndex: (photoIndex + images.length - 1) % images.length,
							})
						}
						onMoveNextRequest={() =>
							{this.setState({
								photoIndex: (photoIndex + 1) % images.length,
							})}
						}
					/>
				)}
			</div>
		);
  }
}

const mapStateToProps = (state, ownProps) => ({
  	photos: ownProps.album_photos.map(id => state.photos.byId[id])
})

export default connect(mapStateToProps)(AlbumLightbox);