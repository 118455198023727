const Theme = {
	button: {
		primary: {
			extend: `color: white;`
		}, 
		},
		global: {
		colors: {
			brand: "#73a09a", //title green, name
			focus: "#635548",
			selected: "#61C9A8",
			["accent-1"]: "#15756c", //Green headings
			["accent-2"]: "#444444", //grey text
			["accent-3"]: "#63b9d6", //blue links
			["accent-4"]: "#938780",  //top nav
		},
		heading: {color: "#15756c"}
	}
};

export default Theme;