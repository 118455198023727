// both event must have year
export const findAge = (birth, event) => {
    let age = event.year - birth.year;
    let exact = true;

    if (birth.month && event.month) {
        if (event.month < birth.month) {
            age--;
        } else if (event.month == birth.month) {
            if (birth.day && event.day) {
                if (event.day < birth.day) {
                    age--;
                }
            } else {
                // no days, same month
                exact = false;
            }
        }
    
    } else {
        //no months
        exact = false;
    }
    if (age===0) {
        return ` as an infant`;
    } else if (birth.exact_date && event.exact_date && exact) {
        return ` at the age of ${age}`;
    } else {
        return ` at around age ${age}`;
    }

}

// takes person object and returns a string of the form (birth year - death year)
export const renderDateParen = (person) => {
    if (person.events) {
        const birth = person.events.find(e => e.name==="Birth");
        const death = person.events.find(e => e.name==="Death");
 
        let result = `(`;
        let birthYear = formatYear(birth);
        result = result.concat(birthYear);

        result = result.concat(`-`);

        let deathYear = formatYear(death);
        result = result.concat(deathYear);

        result = result.concat(`)`);

        return result
    } else {
        return `(-)`;
    }

}

const formatYear = (event) => {
    let result = '';

    // if no event, return empty string
    if (!event) {
        return result;

    // if event is between date
    } else if (event.date && event.date.includes('between')) {
        const regex = /between (\d{4}) and (\d{4})/;
        const match = event.date.match(regex);
        if (match) {
            let years = [parseInt(match[1]), parseInt(match[2])];
            if (years[0] === years[1]) {
                return years[0];
            } else {
                return 'aft. ' + years[0];
            }
        }
    }


    if (event && event.year && event.prefix && !event.month) {
        result = result.concat(event.prefix, ' ');
    }
    if (event && event.year) {
        result = result.concat(event.year);
    }
    return result;
}