import React, { Component } from 'react';
import RoutedButton from '../Reused/RoutedButton';
import { connect } from 'react-redux';
import {
	Text,
	Box,
} from "grommet";

// Main admin page, routes to other admin function

class AdminPage extends Component {

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}


	render() {
		return(
		
		<Box pad={{ horizontal: "large", vertical: "small" }}>
						
			<RoutedButton path='/users' hoverIndicator>
				<Box float='left' pad={{ horizontal: "medium" }}>
					<Text alignSelf="start">Users</Text>
				</Box>
			</RoutedButton>

			<RoutedButton path='/photos' hoverIndicator>
				<Box float='left' pad={{ horizontal: "medium" }}>
					<Text alignSelf="start">Photos</Text>
				</Box>
			</RoutedButton>

			<RoutedButton path='/unusedphotos' hoverIndicator>
				<Box float='left' pad={{ horizontal: "medium" }}>
					<Text alignSelf="start">Unused Photos</Text>
				</Box>
			</RoutedButton>

			<RoutedButton path='/albums' hoverIndicator>
				<Box float='left' pad={{ horizontal: "medium" }}>
					<Text alignSelf="start">Albums</Text>
				</Box>
			</RoutedButton>

			<RoutedButton path='/unusedalbums' hoverIndicator>
				<Box float='left' pad={{ horizontal: "medium" }}>
					<Text alignSelf="start">Unused Albums</Text>
				</Box>
			</RoutedButton>    

			<RoutedButton path='/articleadmin' hoverIndicator>
				<Box float='left' pad={{ horizontal: "medium" }}>
					<Text alignSelf="start">Articles</Text>
				</Box>
			</RoutedButton>

			<RoutedButton path='/you' hoverIndicator>
				<Box float='left' pad={{ horizontal: "medium" }}>
					<Text alignSelf="start">How Related with You</Text>
				</Box>
			</RoutedButton>   
					
		</Box>

		)
	}
}

export default connect()(AdminPage);