import React, { useState, useRef, useEffect } from 'react';
import {
	HashRouter as Router,
	Route,
	Redirect,
	Switch,
} from 'react-router-dom';

import { Grommet, Box, Grid } from 'grommet';
import Theme from '../Themes/Theme'

import { connect } from 'react-redux';
import './App.css';

import Nav from '../components/Header/Nav';
import Header from '../components/Header/Crest';
import Title from '../components/Header/Title';

import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';

import UserPage from '../components/UserPage/UserPage';
import ListPage from '../components/ListPage/ListPage';
import PersonPage from '../components/PersonPage/PersonPage';
import InfoPage from '../components/InfoPage/InfoPage';
import AdminPage from '../components/AdminPage/AdminPage';
import AddAlbum from '../components/AlbumManage/AddAlbum';
import EditPhoto from '../components/PhotoManage/EditPhoto';
import EditAllPhotos from '../components/PhotoManage/EditAllPhotos';
import AddNews from '../components/InfoPage/AddNews';
import You from '../components/HowRelated/You';
import Home from '../components/TreePage/Home';
import Images from '../components/PhotoManage/Images';
import AlbumManage from '../components/AlbumManage/AlbumManage';
import OrphanAlbums from '../components/AlbumManage/OrphanAlbums';
import MyUploader from '../components/PhotoManage/MyUploader';
import EditAlbum from '../components/AlbumManage/EditAlbum';
import OrphanPhotos from '../components/PhotoManage/OrphanPhotos';
import PasswordReset from '../components/UserPage/PasswordReset';
import TreePage from '../components/TreePage/TreePage';
import WhatsNew from '../components/InfoPage/WhatsNew';
import EditNews from '../components/InfoPage/EditNews';
import TokenReset from '../components/UserPage/TokenReset';
import ForgotPassword from '../components/UserPage/ForgotPassword';
import ArticlePage from '../components/Articles/ArticlePage';
import ArticleAdmin from '../components/Articles/ArticleAdmin';
import Today from '../components/Articles/Today';
import LocationPage from '../components/LocationPage/LocationPage';
import RecipePage from '../components/LocationPage/RecipePage';


const App = ({ dispatch, title }) => {

    const [headerHeight, setHeaderHeight] = useState(0);
	const headerRef = useRef(null);

	const updateHeaderHeight = () => {
		if (headerRef.current) {
			const height = headerRef.current.offsetHeight;
			setHeaderHeight(height);
		}
	};

    useEffect(() => {
		dispatch({ type: 'FETCH_USER' });
		updateHeaderHeight();
		window.addEventListener('resize', updateHeaderHeight);

		return () => {
			window.removeEventListener('resize', updateHeaderHeight);
		};
	}, []);

	useEffect(() => {
		updateHeaderHeight();
	}, [headerHeight]);

	useEffect(() => {
		updateHeaderHeight();
	}, [title]);


	return (
		<Router>
			<Grommet theme={Theme} full>

				<Box fill>

					<Box style={{ position: 'fixed', top: 0, width: '100%', zIndex: 10 }} ref={headerRef}> 
						<Box
							direction="row"
							align="center"
							justify="between"
							pad={{ horizontal: "medium", vertical: "small" }}	
							height="xxsmall"
						>
							<Header />
							<Nav />
						</Box>
						<Title />
					</Box>

					<Box fill overflow="auto" style={{ marginTop:`${headerHeight}px` }}>
						<Switch>
								{/* Visiting localhost:3000 will redirect to localhost:3000/home */}
								<Redirect exact from="/" to="/home" />
								
								{/* Visiting localhost:3000/about will show the about page.
								This is a route anyone can see, no login necessary */}
								{/* <Route
									exact
									path="/about"
									component={AboutPage}
								/> */}

								<Route
									exact
									path="/reset/:id"
									component={TokenReset}
								/>

								<Route
									exact
									path="/forgot"
									component={ForgotPassword}
								/>
								
								<ProtectedRoute
									exact
									path="/info"
									component={InfoPage}
								/>
								<ProtectedRoute
									path="/article"
									component={ArticlePage}
								/>
								<ProtectedRoute 
									exact 
									path="/person/:id" 
									component={PersonPage}
								/>
								<ProtectedRoute 
									exact 
									path="/location/:id" 
									component={LocationPage}
								/>
								<ProtectedRoute 
									exact 
									path="/recipe/:id" 
									component={RecipePage}
								/>
								<ProtectedRoute 
									exact 
									path="/new" 
									component={WhatsNew}
								/>
								<ProtectedRoute 
									exact 
									path="/today" 
									component={Today}
								/>
								<ProtectedRoute 
									exact 
									path="/home" 
									component={Home}
								/>

								{/* parameter 'back' will exist if there should be a down arrow that functions as a back button */}
								<ProtectedRoute 
									exact 
									path="/tree/:id/:back?" 
									component={TreePage}
								/>
								<ProtectedRoute 
									exact 
									path="/reset_in" 
									component={PasswordReset}
								/>

								{/* For protected routes, the view could show one of several things on the same route.
								Visiting localhost:3000/home will show the UserPage if the user is logged in.
								If the user is not logged in, the ProtectedRoute will show the 'Login' or 'Register' page.
								Even though it seems like they are different pages, the user is always on localhost:3000/home */}
								<AdminRoute
									exact
									path="/users"
									component={UserPage}
								/>
								<AdminRoute
									exact
									path="/photos"
									component={Images}
								/>
								<AdminRoute
									exact
									path="/unusedphotos"
									component={OrphanPhotos}
								/>
								<AdminRoute
									exact
									path="/admin"
									component={AdminPage}
								/>
								<AdminRoute
									exact
									path="/albums"
									component={AlbumManage}
								/>
								<AdminRoute
									exact
									path="/unusedalbums"
									component={OrphanAlbums}
								/>     
								<AdminRoute
									exact
									path="/articleadmin"
									component={ArticleAdmin}
								/>          
								<AdminRoute
									exact
									path="/addAlbum"
									component={AddAlbum}
								/>
								<AdminRoute
									exact
									path="/addNews"
									component={AddNews}
								/>
								<AdminRoute
									exact
									path="/photoUpload/:albumId?"
									component={MyUploader}
								/>
								<AdminRoute
									exact
									path="/editAlbum/:id"
									component={EditAlbum}
								/>
								<AdminRoute
									exact
									path="/editNews/:id"
									component={EditNews}
								/>
								<AdminRoute
									exact
									path="/editPhoto/:photoId?"
									component={EditPhoto}
								/>
								<AdminRoute
									exact
									path="/editAllPhotos/:id"
									component={EditAllPhotos}
								/>
								<AdminRoute
									exact
									path="/you"
									component={You}
								/>

								{/* This works the same as the other protected route, except that if the user is logged in,
								they will see the info page instead. */}
								<ProtectedRoute
									exact
									path="/list"
									component={ListPage}
								/>
								{/* If none of the other routes matched, we will show a 404. */}
								<Route render={() => <h1>404</h1>} />
						</Switch>
					
					</Box>

				</Box>

			</Grommet>
		</Router>
	)
}

const mapStateToProps = (state) => ({
	title: state.title,
});


export default connect(mapStateToProps)(App);
