import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import convertArrayToObject from './convertArrayToObject';

function* getNewspapers(action) {
	try {
		
		const response = yield axios.get('/api/articles/newspapers');
		const newspapers = {
			byId: convertArrayToObject(response.data, 'newspaper_id'),
			allIds: response.data.map((newspaper) => newspaper.newspaper_id)
		};
		
		yield put({type: 'SET_NEWSPAPERS', payload: newspapers});

	} catch (error) {
		console.log('newspaper get failed', error);
	}
}


function* newspaperSaga() {
	yield takeLatest('FETCH_NEWSPAPERS', getNewspapers);
}

export default newspaperSaga;