import React, {Component} from 'react';
import RichTextEditor from 'react-rte';
import {connect} from 'react-redux';
import {Button,} from "grommet";

// Allows admin users to edit the bio
// Props: bio_text, handleSubmit, handleCancel

class BioEditor extends Component {
	
	state = {
		value: ''
	}
		
	constructor(props) {
		super(props);
		if (props.bio_text) {
			this.state = { value: RichTextEditor.createValueFromString(props.bio_text, 'markdown') };
		} else {
			this.state = {value: RichTextEditor.createValueFromString('', 'markdown')}
		}
	}

	onChange = (value) => {
		this.setState({value});
	};


	render () {
		const toolbarConfig = {
			// Optionally specify the groups to display (displayed in the order listed).
			display: ['INLINE_STYLE_BUTTONS', 'HISTORY_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
			INLINE_STYLE_BUTTONS: [
				{label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
				{label: 'Italic', style: 'ITALIC'},
				{label: 'Underline', style: 'UNDERLINE'},
			],
			BLOCK_TYPE_DROPDOWN: [
				{label: 'Normal', style: 'unstyled'},
				{label: 'Heading Small', style: 'header-three'}
			],
			BLOCK_TYPE_BUTTONS: [
				{label: 'UL', style: 'unordered-list-item'},
				{label: 'OL', style: 'ordered-list-item'}
			]
		};

		return (
			<>
				<RichTextEditor
					style={{minHeight: '9000px'}}
					toolbarConfig={toolbarConfig}
					value={this.state.value}
					onChange={this.onChange}
				/>
				<Button  label="Save" onClick={()=>{this.props.handleSubmit(this.state.value.toString('markdown'))}}/>
				<Button label="Cancel" onClick={this.props.handleCancel}/>
			</>
		);
	}
}

export default connect()(BioEditor);