import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
	Box,
	Heading,
	TextInput,
	Form,
	FormField,
	Button
} from "grommet";
import SortPhotos from './SortPhotos';
import SelectPhotos from './SelectPhotos';
import AlbumLightbox from './AlbumLightbox';
import PersonLink from '../PersonPage/PersonLink';

// Allows admin user to edit or delete album

class EditAlbum extends Component { 

	emptyState = {
		edit: false,
		name: this.props.album.name,
		displayName: this.props.album.display_name,
		adding: false
	}

	state ={
		edit: false,
		name: this.props.album.name,
		displayName: this.props.album.display_name,
		adding: false
	}

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
		this.setState(this.emptyState);
		this.setState({...this.state, name: this.props.album.name, displayName: this.props.album.display_name})
	}

	deleteAlbum = () => {
		if ( window.confirm('Delete this album?') ) {
			axios.delete(`/api/album/${this.props.album.album_id}`)
			.then((results) => {
				this.props.dispatch({type:'FETCH_ALBUMS'});
				this.props.history.goBack();
				//this.props.history.push(`/albums`);
			}).catch((error) => {
				console.log('error deleting album', error);
			})
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();
		let data = {name: this.state.name, album_id: this.props.album.album_id, displayName: this.state.displayName}
		axios.post(`/api/album/updateName`, data)
	.then((results) => {
		this.props.dispatch({type:'FETCH_ALBUMS'});
		this.edit();
	}).catch((error) => {
		console.log('error renaming album', error);
	})
		
		
}

cancel() {
	this.setState(prevState => ({
		...this.state,
		edit: !prevState.edit,
		name: this.props.album.name
	}));
}

	toggleAdding = () => {
		this.setState(prevState => ({
			...this.state,
			adding: !prevState.adding,
		}));
	}

	edit() {		
		this.setState(prevState => ({
			...this.state,
			edit: !prevState.edit
		}));
	}

	handleInputChangeFor = propertyName => (event) => {
		event.preventDefault();
		this.setState({...this.state,
			[propertyName]: event.target.value,
		});
	}

	goBack = () => {
		if (this.props.album.person_id) {
			this.props.history.push(`/person/${this.props.album.person_id}`);
		} else {
			this.props.history.goBack();
		}
	}

	render() {

		const { name, displayName } = this.state;
		return(
			<Box pad="large" flex="grow">
				{!this.state.edit && !this.props.album.person_id && <Heading margin='none' level='3'>{this.props.album.name}</Heading>}
				{!this.state.edit && !this.props.album.person_id && <Heading level='5'>Display Name: {this.props.album.display_name}</Heading>}
				{this.props.album.person_id && <> <PersonLink self={true} id={this.props.album.person_id}/> <Heading level='4'>{this.props.album.person_id}</Heading> </>}
				{this.state.edit &&           
					<Form onSubmit={this.handleSubmit}>
						<FormField label="Name" name="name" value={name} htmlFor="name">
							<TextInput
								id="name"
								name="name"
								placeholder={name}
								value={name}
								onChange={this.handleInputChangeFor('name')}
							/>
						</FormField>
						<FormField label="Display Name" name="displayName" value={displayName} htmlFor="displayName">
							<TextInput
								id="displayName"
								name="displayName"
								placeholder={displayName}
								value={displayName}
								onChange={this.handleInputChangeFor('displayName')}
							/>
						</FormField>
						<Button type="submit" name="submit" label="Save"/>
						<Button label="Cancel" onClick={()=>{this.cancel()}}/>
					</Form>
				}
				{!this.props.album.person_id && !this.state.edit && !this.state.adding && <Button label="Edit Name" onClick={()=>{this.edit()}}/>}
				{this.props.photos && <SortPhotos album_id={this.props.album.album_id} photos={this.props.photos}/>}
				{!this.state.adding && !this.state.edit && <Button label="Add Photos" onClick={()=>{this.toggleAdding()}}/>}
				{this.state.adding && <SelectPhotos photos={this.props.otherPhotos} toggleAdding={this.toggleAdding} album_id={this.props.album.album_id}/>}
				{!this.props.album.person_id && !this.state.edit && !this.state.adding && <Button onClick={()=>{this.deleteAlbum()}} label={'Delete Album'}/>}
				{this.props.photos[0] && <AlbumLightbox album_photos={this.props.album.photos} key={this.props.album.album_id} buttonText={'View'}/>}
				<Button onClick={()=>this.goBack()} name="back"	label="Back"/>
			</Box>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		photos: state.albums.byId[ownProps.match.params.id].photos.map(id => state.photos.byId[id]),
		album: state.albums.byId[ownProps.match.params.id],
		otherPhotos: state.photos.allIds.filter(i => !state.albums.byId[ownProps.match.params.id].photos.includes(i)).map(id => state.photos.byId[id]),
	}
}

export default connect(mapStateToProps)(EditAlbum);