import React, { Component } from 'react';
import {connect} from 'react-redux';
import SVGName from './SVGName';
import { withRouter } from "react-router";

//props: id, x, y, ?generations
class SVGPerson extends Component {

    route() {
        const id = this.props.r.id;
        if (id==='u' || id==='y' || id==='g' || this.props.r.end) {
            console.log('no!');
        } else {
            this.props.onClose();
            //console.log(id);
            this.props.history.push(`/person/${id}`)            
        }
    }
    

    render() {

        const {r} = this.props;
        let color = 'white';
        let strokeWidth = '2';
        let strokeDasharray='0';
        let linkStyle = { cursor:"pointer"};

        if (isNaN(r.id)) {
            linkStyle = {};
        }

        if(!r.id) {
            strokeDasharray='5,5';
        } else if(r.id==='g') {
            strokeWidth = '0';
        } else if(r.end || r.id==='y') {
            color = '#cadbd9';
        }


        return(
            <>
                <a 
                    onClick={()=>this.route()}
                    style={linkStyle}
                >
                <rect key={r.id} x={r.x} y={r.y} ry='10' rx='10' width={r.width} height={r.height} fill={color} strokeDasharray={strokeDasharray} strokeWidth={strokeWidth} stroke='#73a09a' />
                <SVGName gens={this.props.gens} condensed={this.props.condensed} id={r.id} x={r.centerX} y={r.centerY} width={r.width} font={this.props.font}/>
                </a>
            </>
        )
    }


}

export default withRouter(connect()(SVGPerson));