const rHeight = 60;
const rWidth = 150;
const up = 100;
const across = 120;
const youHeight = 60;
const youWidth = 100;
let dashArray = '5';
let connections = [];
let columns = [];

export default function createTree(f, nGen) {

	let maxDepth = f[f.length-1].depth;
	let maxWidth = 2 ** maxDepth;

	let peopleRows = [];
	let familyRows = [[]];

	//split array into levels
	for (let i = 0; i < maxDepth+1; i++) {
		let level = f.filter(p => p.depth===i);
		peopleRows.push(level);
	}

	for (let level = 0; level < maxDepth+1; level++) {
		const people = peopleRows[level];
		const families = familyRows[level];
		const denom = getDenom(level);
		
		let nextFamillyRow = [];

		if (level===0) {   
			people[0].id='y';
			people[0].denom=2;
			people[0].num=1;
		} else {
			families.forEach(family => {

				if (!family.father && !family.mother) {
					console.log('no parents in family', family);
				} else {
					//add data to father or add unkown
					if (family.father) {
						let fatherIndex = people.findIndex(p=>p.person_id===family.father);
						people[fatherIndex].denom=denom;
						people[fatherIndex].num=shiftLeft(family.num);
						people[fatherIndex].id=people[fatherIndex].person_id;
						if (!people[fatherIndex].num || !people[fatherIndex].denom) {
							console.log('data not being added', people[fatherIndex], 'num should be:', family.num);
						}
					} else {
						people.push({id:'u', depth: level, num: shiftLeft(family.num), denom: denom})
					}
					addConnection(level, level, family.num, shiftLeft(family.num), family.denom, denom);

					//add data to mother or add unkown
					if (family.mother) {
						let motherIndex = people.findIndex(p=>p.person_id===family.mother);
						people[motherIndex].denom=denom;
						people[motherIndex].num=shiftRight(family.num);
						people[motherIndex].id=people[motherIndex].person_id;
						if (!people[motherIndex].num || !people[motherIndex].denom) {
							console.log('data not being added', people[motherIndex], 'num should be:', family.num);
						}
					} else {
						people.push({id:'u', depth: level, num: shiftRight(family.num), denom: denom})
					}
					addConnection(level, level, family.num, shiftRight(family.num), family.denom, denom);
				}
			});
		}
		//add parent families
		people.forEach(person => {
			
			if (!person.num || !person.denom) {
				console.log('data missing', person);
			}
			if (person.parent_fam) {
				nextFamillyRow.push({id: person.parent_fam, depth: level+1, num: person.num, denom: person.denom, mother: person.mother, father: person.father});
				addConnection(level, level+1, person.num, person.num, person.denom, person.denom);
			}
		});

		familyRows.push(nextFamillyRow);

	}

	//console.log(maxWidth, peopleRows, families, connections);

	
	let height = (maxDepth * up) + ((maxDepth+1)*rHeight);

	//combine array of arrays
	let rectangles = [];
	peopleRows.forEach(curSet=>{
		rectangles = [...rectangles,...curSet]
	});
	let families = [];
	familyRows.forEach(curSet=>{
		families = [...families,...curSet]
	});

	//convert to x and y
	rectangles = rectangles.map(p=>formatRect(p, maxWidth, height));
	families = families.map(f=>formatFam(f, maxWidth, height));
	connections = connections.map(c=>formatConn(c, maxWidth, height));

	columns = columns.filter(function (value, index, columns) { 
		return columns.indexOf(value) === index;
	});
	columns.sort(function(a, b){return a-b});

	rectangles = rectangles.map(p=>{return {...p, centerX:columnToX(p.centerX)}});
	families = families.map(p=>{return {...p, x:columnToX(p.x)}});
	connections = connections.map(p=>{return {...p, x1:columnToX(p.x1), x2:columnToX(p.x2)}});
	rectangles = rectangles.map(p=> rectOffset(p));

	let width = columns.length * across;

	if (nGen>1) {
		let x = columnToX(findx(1,2,maxWidth));
		height = height + up + rHeight;
		connections.push({line: '0', x1: x, x2: x, y1: height-rHeight, y2: height-rHeight-up})
		rectangles.push({
			id: 'y',
			centerX: x,
			centerY: height-rHeight,
			x: x-(youWidth/2),
			y: height-rHeight-youHeight/2,
			width: youWidth,
			height: youHeight
		  });
		  rectangles[0].id = 'g';
	}


	let result = {height: height, width: width, families: families, connections: connections, rectangles: rectangles};

	return result;

}

function columnToX(x) {
	let index = columns.findIndex(i=> i===x);
	let final = (index)*across;
	return final;
}

function rectOffset(p) {
	if (p.id==='y') {
		p.x = p.centerX-(youWidth/2);
		p.y = p.centerY-(youHeight/2);
		p.width = youWidth;
		p.height = youHeight;
	} else {
		p.x = p.centerX-(rWidth/2);
		p.y = p.centerY-(rHeight/2);
		p.width = rWidth;
		p.height = rHeight;
	}
	return p;
}

function formatRect(p, width, height) {
	let res = {id: p.id}
	res.centerX = findx(p.num, p.denom, width);
	res.centerY = findy(p.depth, height);
	return res;
}



function formatFam(f, width, height) {
	let res = {id: f.id, x: findx(f.num, f.denom, width), y: findy(f.depth, height)};
	return res;
}

function formatConn(c, width, height) {
	let res = {
		line: c.line,
		y1: findy(c.level1, height),
		y2: findy(c.level2, height),
		x1: findx(c.num1, c.denom1, width),
		x2: findx(c.num2, c.denom2, width)
	}
	return res;
}

function findy(level, height) {
	let y = height-(rHeight/2)-(up*level);
	return y;
}

function findx(num, denom, width) {
	num = num*2-1;
	let x = width * num / denom;
	columns.push(x);    
	return x;
}

function shiftLeft(num) {
	let result = 2*num-1;
	return result;
}

function shiftRight(num) {
	let result = 2*num;
	return result;
}

function addConnection(level1, level2, num1, num2, denom1, denom2) {
	connections.push({level1: level1, level2: level2, num1: num1, num2: num2, denom1: denom1, denom2: denom2, line: '0'});
}

function getDenom(level) {
	let denom = (2 ** (level+1)) ;
	return denom;
}