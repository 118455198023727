import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Markdown, Accordion, AccordionPanel, ThemeContext} from 'grommet';

import PersonLink from '../PersonPage/PersonLink';
import ArticleLink from './ArticleLink';

// Shows the bio, in accordion if it is an object
// props: bio

const markdownOptions = {
	overrides: { 
		PersonLink: { component: PersonLink }, 
		ArticleLink: { component: ArticleLink }, 
		ul: {
			props: {
			  className: 'para',
			}
		},
		ol: {
			props: {
			  className: 'para',
			}
		},
		p: {
			props: {
				className: 'para',
			}
		}
	},
  };

const BioDisplay = ({bio}) => {
	switch (typeof bio) {
		case 'boolean':
			return null;
		case 'string':
			return (
				<Markdown 
				fill
				components={{
					"p": {"component": "p",}
				}}
				options={markdownOptions}
				>
					{bio}
				</Markdown>
				);
		case 'object': 
			return (
				<ThemeContext.Extend
					value={
						{ 
							global: {
								focus: {
									background: { color: '#73a09a' },
                					outline: { color: 'transparent' },
                					shadow: { color: 'transparent' },
                					boxShadow: { color: 'transparent' },
								}
							}
						}
					}
				>
					<Accordion width='100%'>
						{bio.map((section, index) => 
							<AccordionPanel label={section.header} key={index}>
								<Markdown 
									fill
									components={{ p: { component: 'p' } }}
									options={markdownOptions}
									className='para'
								>
									{section.paragraphs}
								</Markdown>
							</AccordionPanel>
						)}
					</Accordion>
				</ThemeContext.Extend>
			);
	}
}

export default connect()(BioDisplay);