import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

// worker Saga: will be fired on "REGISTER" actions
function* registerUser(action) {
	try {
		// clear any existing error on the registration page
		yield put({ type: 'CLEAR_REGISTRATION_ERROR' });
		console.log(action.payload);
		
		// passes the username and password from the payload to the server
		yield axios.post('/api/user/register', action.payload);

	} catch (error) {
		console.log('Error with user registration:', error);
		yield put({type: 'REGISTRATION_FAILED'});
	}
}

function* registrationSaga() {
	yield takeLatest('REGISTER', registerUser);
}

export default registrationSaga;
