import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Box, Text, Form, FormField, Button, Heading } from 'grommet';

// User inputs email to get password reset email

class ForgotPassword extends Component {

	state = {
		email: '',
		disabled: false
	}

	componentDidMount() {
		this.props.dispatch({ type: 'CLEAR_TITLE' });
	}

	handleChange = propertyName => (event) => {
		this.setState({
			[propertyName]: event.target.value,
		});
	}

	sendEmail =  (e) => {
		e.preventDefault();
		this.setState({disabled: true})

		if (this.state.email) {
			this.props.dispatch({
				type: 'SEND_RESET',
				payload: {
					email: this.state.email.toLowerCase()
				},
			});
		} else {
			this.props.dispatch({ type: 'RESET_INPUT_ERROR' });
		}
	};

	cancel = (e) => {
		e.preventDefault();
		this.setState({disabled: false});
		this.props.dispatch({ type: 'CLEAR_RESET_ERROR' });
		this.props.history.goBack();
	}

	render() {
		const {email, disabled } = this.state;
		const active = (disabled && !this.props.errors.resetMessage) ? true : false;

		return (
			<Box fill align="center" justify="center">
				<Heading>Forgot Password</Heading>
				<Text>Enter your email address.  We’ll send you an email to reset your password.</Text>
				<Form onSubmit={this.sendEmail}>
					<FormField
						id="email"
						label="email"
						value={email}
						onChange={this.handleChange('email')}
						placeholder="Email Address"
					/>
					<Button
						margin={'xsmall'}
						onClick={this.cancel}
						name="cancel"
						label="Cancel"
					/>
					<Button
							type="submit"
							name="submit"
							label="Send Email"
							disabled={active}
						/>
				</Form>
				{this.props.errors.resetMessage && (
					<Heading
						className="alert"
						role="alert"
						level="4"
					>
						{this.props.errors.resetMessage}
					</Heading>
				)}
				{this.props.errors.resetMessage === 'Password Reset Email Successfully Sent!' && (
					<Box alignContent='center'>
						<Text>If you don’t find an email from cajacob.family in your inbox, check for it in your spam folder.</Text>
					</Box>
				)}
			</Box>
		);
	}
}

const mapStateToProps = state => ({
	errors: state.errors,
});

export default connect(mapStateToProps)(ForgotPassword);